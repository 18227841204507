import React, { useMemo, useState } from "react";
import { Grid, IconButton } from "@mui/material/";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { TakerDocumentReviewComment, User } from "../../redux/models/dataModelTypes";
import Comment from "./CommentLexical";
import { CommentUserDateDisplay } from "./CommentMetaDisplay";
import CommentOptionsMenu from "./CommentOptionsMenu";

interface CommentRowProps {
    viewerUser: User;
    comment: TakerDocumentReviewComment;
    takerDocumentId: string;
}

/**
 * A single comment in a thread of comments.
 * @param {Object} viewerUser The user who is viewing the comment.
 * @param {Object} comment The comment to display.
 * @param {string} takerDocumentId The ID of the document this comment is for.
 * @returns {React.ReactElement}
 */
function CommentRow({
    viewerUser,
    comment,
    takerDocumentId
}: CommentRowProps) {
    const [collapsed, setCollapsed] = useState<boolean>(true);

    const contentShort = useMemo(() => {
        let t = comment.commentText;
        if (t.length > 30) {
            return `${t.substring(0, 30)}...`;
        }
        return t;
    }, [comment]);

    return (
        <Grid
            sx={{
                padding: 1,
                paddingRight: 1,
                border: "1px solid rgba(221, 221, 221, 0.5)",
                backgroundColor: "rgba(221, 221, 221, 0.05)",
                borderRadius: 1
            }}
            item
            xs={12}
        >
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <div>
                    <CommentUserDateDisplay comment={comment} />
                    {collapsed && (
                        <>
                            {" - "}<span>{contentShort}</span>
                        </>
                    )}
                </div>
                <div>
                    <IconButton
                        size="small"
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {collapsed ?
                            <ExpandLessOutlined /> :
                            <ExpandMoreOutlined />
                        }
                    </IconButton>
                    <CommentOptionsMenu 
                        viewerUser={viewerUser} 
                        comment={comment} 
                        takerDocumentId={takerDocumentId} 
                    />
                </div>
            </Grid>
            {!collapsed && (
                <Grid
                    item
                    xs={12}
                >
                    <Comment
                        namespace={comment.id}
                        lexical={comment.commentLexical} 
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default CommentRow;
