import { createApi } from '@reduxjs/toolkit/query/react';
import { User } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';

export const currentUserApi = createApi({
    reducerPath: 'curentUserApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        updateCurrentUser: build.mutation<User, Partial<User>>({
            query(data) {
                const { firstName, lastName } = data;
                return {
                    url: `current_user`,
                    method: 'PUT',
                    data: {
                        first_name: firstName,
                        last_name: lastName
                    }                    
                }
            }
        }),
    }),
})

export const {
    useUpdateCurrentUserMutation,
} = currentUserApi;