import React, { useMemo } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Box } from "@mui/material/";
import { useAddAppConfigEntryMutation, useGetApplicationQuery, useRemoveAppConfigEntryMutation } from "../../redux/services/application";
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../components/jsonschema/theme";
import { useGetFeatureFlagFileQuery } from "../../redux/services/staticFile";

interface Props {
  applicationId: string;
  open: boolean;
  onClose: () => void;
}

export const UpdateApplicationModal = ({ applicationId, open, onClose }: Props) => {
  const { data: application } = useGetApplicationQuery(applicationId);
  const [addAppConfigEntry, addAppConfigEntryRes] = useAddAppConfigEntryMutation();
  const [removeAppConfigEntry, removeAppConfigEntryRes] = useRemoveAppConfigEntryMutation();
  const { data: featureFlagSchema } = useGetFeatureFlagFileQuery();

  const formData = useMemo(() => {
    const map: Record<string, any> = {};
    if (application?.configuration) {
      for (const entry of application.configuration.entries) {
        map[entry.key] = entry.data;
      }
    }
    return map;
  }, [application?.configuration]);

  const saveDiff = (newFormData: any) => {
    const keysToUpdate = new Set<string>();
    const keysToAdd = new Set<string>();
    for (const [k, v] of Object.entries(newFormData)) {
      if (formData[k] !== v) {
        if (formData[k] === undefined) {
          keysToAdd.add(k);
        } else {
          keysToUpdate.add(k);
        }
      }
    }

    if (application?.configuration) {
      for (const k of keysToUpdate) {
        for (const entry of application?.configuration.entries) {
          if (entry.key === k) {
            removeAppConfigEntry({
              id: entry.id,
              applicationId: application?.id,
              applicationConfigurationId: application?.configuration?.id
            });
            keysToAdd.add(k)
          }
        }
      }
    }

    if (application?.configuration) {
      for (const k of keysToAdd) {
        addAppConfigEntry({
          applicationId: application?.id,
          applicationConfigurationId: application?.configuration?.id,
          key: k,
          data: newFormData[k]
        });
      }
    }
  };

  return (
    <SimpleModalWrapper
      headerText=""
      open={open}
      handleClose={() => onClose()}
      maxWidth='md'
    >
      <Box>
        {featureFlagSchema && (
          <Form
            formData={formData}
            onSubmit={(data, event) => saveDiff(data.formData)}
            schema={featureFlagSchema}
            validator={validator}
          />
        )}
      </Box>
    </SimpleModalWrapper>
  );
};
