import React, { useMemo } from "react";
import { TakerDocumentReviewComment } from "../../redux/models/dataModelTypes";

interface CommentUserDisplayProps {
    comment: TakerDocumentReviewComment;
}

const MS_IN_MINUTE = 1000 * 60;
const MS_IN_HOUR = MS_IN_MINUTE * 60;

/**
 * Renders a string that displays the user who made a comment and how long ago
 * they made the comment.
 * @param {{ comment: TakerDocumentReviewComment }} props
 * @returns {JSX.Element} JSX element containing a string that displays the user
 * who made a comment and how long ago they made the comment.
 */
function CommentUserDateDisplay({ comment }: CommentUserDisplayProps) {
    const userDisplay = useMemo(() => {
        let user = comment.user;
        let now = new Date();
        let createdDatetime = new Date(comment.createdAt);
        let diff = (now.getTime() - createdDatetime.getTime());
        if (diff < MS_IN_MINUTE) {
            return `By ${user.firstName} ${user.lastName} just now`;
        } else if (diff < (12 * MS_IN_HOUR)) {
            return `By ${user.firstName} ${user.lastName} at ${createdDatetime.toLocaleTimeString()}`;
        }
        return `By ${user.firstName} ${user.lastName} at ${createdDatetime.toLocaleTimeString()} on ${createdDatetime.toLocaleDateString()}`;
    }, [comment]);

    return (
        <i style={{ opacity: 0.5 }}>{userDisplay}</i>
    );
}

/**
 * Renders a string that displays how long ago a comment was made.
 * If the comment was made less than a minute ago, displays "just now".
 * If the comment was made less than 12 hours ago, displays the time of day.
 * Otherwise, displays the date and time.
 * @param {{ comment: TakerDocumentReviewComment }} props
 * @returns {JSX.Element} JSX element containing a string that displays how long ago a comment was made.
 */
function CommentDateDisplay({ comment }: CommentUserDisplayProps) {
    const displayStr = useMemo(() => {
        let now = new Date();
        let createdDatetime = new Date(comment.createdAt);
        let diff = (now.getTime() - createdDatetime.getTime());
        if (diff < MS_IN_MINUTE) {
            return `just now`;
        } else if (diff < (12 * MS_IN_HOUR)) {
            return `${createdDatetime.toLocaleTimeString()}`;
        }
        return `${createdDatetime.toLocaleDateString()} - ${createdDatetime.toLocaleTimeString()}`;
    }, [comment]);

    return (
        <i style={{ opacity: 0.5 }}>{displayStr}</i>
    );
}

/**
 * Renders a string that displays the author of a comment.
 * @param {{ comment: TakerDocumentReviewComment }} props
 * @returns {JSX.Element} JSX element containing a string that displays the author of a comment.
 */
function CommentUserDisplay({ comment }: CommentUserDisplayProps) {
    const displayStr = useMemo(() => {
        let user = comment.user;
        return `${user.firstName} ${user.lastName}`;
    }, [comment]);
    return (
        <i style={{ opacity: 0.5 }}>{displayStr}</i>
    );
}

export {
    CommentUserDateDisplay,
    CommentUserDisplay,
    CommentDateDisplay 
}
