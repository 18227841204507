import React from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from "@mui/material/";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldErrors, useForm } from "react-hook-form";
import * as yup from "yup";
import { Delete } from "@mui/icons-material";
import { DropDownItem } from "./models/muiTypes";
import { JsonFieldRhf } from "./reactHookForm/jsonFieldRhf";

export interface ConfigEntry {
    id: string;
    key: string;
    rawData: string;
    formattedData: string;
}

interface AdditionalField {
    name: string;
    defaultValue: any;
    yupSchema: yup.BaseSchema;
    buildFieldComponent: (c: any) => React.ReactNode;
}

interface ConfigEntryDataProps {
    keyValue: string;
    label: string;
    fieldName: string;
    isJson?: boolean;
    control: any;
    errors: FieldErrors<any>;
    keyOptionsGetter: (k: string) => DropDownItem[] | undefined;
}

const ConfigEntryData = ({
    keyValue,
    label,
    fieldName,
    isJson = false,
    control,
    errors,
    keyOptionsGetter
}: ConfigEntryDataProps) => {
    const keyOptions = keyOptionsGetter(keyValue);
    return (
        isJson ? (
            <JsonFieldRhf
                label={label}
                fieldName={fieldName}
                sx={{ width: "100%" }}
                variant="outlined"
                control={control}
                errors={errors}
            />
        ) : (
            <Controller
                name={fieldName}
                control={control}
                render={({ field }) => (
                    <>
                        {!!keyOptions ? (
                            <FormControl
                                {...field}
                                sx={{ width: "100%" }}
                                variant="outlined"
                                error={!!errors[`${fieldName}`]}
                            >
                                <InputLabel id="label">{label}</InputLabel>
                                <Select
                                    label={label}
                                    labelId="label"
                                    id="select-data-form"
                                    value={field.value || ''}
                                    onChange={(e: any) => field.onChange(e.target.value)}>
                                    {keyOptions.map((item: DropDownItem) =>
                                        <MenuItem key={item.id} data-testid={`select-${item.id}`} value={item.id}>
                                            {item.display}
                                        </MenuItem>
                                    )}
                                </Select>
                                <FormHelperText>
                                    {(errors[`${fieldName}`]?.message as string | undefined | null) ?? ""}
                                </FormHelperText>
                            </FormControl>
                        ) : (
                            <TextField
                                {...field}
                                label={label}
                                id={fieldName}
                                fullWidth
                                multiline
                                data-testid={`select-${fieldName}`}
                                variant="outlined"
                                error={!!errors[`${fieldName}`]}
                                helperText={(errors[`${fieldName}`]?.message as string | undefined | null) ?? ""}
                            />
                        )}
                    </>
                )}
            />
        )
    );
}

interface ConfigFormProps {
    options: any[];
    additionalFields: AdditionalField[];
    keyOptionsGetter: (k: string) => DropDownItem[] | undefined;
    onCreate: (d: any) => void;
    control: any;
    errors: any;
    handleSubmit: any;
    disableData: boolean;
}

const ConfigForm = ({ 
    options, 
    additionalFields,
    keyOptionsGetter,
    onCreate,
    control,
    errors,
    handleSubmit,
    disableData
}: ConfigFormProps) => {
    const handleSubmitCust = (d: any) => {
        onCreate(d);
    };

    const getDescriptionForKeyOption = (targetId: string) => {
        for (let { id, description } of options) {
            if (targetId === id) {
                return description;
            }
        }
    };

    const getIsJsonForKeyOption = (targetId: string) => {
        for (let { id, isJson } of options) {
            if (targetId === id) {
                return !!isJson;
            }
        }
    };

    return (
        <form 
            onSubmit={handleSubmit(handleSubmitCust)}
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Controller
                name="key"
                control={control}
                render={({ field }) => (
                    <>
                        <FormHelperText>
                            {getDescriptionForKeyOption(field.value)}
                        </FormHelperText>
                        <FormControl
                            sx={{ marginTop: "20px" }}
                            variant="outlined"
                            error={!!errors[`key`]}
                            data-testid="form-control"
                        >
                            <InputLabel id="label">Key</InputLabel>
                            <Select
                                label="Key"
                                labelId="label"
                                value={field.value || ''}
                                onChange={(e: any) => field.onChange(e.target.value)}
                                data-testid="select"
                            >
                                {options.map((item) =>
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.display}
                                    </MenuItem>
                                )}
                            </Select>
                            <FormHelperText data-testid="form-helper-text">
                                {(errors[`key`]?.message as string | undefined | null) ?? ""}
                            </FormHelperText>
                        </FormControl>
                        {!disableData && (
                            <div 
                                style={{ 
                                    width: "100%",
                                    marginTop: "20px" 
                                }}
                                data-testid="data-div"
                            >
                                <ConfigEntryData
                                    keyValue={field.value}
                                    label="Data"
                                    fieldName="data"
                                    isJson={getIsJsonForKeyOption(field.value)}
                                    control={control}
                                    errors={errors}
                                    keyOptionsGetter={keyOptionsGetter}
                                    data-testid="config-entry-data"
                                />
                            </div>           
                        )}
                    </>
                )}
            />
            {additionalFields.map((af: AdditionalField) => af.buildFieldComponent(control))}        
            <Box sx={{ marginTop: "20px" }}>
                <Button 
                    data-testid="create-button"
                    variant="contained" 
                    type="submit"
                >
                    Create
                </Button>
            </Box>
        </form>
    );
};

interface FullConfigProps {
    options: DropDownItem[];
    additionalFields: AdditionalField[];
    entries: ConfigEntry[];
    onDeleteEntry: (ce: ConfigEntry) => void;
    keyOptionsGetter: (k: string) => DropDownItem[] | undefined;
    onCreate: (d: any) => void;
    disableData?: boolean;
}

const FullConfig = ({
    options,
    additionalFields,
    entries,
    onDeleteEntry,
    keyOptionsGetter,
    onCreate,
    disableData
}: FullConfigProps) => {
    let objectDataSchema: Record<string, yup.BaseSchema> = {
        key: yup.string().required(),
    }

    if (!disableData) {
        objectDataSchema['data'] = yup.string().required();
    }

    let defaultValues: Record<string, any> = {};
    for (let {name, defaultValue, yupSchema} of additionalFields) {
        defaultValues[name] = defaultValue;
        objectDataSchema[name] = yupSchema;
    }

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(yup.object().shape(objectDataSchema)),
        defaultValues
    });

    const getDisplayForKeyOption = (targetId: string) => {
        for (let {id, display} of options) {
            if (targetId === id) {
                return display;
            }
        }
    };

    return (
        <>
            <Box 
                sx={{ 
                    marginBottom: "20px",
                    marginTop: "20px" 
                }}
            >
                <Typography variant="h6">
                    Entries
                </Typography>
                <Stack
                    sx={{ 
                        marginTop: "20px" 
                    }}
                >
                    {(entries && entries.length > 0) ? entries.map((entry: ConfigEntry) => (
                        <div 
                            onClick={() => {
                                setValue("key", entry.key);
                                if (!disableData) {
                                    setValue("data", entry.rawData);
                                }
                            }}
                            style={{
                                marginTop: "0.1%",
                                marginBottom: "0.1%",
                                border: "1px solid #dddddd",
                                alignItems: "center",
                                borderRadius: 2,
                                display: "flex"
                            }}
                        >
                            <span
                                style={{
                                    paddingRight: "5px"
                                }}
                            >
                                <IconButton 
                                    onClick={() => onDeleteEntry(entry)}
                                >
                                    <Delete/>
                                </IconButton>
                            </span>
                            <span
                                style={{
                                    paddingRight: "5px"
                                }}
                            >
                                <strong>
                                    {`${getDisplayForKeyOption(entry.key)} `}
                                </strong>
                            </span> 
                            {entry.formattedData}
                        </div>
                    )) : (
                        <div 
                            style={{
                                alignItems: "center",
                                display: "flex"
                            }}
                        >
                            none
                        </div>
                    )}
                </Stack>
            </Box>
            <Divider/>
            <Box sx={{ marginTop: "20px" }}>
                <Typography variant="h6">
                    Add Entry
                </Typography>
                <ConfigForm 
                    options={options}
                    additionalFields={additionalFields}
                    keyOptionsGetter={keyOptionsGetter}
                    onCreate={onCreate}
                    control={control}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    disableData={!!disableData}
                />
            </Box>
        </>                
    );
}

export default FullConfig;