import React, { useEffect, useMemo, useState } from "react";
import { SimpleModalWrapper } from "../../dialog/wrappers/simpleModalWrapper";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material/";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import { Add, Delete } from "@mui/icons-material";
import { useAddTakerDocumentAccessGrantMutation, useDeleteTakerDocumentAccessGrantMutation } from "../../../redux/services/taker";
import { useListUsersByOrgQuery } from "../../../redux/services/user";
import { useUserScopedAppData } from "../../../containers/UserScopedAppData/UserScopedAppData";
import { TakerAccessType, TakerDocumentAccessGrant, User } from "../../../redux/models/dataModelTypes";
import { RootReducerType } from "../../../redux/models/reduxTypes";
import { useSelector } from "../../../redux/reduxUtils/functions";
import { CrudButtonGroup } from "../../buttons/crudButtonGroup";
import { useSnackbar } from "notistack";


interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UserSelectionProps {
    onSelectUser: (user: User) => void;
    onSelectGrantType: (grantType: TakerAccessType) => void;
    onClickAdd: () => void;
    organizationId: string;
}

const UserSelection = ({
    onSelectUser,
    onSelectGrantType,
    onClickAdd,
    organizationId
}: UserSelectionProps) => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const [grantType, setGrantType] = useState<TakerAccessType>("READ");
    const { data: organizationMemberships } = useListUsersByOrgQuery({
        page: 0,
        limit: 100,
        organizationId
    });
    const { user: authedUser } = useSelector((state: RootReducerType) => state.auth);
    const { flagProvider } = useUserScopedAppData();

    const shareOptions = useMemo(() => {
        let options = [];
        let flags = flagProvider.populateFlagValues([
            "FEATURE_SHARE_TAKERS__share_flow_ui_enable_read",
            "FEATURE_SHARE_TAKERS__share_flow_ui_enable_review"
        ]);
        if (flags.FEATURE_SHARE_TAKERS__share_flow_ui_enable_read) {
            options.push("READ");
        }
        if (flags.FEATURE_SHARE_TAKERS__share_flow_ui_enable_review === "TRUE") {
            options.push("REVIEW");
        }
        return options
    }, [flagProvider]);

    useEffect(() => {
        if (user) {
            onSelectUser(user);
        }
    }, [user]);

    useEffect(() => {
        if (grantType) {
            onSelectGrantType(grantType);
        }
    }, [grantType]);

    const filteredUsers: User[] = useMemo(() => {
        if (!organizationMemberships || !authedUser) {
            return [];
        }
        return organizationMemberships.data
            .filter(om => (om.userId !== authedUser.id) && om.user)
            .map(om => om.user) as User[];
    }, [organizationMemberships, authedUser]);

    return (
        <div>
            <FormControl sx={{ m: 1 }}>
                <Select
                    sx={{ minWidth: "200px" }}
                    size="small"
                    labelId="user-label"
                    id="user-select"
                    value={user && user.id}
                    label=""
                    notched
                    placeholder="Share with..."
                    onChange={(event: SelectChangeEvent) => {
                        let userId = event.target.value as string
                        setUser(filteredUsers.find((u: User) => u.id === userId));
                    }}
                >
                    {filteredUsers.map(u => (
                        <MenuItem value={u.id}>
                            {`${u.firstName} ${u.lastName}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1 }}>
                <InputLabel id="taker-access-type">Type</InputLabel>
                <Select
                    size="small"
                    labelId="taker-access-type"
                    id="taker-access-type-select"
                    value={grantType}
                    label="Type"
                    onChange={(event: SelectChangeEvent) => {
                        setGrantType(event.target.value as TakerAccessType);
                    }}
                >
                    {shareOptions.map(t => (
                        <MenuItem value={t}>
                            {t}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1 }}>
                <Button
                    disabled={!user || !grantType}
                    onClick={() => onClickAdd()}
                >
                    <Add />
                </Button>
            </FormControl>
        </div>
    );
};

function ShareModal({ open, setOpen }: Props) {
    const [newUser, setNewUser] = useState<User>();
    const [newGrantType, setNewGrantType] = useState<TakerAccessType>();
    const {
        taker,
        takerDocumentId,
        activeTakerDocument,
        takerPermissionState
    } = useTakerState();
    const { selectedOrgId } = useUserScopedAppData();
    const { enqueueSnackbar } = useSnackbar();
    const [grantsToAdd, setGrantsToAdd] = useState<Partial<TakerDocumentAccessGrant>[]>([]);
    const [markedForDeletion, setMarkedForDeletion] = useState<Array<string>>([]);

    let [addTakerDocumentAccessGrant, addTakerDocumentAccessGrantRes] = useAddTakerDocumentAccessGrantMutation();
    let [deleteTakerDocumentAccessGrant, deleteTakerDocumentAccessGrantRes] = useDeleteTakerDocumentAccessGrantMutation();

    const pageData = useMemo(() => {
        if (activeTakerDocument) {
            return activeTakerDocument.takerDocumentAccessGrants;
        }
        return [];
    }, [activeTakerDocument?.takerDocumentAccessGrants]);

    const handleClose = () => {
        setOpen(false);
        setNewUser(undefined);
        setNewGrantType(undefined);
        setGrantsToAdd([]);
        setMarkedForDeletion([]);
    };

    const buttonElements = [];
    if (takerPermissionState.isAdmin) {
        buttonElements.push(
            <Button
                variant="contained"
                onClick={() => {
                    if (!selectedOrgId) {
                        enqueueSnackbar("Cannot share workflow if you are not in an organization", {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center'
                            }
                        });
                    } else {
                        if (taker) {
                            for (const grant of grantsToAdd) {
                                addTakerDocumentAccessGrant({
                                    takerDocumentId: grant.takerDocumentId,
                                    type: grant.type,
                                    userId: grant.userId,
                                    takerId: taker.id,
                                    organizationId: selectedOrgId
                                });
                            }
                            for (const grantId of markedForDeletion) {
                                let toDelete = pageData.find((tdag: TakerDocumentAccessGrant) => tdag.id === grantId);
                                if (toDelete) {
                                    deleteTakerDocumentAccessGrant({
                                        id: toDelete.id,
                                        takerId: taker.id
                                    });
                                }
                            }
                            handleClose();
                        }
                    }
                }}
            >
                Save
            </Button>
        );
    }
    buttonElements.push(
        <Button
            sx={{ float: "right" }}
            variant="outlined"
            onClick={handleClose}
        >
            Cancel
        </Button>
    );

    return (
        <SimpleModalWrapper
            headerText="Share"
            open={open}
            handleClose={handleClose}
            maxWidth='md'
            buttonElements={buttonElements}
        >
            <Box padding={1}>
                {!!selectedOrgId ? (
                    <UserSelection
                        organizationId={selectedOrgId}
                        onSelectUser={setNewUser}
                        onSelectGrantType={(gt) => setNewGrantType(gt)}
                        onClickAdd={() => {
                            const newGrants = [...grantsToAdd];
                            newGrants.push({
                                takerDocumentId: takerDocumentId,
                                type: newGrantType,
                                userId: newUser?.id,
                                user: newUser
                            });
                            setGrantsToAdd(newGrants);
                        }}
                    />
                ) : (
                    <Typography>
                        {"Document is not sharable."}
                    </Typography>
                )}
            </Box>
            <Box padding={1}>
                <TableContainer>
                    <Table>
                        <TableHead>

                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Type
                            </TableCell>
                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Created
                            </TableCell>
                            <TableCell />
                        </TableHead>
                        <TableBody>
                            {(pageData && pageData.length > 0) ? pageData.map((tdag: TakerDocumentAccessGrant) => (
                                <TableRow
                                    key={tdag.id}
                                    selected={markedForDeletion.includes(tdag.id)}
                                >
                                    <TableCell>
                                        {tdag.user ? (
                                            tdag.user.firstName
                                        ) : (
                                            "no name"
                                        )}
                                    </TableCell>
                                    <TableCell>{tdag.type}</TableCell>
                                    <TableCell>{new Date(tdag.createdAt).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <CrudButtonGroup
                                            buttons={[
                                                {
                                                    icon: (<Delete fontSize="small" />),
                                                    disabled: markedForDeletion.includes(tdag.id),
                                                    handleClick: () => {
                                                        let newMarked = [...markedForDeletion];
                                                        newMarked.push(tdag.id);
                                                        setMarkedForDeletion(newMarked);
                                                    }
                                                }
                                            ]}
                                        />
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={4}>
                                        <i>none</i>
                                    </TableCell>
                                </TableRow>
                            )}
                            {(grantsToAdd && grantsToAdd.length > 0) && grantsToAdd.map((tdag: Partial<TakerDocumentAccessGrant>, i) => (
                                <TableRow
                                    key={tdag.id}
                                    selected
                                >
                                    <TableCell>
                                        <i>
                                            {tdag.user ? (
                                                tdag.user.firstName
                                            ) : (
                                                "no name"
                                            )}
                                        </i>
                                    </TableCell>
                                    <TableCell>{tdag.type}</TableCell>
                                    <TableCell />
                                    <TableCell>
                                        <CrudButtonGroup
                                            buttons={[
                                                {
                                                    icon: (<Delete fontSize="small" />),
                                                    handleClick: () => {
                                                        const newGrants = [...grantsToAdd];
                                                        newGrants.splice(i, 1);
                                                        setGrantsToAdd(newGrants);
                                                    }
                                                }
                                            ]}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </SimpleModalWrapper>
    );
}

export default ShareModal;
