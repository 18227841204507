import React, { useState } from "react";
import { SimpleModalWrapper } from "../../dialog/wrappers/simpleModalWrapper";
import { Box, Button, FormControlLabel, FormHelperText, Switch, Tab, Tabs, Typography } from "@mui/material/";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import TabPanel, { a11yProps } from "../../navigation/TabPanel";
import { TakerDocumentSettingsPropertyType } from "../../../redux/models/dataModelTypes";
import { useAddTakerDocumentSettingsPropertyMutation, useUpdateTakerDocumentSettingsPropertyMutation } from "../../../redux/services/taker";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CopilotModal({ open, setOpen }: Props) {
    const { 
        activeTakerDocument, 
        getActiveSetting, 
        takerDocumentId 
    } = useTakerState();
    const [addProperty, addPropertyRes] = useAddTakerDocumentSettingsPropertyMutation();
    const [updateProperty, updatePropertyRes] = useUpdateTakerDocumentSettingsPropertyMutation();

    const [tabValue, setTabValue] = useState(0);

    const [enableKeyTermTagging, setEnableKeyTermTagging] = useState<string>(
        getActiveSetting("KEY_TERM_TAGGING_ENABLED")
    );
    const [disableSummaryAiGeneratedContent, setDisableSummaryAiGeneratedContent] = useState<string>(
        getActiveSetting("KEY_TERM_SUMMARY_DISABLE_AI_GENERATED_CONTENT")
    );
    const [disableAiGeneratedContentReport, setDisableAiGeneratedContentReport] = useState<string>(
        getActiveSetting("REPORT_DISABLE_AI_GENERATED_CONTENT")
    );
    const [enableAiFeatureCaching, setEnableAiFeatureCaching] = useState<string>(
        getActiveSetting("REPORT_ENABLE_AI_FEATURE_CACHING")
    );

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

    const getSetting = (type: TakerDocumentSettingsPropertyType) => {
        if (activeTakerDocument?.settings) {
            for (const property of activeTakerDocument?.settings.properties) {
                if (property.type === type) {
                    return property;
                }
            }
        }
    }

    const saveActiveSetting = (type: TakerDocumentSettingsPropertyType, newValue: string) => {
        const prop = getSetting(type);
        if (!!prop) {
            updateProperty({
                id: prop.id,
                takerDocumentId: takerDocumentId,
                takerDocumentSettingsId: prop.takerDocumentSettingsId,
                value: newValue
            });
        } else {
            addProperty({
                takerDocumentId: takerDocumentId,
                takerDocumentSettingsId: activeTakerDocument?.settings.id,
                type: type,
                value: newValue
            });
        }
    }
    
    const saveActiveSettings = () => {
        saveActiveSetting(
            "KEY_TERM_TAGGING_ENABLED",
            enableKeyTermTagging
        );
        saveActiveSetting(
            "KEY_TERM_SUMMARY_DISABLE_AI_GENERATED_CONTENT",
            disableSummaryAiGeneratedContent
        );
        saveActiveSetting(
            "REPORT_DISABLE_AI_GENERATED_CONTENT",
            disableAiGeneratedContentReport
        );
        saveActiveSetting(
            "REPORT_ENABLE_AI_FEATURE_CACHING",
            enableAiFeatureCaching
        );
    }

    return (
        <SimpleModalWrapper
            headerText=""
            open={open}
            handleClose={() => setOpen(false)}
            maxWidth='md'
            buttonElements={[
                <Button
                    variant="contained"
                    onClick={() => {
                        saveActiveSettings();
                        setOpen(false);
                    }}
                >
                    Save
                </Button>,
                <Button
                    sx={{ float: "right" }}
                    variant="outlined"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
            ]}
        >
            <Tabs
                value={tabValue}
                onChange={handleChange}
                style={{
                    height: "10%",
                }}
            >
                <Tab
                    label="Key Term Highlights"
                    {...a11yProps(0)}
                />
                <Tab
                    label="Summarization"
                    {...a11yProps(1)}
                />
                <Tab
                    label="Question Answering"
                    disabled
                    {...a11yProps(2)}
                />
                <Tab
                    label="Memo Generation"
                    {...a11yProps(3)}
                />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <Box padding={1}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={enableKeyTermTagging === "true"}
                                onChange={(e) => {
                                    setEnableKeyTermTagging(
                                        e.target.checked ? "true" : "false"
                                    )
                                }}
                            />
                        }
                        label="Enable Key Term Tagging"
                    />
                    <FormHelperText>
                        Enable AI to highlight key terms in .
                    </FormHelperText>
                </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Box padding={1}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={disableSummaryAiGeneratedContent === "true"}
                                onChange={(e) => {
                                    setDisableSummaryAiGeneratedContent(
                                        e.target.checked ? "true" : "false"
                                    )
                                }}
                            />
                        }
                        label="Disable AI Generated Content"
                    />
                    <FormHelperText>
                        Disable AI generated content in the summary generation.
                    </FormHelperText>
                </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <Box padding={1}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={disableAiGeneratedContentReport === "true"}
                                onChange={(e) => {
                                    setDisableAiGeneratedContentReport(
                                        e.target.checked ? "true" : "false"
                                    )
                                }}
                            />
                        }
                        label="Disable AI Generated Content"
                    />
                    <FormHelperText>
                        Disable AI generated content in the report. This will affect the various report elements that use
                        generative AI to produce a natural writing output.
                    </FormHelperText>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={enableAiFeatureCaching === "true"}
                                onChange={(e) => {
                                    setEnableAiFeatureCaching(
                                        e.target.checked ? "true" : "false"
                                    )
                                }}
                            />
                        }
                        label="Enable AI Feature Caching"
                    />
                    <FormHelperText>
                        Disabling AI feature caching can cause subsequent memo generations to take a longer time. Enabling the cache often results
                        in shorter generation times.
                    </FormHelperText>
                </Box>
            </TabPanel>
        </SimpleModalWrapper>
    );
}

export default CopilotModal;
