import React, { useContext, PropsWithChildren } from 'react';

interface GlobalScopedAppDataHookData {
}

type GlobalScopedAppDataHook = () => GlobalScopedAppDataHookData;

const Context = React.createContext({});

export const useGlobalScopedAppData: GlobalScopedAppDataHook = () => {
    return useContext(Context) as GlobalScopedAppDataHookData;
}

interface GlobalScopedAppDataContainerProps {
}

export const GlobalScopedAppDataContainer: React.FC<PropsWithChildren<GlobalScopedAppDataContainerProps>> = ({
    children
}) => {
    return (
        <Context.Provider
            value={{}}>
            {children}
        </Context.Provider>
    );
};
