import { useMemo, useState, useEffect } from "react";

import FullConfig, { ConfigEntry } from "../../components/form/EntryBuilder";
import axios from "axios";
import { Assistant, AssistantMembership } from "../../redux/models/dataModelTypes";
import { useGetOrganizationQuery } from "../../redux/services/organization";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";

const API_ENDPOINT = window.__RUNTIME_CONFIG__.API_ENDPOINT;

interface Props {
  builderDocumentId: string;
}

export function ModelSettings({ builderDocumentId }: Props) {
  const [assistants, setAssistants] = useState<Array<Assistant>>([]);
  const [assistantAssignments, setAssistantAssignments] = useState<Array<any>>([]);
  const { selectedOrgId } = useUserScopedAppData();
  const { data: organization } = useGetOrganizationQuery(selectedOrgId);

  const fetchAssistantAssignments = () => {
    axios.get(
      `${API_ENDPOINT}/v1/assistant_assignments?page=0&limit=1000&builder_document_id=${builderDocumentId}`,
      { withCredentials: true }
    ).then((data) => {
      let res = data.data;
      setAssistantAssignments(res.data);
    }).catch((e) => {
      console.error(e);
    });
  };

  const deleteAssistantAssignment = async (id: string) => {  
    const res = await axios.delete(
        `${API_ENDPOINT}/v1/assistant_assignments/${id}`,
        { withCredentials: true }
    );
    setAssistantAssignments(assistantAssignments.filter((aa) => aa.id !== id));
  }

  const createAssistantAssignment = async (assistantId: string) => {
    const res = await axios.post(
        `${API_ENDPOINT}/v1/assistant_assignments`,
        {
            builder_document_id: builderDocumentId, 
            assistant_id: assistantId
        },
        { withCredentials: true }
    );
    fetchAssistantAssignments();
  };

  useEffect(() => {
    fetchAssistantAssignments();
  }, []);
  
  useEffect(() => {
    if (!selectedOrgId) {
      axios.get(
      `${API_ENDPOINT}/v1/assistants?page=0&limit=1000`,
      { withCredentials: true }
    ).then((data) => {
      let res = data.data;
      setAssistants(res.data);
    }).catch((e) => {
      console.error(e);
    });
    } else if (organization) {
      setAssistants(organization.assistantMemberships.map((am: AssistantMembership) => am.assistant));
    }
  }, [organization]);

  return (
    <FullConfig
        options={(assistants || []).map((a) => ({
          id: a.id,
          display: a.name
        }))}
        additionalFields={[]}
        entries={assistantAssignments.map((aa) => ({
          id: aa.id,
          key: aa.assistant.id
        } as ConfigEntry))}
        onDeleteEntry={(ce: ConfigEntry) => {
            deleteAssistantAssignment(ce.id);
        }}
        keyOptionsGetter={(k: string) => undefined}
        onCreate={(d: any) => {
          createAssistantAssignment(d.key);
        }}
        disableData
    />
  );
}

export default ModelSettings;
