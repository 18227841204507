import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Toolbar, Tooltip } from "@mui/material";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import axios from 'axios';
import { ArrowDropDown } from "@mui/icons-material";
import { useWidgetState } from "../../containers/WidgetWrapper/wrapper";
import { MemoGenerationState } from "../../containers/WidgetWrapper/states";
import ReportsModal from "./ReportsModal";
import { useReportState } from "../../containers/TakerDocumentState/ReportState";

const ReportToolbar = () => {
    const { latestReportRevision } = useReportState();
    const { takerPermissionState } = useTakerState();
    const { getState, mutateState } = useWidgetState()
    const [exportBtnOpen, setExportBtnOpen] = useState(false);
    const exportAnchorRef = useRef<HTMLButtonElement>(null);
    const generatedReportsModalOpen = getState<MemoGenerationState>().generatedReportsModalOpen;
    const currentEditor = getState<MemoGenerationState>().currentEditor;

    const handleClose = (event: Event) => {
        if (
            exportAnchorRef.current &&
            exportAnchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setExportBtnOpen(false);
    };

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    const tfToDocx = useCallback(() => {
        if (currentEditor) {
            axios({
                url: `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/v1/lexical_to_docx`,
                method: 'POST',
                responseType: 'blob',
                data: {
                    lexical: currentEditor.getEditorState().toJSON(),
                    report_revision: latestReportRevision
                },
                withCredentials: true
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (latestReportRevision) {
                    const dateString = (new Date(latestReportRevision.updatedAt)).toLocaleString()
                    link.setAttribute('download', `report (revised ${dateString}).docx`);
                } else {
                    link.setAttribute('download', 'report.docx');
                }
                document.body.appendChild(link);
                link.click();
            });
        }
    }, [
        currentEditor,
        latestReportRevision
    ]);

    return (
        <Box sx={{ padding: 0.5 }}>
            <Toolbar
                variant="dense"
                disableGutters
                sx={{
                    width: "100%",
                    display: "inline-grid",
                    gridTemplateColumns: "1fr 1fr",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Tooltip title={"Open Current Reports / Generated Reports"}>
                        <Button
                            data-testid="reports-button"
                            variant="outlined"
                            onClick={() => {
                                mutateState<MemoGenerationState>({
                                    generatedReportsModalOpen: true
                                });
                            }}
                        >
                            Reports
                        </Button>
                    </Tooltip>
                    <ReportsModal
                        readOnly={isReadOnly}
                        open={generatedReportsModalOpen}
                        setOpen={(o) => mutateState<MemoGenerationState>({
                            generatedReportsModalOpen: o
                        })}
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip title={"Export Curernt Report"}>
                        <Button
                            data-testid="export-button"
                            ref={exportAnchorRef}
                            variant="outlined"
                            endIcon={<ArrowDropDown />}
                            aria-controls={exportBtnOpen ? 'split-button-menu' : undefined}
                            aria-expanded={exportBtnOpen ? 'true' : undefined}
                            aria-label="select export"
                            aria-haspopup="menu"
                            onClick={() => setExportBtnOpen((prevOpen) => !prevOpen)}
                        >
                            Export
                        </Button>
                    </Tooltip>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={exportBtnOpen}
                        anchorEl={exportAnchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {["DOCX"].map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    disabled={index === 2}
                                                    onClick={(event) => {
                                                        if (index === 0) {
                                                            tfToDocx();
                                                        }
                                                    }}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Toolbar>
        </Box>
    );
};

export default ReportToolbar;