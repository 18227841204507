import React from "react";
import { Box, styled } from "@mui/material/";

const BoxWithHoverBg = styled(Box)(({ theme }) => ({
    background: "none",
    '&:hover': {
        background: "rgba(0, 0, 0, 0.03)",
        cursor: "pointer"
    }
}));

export default BoxWithHoverBg;