import React from "react";
import {
  Dialog,
  IconButton,
  Typography,
  Toolbar,
  DialogContent,
  DialogActions,
  Breakpoint
} from "@mui/material/";
import { DeleteIcon } from "./../../../assets/icons";

export interface SimpleModalWrapperProps {
  open: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  disableBackdropClick?: boolean;
  headerText: string;
  buttonElements?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  maxWidth: Breakpoint;
}

export const SimpleModalWrapper = (props: SimpleModalWrapperProps) => {
  const {
    handleClose,
    open,
    children,
    headerText,
    buttonElements,
    maxWidth,
    disableBackdropClick = false
  } = props;
  let onClick = disableBackdropClick ? () => { } : handleClose;
  return (
    <Dialog onClose={onClick} open={open} maxWidth={maxWidth} fullWidth>
      <Toolbar variant="dense" sx={{ justifyContent: "space-between", backgroundColor: "#276EDF" }}>
        <Typography color="white" sx={{ fontWeight: "bold" }}>
          {headerText}
        </Typography>
        <IconButton
          data-testid="close-modal"
          size="small"
          onClick={handleClose}
        >
          <DeleteIcon htmlColor="white" />
        </IconButton>
      </Toolbar>
      <DialogContent sx={{ maxHeight: "80vh", padding: 0, backgroundColor: '#F0F4F8' }}>
        {children}
      </DialogContent>
      {buttonElements && (
        <DialogActions sx={{ padding: 1, backgroundColor: '#F0F4F8' }}>
          {buttonElements}
        </DialogActions>
      )}
    </Dialog>
  );
};
