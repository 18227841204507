import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { COMMAND_PRIORITY_EDITOR } from 'lexical';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import UpdateReportSectionModal from './UpdateReportSectionModal';
import { ReportSectionUpdatePayload } from '../../nodes/ReportSectionNode';
import { UPDATE_REPORT_SECTION_COMMAND, UpdateReportSectionCommandPayload } from '../common';
import { useTakerState } from '../../../../containers/TakerDocumentState/TakerDocumentState';

interface UpdateReportSectionPluginProps {
    reportSectionNode: ReportSectionUpdatePayload;
}

export default function UpdateReportSectionPlugin({
    reportSectionNode
}: UpdateReportSectionPluginProps): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const [commandPayload, setCommandPayload] = useState<UpdateReportSectionCommandPayload>();
    const { takerPermissionState } = useTakerState();

    const readOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                UPDATE_REPORT_SECTION_COMMAND,
                (payload) => {
                    const domSelection = window.getSelection();
                    if (domSelection !== null) {
                        domSelection.removeAllRanges();
                    }
                    setCommandPayload(payload);
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            )
        );
    }, [editor]);

    return (
        <>
            {!!commandPayload &&
                createPortal(
                    <UpdateReportSectionModal
                        readOnly={readOnly}
                        commandPayload={commandPayload}
                        onClose={() => setCommandPayload(undefined)}
                        targetReportSectionNode={reportSectionNode}
                    />,
                    document.body,
                )}
        </>
    );
}