import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  CircularProgress
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import { LanguageModel } from "../../redux/models/dataModelTypes";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { SwitchRhf } from "../../components/form/reactHookForm/switchRhf";
import { yupResolver } from "@hookform/resolvers/yup";
import {  useForm } from "react-hook-form";
import * as yup from "yup";
import FullConfig, { ConfigEntry } from "../../components/form/EntryBuilder";
import { DropDownItem } from "../../components/form/models/muiTypes";
import { useAddLanguageModelConfigurationEntryMutation, useAddLanguageModelConfigurationMutation, useDeleteLanguageModelConfigurationEntryMutation, useGetLanguageModelQuery, useUpdateLanguageModelMutation } from "../../redux/services/languageModel";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const langModelUpdateSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required()
});

const getKeyOptionsForConfigurationType = (type: string) => {
    if (type === "OPEN_AI") {
        return [
            {
                display: "Model Max Tokens", 
                id: "open_ai_model_max_tokens",
                description: `
                The max number of tokens that can be handled by the model.
                `
            },
            {
                display: "Model Identifier", 
                id: "open_ai_model_id",
                description: `
                This is the identifier for the model used as defined by the openai API documentation. 
                `
            },
            {
                display: "API Key", 
                id: "open_ai_api_key",
                description: `
                This is the secret API key found on https://platform.openai.com/account/api-keys.
                `
            },
            {
                display: "Organization ID",
                id: "open_ai_organization_id",
                description: ` 
                This is the organization ID for the model.
                `
            },
            {
                display: "Project ID",
                id: "open_ai_project_id",
                description: `
                This is the project ID for the model.
                `
            }
        ];
    } 
    return [];
}

const getKeyOptionsForKey: (k: string) => DropDownItem[] | undefined  = (k: string) => {
    
    // return nothing for free-formed entry
    return undefined;
}

interface langModelFormInputs {
    name: string;
    description: string;
}

interface LanguageModelUpdateFormProps {
    languageModel: LanguageModel;
}

const LanguageModelUpdateForm = ({ languageModel }: LanguageModelUpdateFormProps) => {
    const [updateLanguageModel, updateLMResult] = useUpdateLanguageModelMutation();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<langModelFormInputs>({
        resolver: yupResolver(langModelUpdateSchema),
        defaultValues: {
            name: languageModel.name,
            description: languageModel.description
        },
    });

    const handleSubmitCust = (d: langModelFormInputs) => {
        updateLanguageModel({
            id: languageModel.id,
            name: d.name, 
            description: d.description
        });
    };

    return (
        <form 
            onSubmit={handleSubmit(handleSubmitCust)}
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <TextFieldRhf
                sx={{ marginTop: "20px" }}
                label="Name"
                fullWidth={false}
                fieldName="name"
                variant="outlined"
                control={control}
                errors={errors}
            />
            <TextFieldRhf
                sx={{ marginTop: "20px" }}
                label="Description"
                multiline={true}
                fieldName="description"
                variant="outlined"
                control={control}
                errors={errors}
            />
            <Box sx={{ marginTop: "20px" }}>
                <Button 
                    variant="contained" 
                    type="submit"
                >
                    Save
                </Button>
            </Box>
        </form>
    );
}

const LanguageModelPage = () => {
    const { id } = useParams<{ id: any }>();
    const [tabValue, setTabValue] = useState(0);

    const { 
        data: languageModel,
        isFetching,
        isLoading
    } = useGetLanguageModelQuery(id);

    const [addLanguageConfigEntry, creatEntryResult] = useAddLanguageModelConfigurationEntryMutation();
    const [deleteLanguageConfigEntry, deleteEntryResult] = useDeleteLanguageModelConfigurationEntryMutation();
    const [addLanguageModelConfiguration, addLanguageModelConfigurationResult] = useAddLanguageModelConfigurationMutation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

    const options = useMemo(() => {
        if (!languageModel || !languageModel.configuration) {
            return [];
        }
        return getKeyOptionsForConfigurationType(languageModel.configuration.type);
    }, [languageModel]);

    const createConfiguration = async (type: string) => {
        if (languageModel) {
            addLanguageModelConfiguration({
                languageModel,
                body: { type }
            });
        }
    };

    const deleteConfigurationEntry = async (configEntryId: string) => {
        if (languageModel && languageModel.configuration) {
            deleteLanguageConfigEntry({
                languageModel,
                id: configEntryId
            });
        }
    };

    const createConfigurationEntry = async (key: string, data: string, encrypt: boolean) => {
        if (languageModel && languageModel.configuration) {
            addLanguageConfigEntry({
                languageModel, 
                body: {
                    key,
                    data,
                    encrypt
                }
            });
        }
    };

    return (
        <SideNav>
            {isFetching ? (
                <div style={{ padding: 2 }}>
                    <CircularProgress/>
                </div>
            ) : (
                <div style={{ padding: 2 }}>
                    <Tabs 
                        value={tabValue} 
                        onChange={handleChange} 
                        aria-label="tabs"
                    >
                        <Tab label="Properties" {...a11yProps(0)} />
                        <Tab label="Configuration" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        {languageModel && (
                            <LanguageModelUpdateForm languageModel={languageModel}/>
                        )}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {(languageModel && languageModel.configuration) ? (
                            <>
                                <FullConfig
                                    options={options}
                                    additionalFields={[
                                        {
                                            name: "encrypt",
                                            defaultValue: false,
                                            yupSchema: yup.boolean().required(),
                                            buildFieldComponent: (control: any) => (
                                                <SwitchRhf
                                                    sx={{ marginTop: "20px" }}
                                                    label="Encrypted"
                                                    fieldName="encrypt"
                                                    control={control}
                                                />
                                            )
                                        }
                                    ]}
                                    entries={
                                        languageModel.configuration.entries.map((entry) => ({
                                            id: entry.id,
                                            key: entry.key,
                                            rawData: entry.data,
                                            formattedData: (
                                                entry.isEncrypted ? (
                                                    [...Array(entry.data.length).keys()].map(_ => "*").join(" ")
                                                ) : (
                                                    entry.data
                                                )
                                            )
                                        } as ConfigEntry))
                                    }
                                    onDeleteEntry={(ce: ConfigEntry) => {
                                        deleteConfigurationEntry(ce.id);
                                    }}
                                    keyOptionsGetter={(k: string) => getKeyOptionsForKey(k)}
                                    onCreate={(d: any) => {
                                        createConfigurationEntry(d.key, d.data, d.encrypt);
                                    }}
                                />
                            </>
                        ) : (
                            <Button 
                                variant="outlined"
                                onClick={() => {
                                    createConfiguration("OPEN_AI");
                                }}
                            >
                                Create Configuration
                            </Button>
                        )}
                    </TabPanel>
                </div>
            )}
        </SideNav>
    );
}

export default LanguageModelPage;

