import { useState } from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Builder } from "../../redux/models/dataModelTypes";
import { KeyboardTab, OpenInFull } from "@mui/icons-material";
import { useListBuildersQuery } from "../../redux/services/builder";
import PagedTable from "../../components/tables/pagedTable";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";

interface Props {
  hasBuilderRole?: boolean;
  onOpenBuilder: (bm: Builder) => void;
}

const MyRecentBuilders = ({ 
  hasBuilderRole,
  onOpenBuilder
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);
  const { selectedOrgId } = useUserScopedAppData();

  const {
    data: builders,
    isFetching,
    isLoading,
  } = useListBuildersQuery({
    page, 
    limit, 
    organizationId: selectedOrgId,
    ownedByUser: (!!selectedOrgId ? false : true),
    status: hasBuilderRole ? undefined :"PUBLISHED"
  });

  return (
    <PagedTable
      isFetching={isFetching}
      isLoading={isLoading}
      paginatedResponse={builders}
      tableSpan={5}
      usePaperComponent
      rowMapper={(b: Builder) => (
        <TableRow
          key={b.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          data-testid={`builder-row-${b.id}`}
        >
          <TableCell>
            {b.name ? (
              b.name
            ) : (
              "no name"
            )}
          </TableCell>
          {hasBuilderRole && (
            <TableCell>{b.status}</TableCell>
          )}
          <TableCell>{new Date(b.createdAt).toLocaleString()}</TableCell>
          <TableCell>
            <CrudButtonGroup 
              buttons={[
                {
                  icon: (
                    <KeyboardTab 
                      sx={{ transform: "rotate(180deg)" }}
                      data-testid={`open-builder-button-${b.id}`}
                    />
                  ),
                  handleClick: () => {
                    onOpenBuilder(b)
                  },
                  tooltip: "Open Workflow"
                }
              ]} 
            />
          </TableCell>
        </TableRow>
      )}
      headers={
        hasBuilderRole ?
        [
          "Label",
          "Status",
          "Created",
          ""
        ] : [
          "Label",
          "Created",
          ""
        ]
      }
      page={page}
      limit={limit}
      setPage={setPage}
      setLimit={setLimit}
    />    
  );
}

export default MyRecentBuilders
