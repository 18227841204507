import React, { CSSProperties } from "react";
import { Controller } from "react-hook-form";
import { Switch, FormControlLabel } from "@mui/material/";

interface Props {
  label?: string;
  fieldName: string;
  disabled?: boolean;
  sx?: CSSProperties;
  control: any;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

export const SwitchRhf = ({ label, fieldName, disabled, sx, control, color }: Props) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        label ? (
          <FormControlLabel
            control={
              <Switch {...field} checked={field.value} color={color} />
            }
            label={label}
            sx={sx ?? {}}
            disabled={disabled ?? false}
          />
        ) : (
          <Switch 
            {...field} 
            color={color}
            disabled={disabled ?? false} 
          />
        )
      )}
    />
  );
};
