import React from "react";
import { Typography } from "@mui/material/";
import { useTakerState } from "../../../../containers/TakerDocumentState/TakerDocumentState";
import BoxWithHoverBg from "./BoxWithHoverBg";
import { useKeyTermsWrapper } from "../../../../containers/WidgetWrapper/wrapper";

interface KeyTermMetaProps {
    commentMetadata: Record<string, any>;
    onClick: () => void;
}

function KeyTermMeta({
    commentMetadata,
    onClick
}: KeyTermMetaProps) {
    const {
        takerDocumentUploads
    } = useTakerState();
    const { mutateState } = useKeyTermsWrapper();
    const {
        keyTermIdentifier,
        keyTermName,
        takerDocumentUploadId,
    } = commentMetadata;

    let targetGroupName = null;
    if (takerDocumentUploads) {
        for (const { id, name: groupName } of takerDocumentUploads) {
            if (id === takerDocumentUploadId) {
                targetGroupName = groupName;
                break;
            }
        }
    }

    return (
        <BoxWithHoverBg
            data-testid="key-term-meta"
            width="100%"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                mutateState({
                    scrollToKeyTermIdentifier: keyTermIdentifier
                });
                onClick();
            }}
        >
            {targetGroupName && (
                <Typography
                    variant="body2"
                    color="text.primary"
                    noWrap
                    data-testid="comments-drawer-group-doc-title"
                >
                    <strong>{targetGroupName}</strong>
                </Typography>
            )}
            <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                data-testid="comments-drawer-highlight-text"
            >
                {keyTermName}
            </Typography>
        </BoxWithHoverBg>
    );
}

export default KeyTermMeta;
