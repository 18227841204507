import React, { CSSProperties } from "react";
import { InputVariantType } from "../models/muiTypes";
import { Controller, FieldErrors } from "react-hook-form";
import { TextField } from "@mui/material/";

interface JsonFieldRhfMemoProps {
  label: string;
  variant?: InputVariantType;
  fullWidth?: boolean;
  fieldName: string;
  disabled?: boolean;
  sx?: CSSProperties;
  control: any;
  errors: FieldErrors<any>;
}

export const JsonFieldRhf = ({
  label,
  variant,
  fullWidth,
  fieldName,
  disabled,
  sx,
  control,
  errors,
}: JsonFieldRhfMemoProps) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          multiline
          sx={sx ?? {}}
          disabled={disabled ?? false}
          label={label}
          variant={variant ?? "standard"}
          fullWidth={fullWidth ?? false}
          error={!!errors[`${fieldName}`]}
          helperText={(errors[`${fieldName}`]?.message as string | undefined | null) ?? ""}
        />
      )}
    />
  );
};
