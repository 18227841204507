import { createApi } from '@reduxjs/toolkit/query/react';
import { AssistantMembership, Organization, OrganizationAssistantMembershipPayload } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { PaginatedResponse } from '../models/commonTypes';

export const organizationApi = createApi({
    reducerPath: 'organizationApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Organizations'],
    endpoints: (build) => ({
        listOrganizations: build.query<PaginatedResponse<Organization>, { page: number | void, limit: number | void }>({
            query: ({page = 1, limit = 10}) => ({
                url: `organizations?page=${page}&limit=${limit}`,
                method: "GET"
            }),            
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Organizations', id } as const)),
                        { type: 'Organizations', id: 'LIST' },
                    ]
                :
                    [{ type: 'Organizations', id: 'LIST' }],
        }),
        getOrganization: build.query<Organization, string>({
            query: (id) => ({
                url: `organizations/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'Organizations', id }],
        }),
        searchOrganizations: build.query<PaginatedResponse<Organization>, { page: number | void, limit: number | void, name: string | void }>({
            query: ({page = 1, limit = 10, name = ''}) => ({
                url: `organizations/name_search?page=${page}&limit=${limit}&name=${name}`,
                method: "GET"
            }),
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Organizations', id } as const)),
                        { type: 'Organizations', id: 'SEARCH' },
                    ]
                :
                    [{ type: 'Organizations', id: 'SEARCH' }],
        }),
        addOrganization: build.mutation<Organization, Partial<Organization>>({
            query(body) {
                return {
                    url: `organizations`,
                    method: 'POST',
                    data: body,
                }
            },
            invalidatesTags: [{ type: 'Organizations', id: 'LIST' }],
        }),
        addOrganizationAssistantMembership: build.mutation<AssistantMembership, Partial<OrganizationAssistantMembershipPayload>>({
            query(data) {
                const { assistantId, organizationId } = data
                return {
                    url: `organizations/${organizationId}/assistant_memberships`,
                    method: 'POST',
                    data: {
                        assistant_id: assistantId
                    },
                }
            },
            invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organizations', id: organizationId }],
        }),
    }),
})

export const {
    useListOrganizationsQuery,
    useGetOrganizationQuery,
    useSearchOrganizationsQuery,
    useAddOrganizationMutation,
    useAddOrganizationAssistantMembershipMutation
} = organizationApi;