import {
  useSelector as useSelectorNoType,
  useDispatch as useDispatchNoType,
  TypedUseSelectorHook,
} from "react-redux";
import { TypedDispatch, RootReducerType } from "../models/reduxTypes";

export const useDispatch = () => useDispatchNoType<TypedDispatch>();
export const useSelector: TypedUseSelectorHook<RootReducerType> = useSelectorNoType;

// export const actionCreator = (action: string) => {
//   const values = ["SUCCESS", "FAILURE", "REQUEST"];
//   const types = values.reduce((acc: any, value: string) => {
//     const type = `${action}_${value}`;
//     acc[value] = type;
//     acc[value.toLowerCase()] = (data: any) => ({
//       type,
//       data,
//     });
//     return acc;
//   }, {});
//   return types;
// };
