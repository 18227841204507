import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import React, { useEffect } from 'react';
import { InnerReportSectionNode } from '../../nodes/InnerReportSectionNode';

interface SyncInnerReportSectionNodesPluginProps {
    outOfSync: boolean;
    overriden: boolean;
}

export default function SyncInnerReportSectionNodesPlugin({
    outOfSync,
    overriden
}: SyncInnerReportSectionNodesPluginProps) {
    const [editor] = useLexicalComposerContext();
    
    useEffect(() => {
        return mergeRegister(
            editor.registerNodeTransform(InnerReportSectionNode, (innerReportSectionNode) => {
                // Synchronize this node's metadata to the outside state.
                innerReportSectionNode.__outOfSync = outOfSync;
                innerReportSectionNode.__overriden = overriden
            })
        );
    }, [
        editor,
        outOfSync,
        overriden
    ]);

    return null;
}