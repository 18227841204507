import { useCallback, useRef } from 'react';
import {
  BaseEdge,
  useReactFlow,
  useStore,
  type Edge,
  type EdgeProps,
  type XYPosition,
  EdgeText,
  getSmoothStepPath,
} from 'reactflow';

import { ControlPoint, type ControlPointData } from './ControlPoint';
import { getPath, getControlPoints } from './path';
import { Algorithm, COLORS } from './constants';

const useIdsForInactiveControlPoints = (points: ControlPointData[]) => {
  const prevIds = useRef<string[]>([]);
  let newPoints: ControlPointData[] = [];
  if (prevIds.current.length === points.length) {
    // reuse control points from last render, just update their position
    newPoints = points.map((point, i) =>
      point.active ? point : { ...point, id: prevIds.current[i] }
    );
  } else {
    // calculate new control points
    newPoints = points.map((prevPoint, i) => {
      const id = window.crypto.randomUUID();
      prevIds.current[i] = id;
      return prevPoint.active ? points[i] : { ...points[i], id };
    });
  }

  return newPoints;
};

export type EditableEdgeData = {
  points: ControlPointData[];
};

const color = COLORS[Algorithm.Linear];

export function EditableEdge({
  id,
  selected,
  source,
  sourceX,
  sourceY,
  sourcePosition,
  target,
  targetX,
  targetY,
  targetPosition,
  markerEnd,
  markerStart,
  style,
  data = { points: [] },
  label,
  ...delegated
}: EdgeProps<EditableEdgeData>) {
  const sourceOrigin = { x: sourceX, y: sourceY } as XYPosition;
  const targetOrigin = { x: targetX, y: targetY } as XYPosition;

  const { setEdges } = useReactFlow();

  const shouldShowPoints = useStore((store) => {
    const sourceNode = store.nodeInternals.get(source)!;
    const targetNode = store.nodeInternals.get(target)!;
    return selected || sourceNode.selected || targetNode.selected;
  });

  const setControlPoints = useCallback(
    (update: (points: ControlPointData[]) => ControlPointData[]) => {
      setEdges((edges) =>
        edges.map((e) => {
          if (e.id !== id) return e;
          if (!isEditableEdge(e)) return e;

          const points = e.data?.points ?? [];
          const data = { ...e.data, points: update(points) };

          return { ...e, data };
        })
      );
    },
    [id, setEdges]
  );

  const pathPoints = [
    sourceOrigin,
    ...data.points,
    targetOrigin
  ];
  const controlPoints = getControlPoints(pathPoints);
  const controlPointsWithIds = useIdsForInactiveControlPoints(controlPoints);

  if (data.points && data.points.length > 0) {
    const pathPoints = [
      sourceOrigin,
      ...data.points,
      targetOrigin
    ];

    const path = getPath(pathPoints);
    const labelPoint = controlPoints.length >= 2 ? controlPoints[1] : controlPoints[0];

    return (
      <>
        <BaseEdge
          id={id}
          path={path}
          {...delegated}
          markerStart={markerStart}
          markerEnd={markerEnd}
          style={{
            ...style,
            strokeWidth: 2,
            stroke: color,
          }}
        />
        {shouldShowPoints &&
          controlPointsWithIds.map((point, index) => (
            <ControlPoint
              key={point.id}
              index={index}
              setControlPoints={setControlPoints}
              color={color}
              {...point}
            />
          ))}
        {label ? (
          <EdgeText
            style={{
              pointerEvents: 'none',
              opacity: 0.25
            }}
            x={labelPoint.x}
            y={labelPoint.y}
            label={label}
          />
        ) : null}
      </>
    );
  } else {
    const [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition
    });
    return (
      <>
        <BaseEdge
          id={id}
          path={edgePath}
          {...delegated}
          markerStart={markerStart}
          markerEnd={markerEnd}
          style={{
            ...style,
            strokeWidth: 2,
            stroke: color,
          }}
        />
        {label ? (
          <EdgeText
            style={{
              pointerEvents: 'none',
              opacity: 0.25
            }}
            x={labelX}
            y={labelY}
            label={label}
          />
        ) : null}
      </>
    );
  }
}

const isEditableEdge = (edge: Edge): edge is Edge<EditableEdgeData> =>
  edge.type === 'editable-edge';
