import React, { useCallback, useMemo, useState } from "react";
import { SimpleModalWrapper } from "../../../dialog/wrappers/simpleModalWrapper";
import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material/";
import { useTakerState } from "../../../../containers/TakerDocumentState/TakerDocumentState";
import { useUpdateTakerDocumentStateMutation } from "../../../../redux/services/taker";
import { TakerDocumentReviewState, TakerDocumentState } from "../../../../redux/models/dataModelTypes";
import { RootReducerType } from "../../../../redux/models/reduxTypes";
import { useSelector } from "../../../../redux/reduxUtils/functions";
import CommentRow from "../../../comments/CommentRow";
import CommentLexical from "../../../comments/CommentLexical";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const steps: { state: TakerDocumentReviewState; text: string; }[] = [
    { state: "DRAFT", text: "Finish the Draft" },
    { state: "PENDING", text: "Review" },
    { state: "COMPLETED", text: "Mark as Complete" },
];

function ReviewModal({
    open,
    setOpen
}: Props) {
    const {
        takerDocumentId,
        activeTakerDocument,
        takerPermissionState,
        reviewComments,
        addReviewComment
    } = useTakerState();

    const [newLexical, setNewLexical] = useState<any>();
    const [newText, setNewText] = useState<string>();
    const { user: authedUser } = useSelector((state: RootReducerType) => state.auth);
    const [
        updateState,
        updateStateRes
    ] = useUpdateTakerDocumentStateMutation();

    const handleClose = () => {
        setOpen(false);
    };

    const topLevelReviewComments = useMemo(
        () => reviewComments.filter((comment) => comment.commentType === "TOP_LEVEL"),
        [reviewComments]
    );

    const activeReviewStep: number = useMemo(() => {
        if (!activeTakerDocument || !activeTakerDocument.state) {
            return -1;
        }

        let activeState = activeTakerDocument.state.reviewState;
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].state === activeState) {
                return i;
            }
        }
        return -1;
    }, [activeTakerDocument]);

    const addComment = (lexical: any, text: string | undefined, userId: string | undefined) => {
        if (!text) {
            return;
        }

        addReviewComment(lexical, text, "TOP_LEVEL", {});
        setNewLexical(undefined);
        setNewText(undefined);
    }

    const incrementReviewState = (takerDocumentState: TakerDocumentState, i: number) => {
        if (i === 2) {
            return;
        }

        let newState = steps[i + 1].state;
        updateState({
            id: takerDocumentState.id,
            takerDocumentId: takerDocumentState.takerDocumentId,
            reviewState: newState
        })
    }

    const decrementReviewState = (takerDocumentState: TakerDocumentState, i: number) => {
        if (i === 0) {
            return;
        }

        let newState = steps[i - 1].state;
        updateState({
            id: takerDocumentState.id,
            takerDocumentId: takerDocumentState.takerDocumentId,
            reviewState: newState
        })
    }

    const addCommentCallback = useCallback(() => {
        addComment(newLexical, newText, authedUser?.id);
    }, [newLexical, newText, authedUser]);

    const addCommentAndIncrement = useCallback(() => {
        if (activeTakerDocument && activeTakerDocument.state && activeReviewStep > -1) {
            addComment(newLexical, newText, authedUser?.id);
            incrementReviewState(activeTakerDocument.state, activeReviewStep);
        }
    }, [newLexical, newText, authedUser, activeTakerDocument]);

    const addCommentAndDecrement = useCallback(() => {
        if (activeTakerDocument && activeTakerDocument.state && activeReviewStep > -1) {
            addComment(newLexical, newText, authedUser?.id);
            decrementReviewState(activeTakerDocument.state, activeReviewStep);
        }
    }, [newLexical, newText, authedUser, activeTakerDocument]);

    const disableComment = useMemo(() =>
        !newText || newText.trim() === "",
        [newText]
    );

    let isReviewer = takerPermissionState.allGrants.includes("REVIEW");
    let isOwner = takerPermissionState.allGrants.includes("OWNER");

    const buttonElements = [];
    if (isReviewer || isOwner) {
        buttonElements.push(
            <Button
                variant="outlined"
                disabled={disableComment}
                onClick={addCommentCallback}
            >
                Post
            </Button>
        );
        if (isReviewer && activeReviewStep === 1) {
            buttonElements.push(
                <Button
                    sx={{ marginLeft: "5px" }}
                    variant="contained"
                    disabled={disableComment}
                    onClick={addCommentAndIncrement}
                >
                    Post and Mark as Completed
                </Button>
            );
        }
        if (isReviewer && activeReviewStep === 1) {
            buttonElements.push(
                <Button
                    sx={{ marginLeft: "5px" }}
                    variant="contained"
                    disabled={disableComment}
                    onClick={addCommentAndDecrement}
                >
                    Post and Move to Draft
                </Button>
            );
        }
        if (isOwner && activeReviewStep === 0) {
            buttonElements.push(
                <Button
                    sx={{ marginLeft: "5px" }}
                    variant="contained"
                    disabled={disableComment}
                    onClick={addCommentAndIncrement}
                >
                    Post and Send for Review
                </Button>
            );
        }
        if ((isOwner || isReviewer) && activeReviewStep === 2) {
            buttonElements.push(
                <Button
                    sx={{ marginLeft: "5px" }}
                    variant="contained"
                    disabled={disableComment}
                    onClick={addCommentAndDecrement}
                >
                    Post and Move back to Review
                </Button>
            );
        }
    }

    return (
        <SimpleModalWrapper
            headerText="Review Notes"
            open={open}
            handleClose={handleClose}
            maxWidth='md'
            buttonElements={buttonElements}
        >
            <Box
                padding={2}
                width="100%"
            >
                <Stepper activeStep={activeReviewStep} alternativeLabel>
                    {steps.map(({ state, text }) => (
                        <Step key={state}>
                            <StepLabel>{text}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box padding={1}>
                <Typography variant="subtitle1">Notes</Typography>
            </Box>
            <Box
                padding={1}
                overflow="auto"
            >
                <Grid container rowGap={1}>
                    {!!authedUser && (
                        (topLevelReviewComments.length > 0) ? topLevelReviewComments.map(comment => (
                            <CommentRow
                                viewerUser={authedUser}
                                comment={comment}
                                takerDocumentId={takerDocumentId}
                            />
                        )) : (
                            <i>no notes</i>
                        )
                    )}
                </Grid>
            </Box>
            <Box padding={1} marginTop={1}>
                <Typography variant="subtitle1">New Note</Typography>
                <Typography variant="subtitle2" color="text.secondary">
                    Compose a new note here to add to the review.
                </Typography>
            </Box>
            <Box
                padding={1}
                width="100%"
            >
                {(isReviewer || isOwner) ? (
                    <Box height="100%">
                        <Grid
                            container
                            sx={{
                                padding: 1,
                                border: "1px solid rgba(221, 221, 221, 0.5)",
                                backgroundColor: "rgba(221, 221, 221, 0.05)",
                                borderRadius: 1,
                                overflow: "auto",
                                height: "70%"
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    height: "100%"
                                }}
                            >
                                <CommentLexical
                                    namespace={crypto.randomUUID()}
                                    lexical={newLexical}
                                    onUpdate={(lexicalContent, textContent) => {
                                        setNewLexical(lexicalContent);
                                        setNewText(textContent);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Typography>
                        Posting is disabled
                    </Typography>
                )}
            </Box>
        </SimpleModalWrapper>
    );
}

export default ReviewModal;
