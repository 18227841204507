import { Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { Delete } from "@mui/icons-material";
import ConfirmationDialog from "../dialog/GenericConfirmation";

interface DeleteWithConfirmationButtonProps {
    onDelete: () => void;
    confirmationText: string;
    icon?: JSX.Element;
    useDefaultButtonStyle?: boolean;
    defaultButtonText?: string;
    disabled?: boolean;
    variant?: "contained" | "outlined" | "text";
    hasDeleteIcon?: boolean;
    tooltipTitle?: string;
}

const DeleteWithConfirmationButton = ({
    onDelete,
    confirmationText,
    icon,
    useDefaultButtonStyle = false,
    defaultButtonText,
    disabled = false,
    variant = "contained",
    hasDeleteIcon = false,
    tooltipTitle
}: DeleteWithConfirmationButtonProps) => {
    const [needConfirm, setNeedConfirm] = useState<boolean>(false);

    const deleteClicked = () => {
        setNeedConfirm(true);
    }

    return (
        <>
            {useDefaultButtonStyle ? (
                <Button
                    disabled={disabled}
                    data-testid="DeleteWithConfirmationButtonDefaultStyle"
                    color="error"
                    variant={variant}
                    onClick={() => {
                        deleteClicked();
                    }}
                    startIcon={hasDeleteIcon && <Delete />}
                >
                    {defaultButtonText ?? "Delete"}
                </Button>
            ) : (
                <Tooltip title={tooltipTitle ?? "Delete"}>                            
                    <IconButton
                        disabled={disabled}
                        data-testid="DeleteWithConfirmationButton"
                        onClick={() => {
                            deleteClicked();
                        }}
                    >
                        {icon ?? <Delete data-testid="DeleteIcon"/>}
                    </IconButton>
                </Tooltip>
            )}
            {needConfirm && (
                <ConfirmationDialog
                    title="Confirmation"
                    confirmationText={confirmationText}
                    onConfirmed={() => {
                        onDelete();
                        setNeedConfirm(false);
                    }}
                    onCancelled={() => {
                        setNeedConfirm(false);
                    }}
                />
            )}
        </>
    );
};

export default DeleteWithConfirmationButton;