import { Module, Root } from "./builderv2.generated";

export type QuestionType = "radio" | "select" | "input" | "end";


function find(moduleIds: string[], top: Module | undefined): Module | undefined {
  let first = moduleIds.shift();
  if (!top || first !== top.id) {
    return;
  } else if (moduleIds.length === 0) {
    return top;
  }

  if (top.nestedModules) {
    for (const n of top.nestedModules) {
      let m = find([...moduleIds], n);
      if (m) {
        return m;
      }
    }
  }
}

export class BuilderHolder {
  builder: Root;

  constructor(builder: Object) {
    this.builder = (builder as Root);
  }

  moduleRequiresIterations(moduleIds: string[]) {
    let m = find([...moduleIds], this.builder.topModule);
    return m?.dataSpec?.dataSpecType === "basic-table";
  }

  getModule(moduleIds: string[]): Module | undefined {
    return find([...moduleIds], this.builder.topModule);
  }

  getKeyTerms() {
    if (this.builder.keyTerms) {
      return this.builder.keyTerms;
    }
    return [];
  }

  getPositionedModuleList() {
    if (!this.builder.topModule) {
      return [];
    }

    const moduleList: { path: string[]; module: Module; }[] = [{
      path: [],
      module: this?.builder.topModule
    }];

    const addModules = (m: Module, path: string[]) => {
      if (m.uiFulfillment?.fulfillmentType === "graph" && m.dataSpec?.dataSpecType === "basic") {
        if (m.uiFulfillment.nodes) {
          for (const n of m.uiFulfillment.nodes) {
            if (n.nodeType === "module") {
              const praxiModule = this.getModule([...path, m.id, n.moduleId]);
              if (!praxiModule) {
                throw Error(`module with moduleId=${[...path, m.id, n.moduleId]} doesn't exist`)
              } else if ((praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table")) {
                moduleList.push({
                  path: [...path, m.id],
                  module: praxiModule
                });
              }
            }
          }
        }
      }

      if (m.nestedModules) {
        for (const nm of m.nestedModules) {
          addModules(nm, [...path, m.id]);
        }
      }
    }

    addModules(this.builder.topModule, []);

    return moduleList;
  }
}
