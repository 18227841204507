import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { ContainerReportTemplate, ContainerWithNestedReportTemplate, ContainerGroupTemplate } from "../../../types/builderv2.generated";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Edit } from "@mui/icons-material";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";

type ReportTemplate = ContainerReportTemplate
    | ContainerWithNestedReportTemplate
    | ContainerGroupTemplate;

interface NodeRowProps {
    reportTemplate: ReportTemplate; 
    setEditReportTemplate: (n: ReportTemplate) => void;
}

const ReportRow = ({ 
    reportTemplate, 
    setEditReportTemplate
}: NodeRowProps) => {

    const getLabel = () => {
        switch (reportTemplate.sectionType) {    
            case "container":
            case "container-with-nested":
                return reportTemplate.sectionLabel;  
            default:
                return "Unknown";          
        }
    }

    return (
        <React.Fragment>    
            <TableRow 
                sx={{ 
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    {reportTemplate.sectionId}
                </TableCell>
                <TableCell component="th" scope="row">
                    {getLabel()}
                </TableCell>
                <TableCell scope="row">
                    {reportTemplate.sectionType}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup
                        buttons={[
                            {
                                icon: (<Edit fontSize="small"/>),
                                handleClick: () => {
                                    setEditReportTemplate(reportTemplate);
                                }
                            },
                            
                        ]} 
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

interface ReportEditorProps {
    fullSchema: RJSFSchema;
    initialReportTemplates?: ReportTemplate[];
    onUpdate: (rts: ReportTemplate[]) => void;
} 

const ReportEditor = ({ 
    fullSchema,
    initialReportTemplates,
    onUpdate
}: ReportEditorProps) => {
    const [editReportTemplate, setEditReportTemplate] = React.useState<ReportTemplate | null>(null);
    const [reports, setReports] = React.useState<ReportTemplate[]>([]);

    useEffect(() => {
        if (initialReportTemplates) {
            setReports(JSON.parse(JSON.stringify(initialReportTemplates)));
        }
    }, [initialReportTemplates]);

    const updateReport = (rt: ReportTemplate) => {
        const newReports = JSON.parse(JSON.stringify(reports));
        for (let i = 0; i < newReports.length; i ++) {
            if (rt.sectionId === newReports[i].sectionId) {
                newReports[i] = rt;
            }
        }
        onUpdate(newReports);
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Section ID
                            </TableCell>
                            <TableCell>
                                Label
                            </TableCell>
                            <TableCell>
                                Type
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports.map((rt) => (
                            <ReportRow 
                                key={rt.sectionId} 
                                reportTemplate={rt}
                                setEditReportTemplate={setEditReportTemplate}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleModalWrapper
                headerText="Edit Report"
                open={!!editReportTemplate}
                handleClose={() => setEditReportTemplate(null)}
                maxWidth='md'
            >
                <Box 
                    sx={{ 
                        width: '100%',
                        paddingTop: '3%',
                        alignContent: "center"
                    }}
                >
                    {editReportTemplate && (
                       <Form
                            formData={editReportTemplate}
                            onSubmit={(data, event) => {
                                updateReport(data.formData);
                                setEditReportTemplate(null)
                            }}
                            schema={({
                                "anyOf": [
                                    { "$ref": "#/$defs/containerReportTemplate" },
                                    { "$ref": "#/$defs/containerWithNestedReportTemplate" },
                                    { "$ref": "#/$defs/containerGroupTemplate" }
                                ],
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator} 
                        />  
                    )}
                </Box>
            </SimpleModalWrapper>
        </>
    );
  }

export default ReportEditor;
