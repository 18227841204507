import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material/";
import { useDeleteTakerDocumentReviewCommentMutation } from "../../redux/services/taker";
import { MoreHoriz } from "@mui/icons-material";
import { TakerDocumentReviewComment, User } from "../../redux/models/dataModelTypes";

interface CommentOptionsMenuProps {
    viewerUser: User;
    comment: TakerDocumentReviewComment;
    takerDocumentId: string;
    disableDelete?: boolean;
    extraMenuItems?: {
        onClick: () => void;
        label: string;
    }[];
}

/**
 * CommentOptionsMenu is a component that renders a menu of options for a comment.
 * If the viewer is the same as the author of the comment, the menu will include a
 * "Delete" option. If extraMenuItems is provided, those will be added to the menu.
 * The component will only render something if there are menu items to show.
 * 
 * @param {{ 
 *  viewerUser: User; 
 *  comment: TakerDocumentReviewComment; 
 *  takerDocumentId: string; 
 *  extraMenuItems?: { onClick: () => void; label: string; }[]; 
 * }} props
 * @returns {JSX.Element}
 */
function CommentOptionsMenu({
    viewerUser,
    comment,
    takerDocumentId,
    disableDelete = false,
    extraMenuItems
}: CommentOptionsMenuProps) {
    const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteComment, deleteCommentRes] = useDeleteTakerDocumentReviewCommentMutation();

    const open = Boolean(settingsAnchorEl);

    let menuItems = [];
    if (viewerUser.id === comment.userId && !disableDelete) {
        menuItems.push(
            <MenuItem
                color="inherit"
                disableRipple
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    deleteComment({
                        id: comment.id,
                        takerDocumentId
                    });
                    setSettingsAnchorEl(null);
                }}
            >
                Delete
            </MenuItem>
        );
    }

    if (extraMenuItems) {
        for (const extraMenuItem of extraMenuItems) {
            menuItems.push(
                <MenuItem
                    color="inherit"
                    disableRipple
                    onClick={(e: any) => {
                        e.stopPropagation();
                        extraMenuItem.onClick();
                        setSettingsAnchorEl(null);
                    }}
                >
                    {extraMenuItem.label}
                </MenuItem>
            );
        }
    }

    if (menuItems.length > 0) {
        return (
            <>
                <IconButton
                    data-testid="comment-options-button"
                    size="small"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        setSettingsAnchorEl(event.currentTarget);
                    }}
                >
                    <MoreHoriz />
                </IconButton>
                <Menu
                    data-testid="comment-options-menu"
                    elevation={1}
                    anchorEl={settingsAnchorEl}
                    open={open}
                    onClose={() => setSettingsAnchorEl(null)}
                >
                    {menuItems}
                </Menu>
            </>
        );
    }
    return null;
}

export default CommentOptionsMenu;
