import React, { Suspense, useEffect, useState } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "../../containers/WidgetWrapper/wrapper";
import { MemoGenerationState } from "../../containers/WidgetWrapper/states";
import ReportToolbar from "./ReportToolbar";
import DisplayReport from "./DisplayReport";
import CommentsDrawer from "../../components/taker/CommentsDrawer";
import { useSideNav } from "../../components/navigation/SideNav";
import RectangularSkeleton from "../../components/loading/RectangularSkeleton";

const buildInitialReportState = (savedState: string | null) => {
    const state: MemoGenerationState = (!!savedState && JSON.parse(savedState)) || {
        generatedReportsModalOpen: false
    };
    return state;
};

const TakerReport = () => {
    const { isOpen: isSideNavOpen } = useSideNav();
    const navigate = useNavigate();
    const theme = useTheme();
    const {
        taker,
        takerDocumentId
    } = useTakerState();
    const [widgetState, setWidgetState] = useState<MemoGenerationState>(
        buildInitialReportState(
            localStorage.getItem(`MemoGenerationState-${takerDocumentId}`),
        )
    );

    /**
     * Sync widgetState with localStorage.
    */
    useEffect(() => {
        if (!!widgetState) {
            const widgetStateCopy = { ...widgetState };
            delete widgetStateCopy.currentEditor;
            localStorage.setItem(`MemoGenerationState-${takerDocumentId}`, JSON.stringify(widgetStateCopy));
        }
    }, [widgetState]);

    const contentStyle: React.CSSProperties = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    };
    if (isSideNavOpen) {
        contentStyle.width = 'calc(100vw - 200px)';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        });
    } else {
        contentStyle.width = '100vw';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
    }

    return (
        <Box sx={contentStyle}>
            <WidgetWrapper
                widgetState={widgetState}
                setPartialWidgetState={(s) => {
                    setWidgetState(Object.assign({}, widgetState, s));
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: 1.5,
                        paddingRight: 1.5,
                        borderRadius: 1,
                        backgroundColor: "#F0F4F8",
                        borderBottom: "1px solid rgb(228, 228, 228, 0.9)"
                    }}
                >
                    <Stack width="100%">
                        <TakerToolbar
                            onClickBack={() => {
                                if (taker) {
                                    navigate(`/mainTaker/${taker.id}/analysis`);
                                }
                            }}
                            workflowDisplay={"Report"}
                            workflowDescription={`Please review a generated report.`}
                        />
                        <ReportToolbar />
                    </Stack>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        overflow: 'hidden'
                    }}
                    flexGrow={1}
                >
                    <CommentsDrawer commentTypes={[]} disabled>
                        <Suspense fallback={<RectangularSkeleton/>}>
                            <DisplayReport />
                        </Suspense>
                    </CommentsDrawer>
                </Box>
            </WidgetWrapper>
        </Box>
    );
}

export default TakerReport;
