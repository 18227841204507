import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import DefaultLoadingScreen from "../loading/defaultLoadingScreen";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { pageOption } from "./../../data/pages";
import UserScopedAppDataContainer from "../../containers/UserScopedAppData/UserScopedAppData";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { User } from "../../redux/models/dataModelTypes";
import { store } from "../../redux/store";
import { authAction } from "../../redux/actions/authActions";
import { AUTH0_TOKEN_KEY } from "../../utils/constants";
const API_ENDPOINT = window.__RUNTIME_CONFIG__.API_ENDPOINT;

interface RoleGatedRoutesProps {
  roles?: Array<string>;
}

const GatedRoutes = ({ roles }: RoleGatedRoutesProps) => {
  const {
    authenticated,
    user,
    loaded
  } = useSelector((state: RootReducerType) => state.auth);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const fetchProtectedData = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.post(`${API_ENDPOINT}/v1/current_user_auth0`, {}, {
      headers: { 'Auth0-Authorization': `Bearer ${token}` },
      withCredentials: true
    });
    localStorage.setItem(AUTH0_TOKEN_KEY, token);
    const userData = response.data as User;
    store.dispatch({
      type: authAction.SET_USER_SUCESS,
      payload: userData
    });
  }

  useEffect(() => {
    if (!authenticated && loaded && !isAuthenticated && !isLoading) {
      navigate("/");
    } else if (!authenticated && loaded && isAuthenticated && !isLoading) {
      fetchProtectedData()
    }
  }, [authenticated, loaded, isAuthenticated, isLoading]);

  if (!user || !loaded) {
    return <DefaultLoadingScreen />
  }

  const shouldAllow = authenticated && (!roles || user.roles.some((r: string) => roles.includes(r)));
  return shouldAllow ? (
    <UserScopedAppDataContainer>
      <Outlet />
    </UserScopedAppDataContainer>
  ) : (
    <Navigate to={pageOption.SIGN_IN.route} />
  );
};

export default GatedRoutes;

