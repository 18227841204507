import { useEffect, useMemo, useState } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Autocomplete, Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material/";
import { useAddUserOrganizationMembershipMutation, useDeleteUserOrganizationMembershipMutation } from "../../redux/services/user";
import { useSearchOrganizationsQuery } from "../../redux/services/organization";
import { Organization, OrganizationMembership, User } from "../../redux/models/dataModelTypes";
import { useSnackbar } from "notistack";
import DeleteWithConfirmationButton from "../../components/buttons/DeleteWithConfirmationButton";


interface Props {
    user: User;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManageOrganizationMemberships = ({ user, open, setOpen }: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<Organization | null>(null);
    const { data: organizationOptions } = useSearchOrganizationsQuery({
        page: 0,
        limit: 10,
        name: inputValue
    });
    const [addUserOrganizationMembership, addUserOrganizationMembershipResult] = useAddUserOrganizationMembershipMutation();
    const [deleteUserOrganizationMembership, deleteUserOrganizationMembershipResult] = useDeleteUserOrganizationMembershipMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (addUserOrganizationMembershipResult.isError && addUserOrganizationMembershipResult.error) {
            if ('data' in (addUserOrganizationMembershipResult.error as any)) {
                enqueueSnackbar(
                    (addUserOrganizationMembershipResult.error as any)['data']['error'],
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        persist: true
                    }
                );
            } else {
                enqueueSnackbar(
                    "Error adding organization.",
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        persist: true
                    }
                );
            }
        }
    }, [addUserOrganizationMembershipResult]);

    return (
        <SimpleModalWrapper
            headerText="Manage Memberships"
            open={open}
            handleClose={handleClose}
            maxWidth='md'
        >
            <Grid container>
                <Grid
                    xs={12}
                    item
                    paddingTop={1}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Type
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Created At
                                </TableCell>
                                <TableCell/>
                            </TableHead>
                            <TableBody>
                                {(user.organizationMemberships.length > 0) ? user.organizationMemberships.map((om: OrganizationMembership) => (
                                    <TableRow
                                        key={om.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {om.organization.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {om.type}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {new Date(om.createdAt).toLocaleString()}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                            <DeleteWithConfirmationButton
                                                onDelete={() => deleteUserOrganizationMembership(om)}
                                                confirmationText={`Are you sure you want to delete ${om.type} membership for ${om.organization.name}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={3} align="left">
                                            <i>none</i>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid
                    item
                    xs={12}
                    paddingBottom={2}
                >
                    <Stack direction="row" spacing={2}>
                        <Autocomplete
                            size="small"
                            sx={{ minWidth: "250px" }}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.name
                            }
                            filterOptions={(orgs) => 
                                orgs.filter(org => !user.organizationMemberships.find(om => om.organization.id === org.id))
                            }
                            options={organizationOptions?.data || []}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="No Organizations"
                            onChange={(event: any, newValue: Organization | null) => {
                                setValue(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Add an organization" fullWidth />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                <Box component="span">
                                                    {option.name}
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Added {new Date(option.createdAt).toLocaleString()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (value) {
                                    addUserOrganizationMembership({
                                        userId: user.id,
                                        organizationId: value.id
                                    });
                                    setValue(null);
                                }
                            }}
                        >
                            Add
                        </Button>
                    </Stack>
                </Grid>
                <Grid
                    xs={12}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </SimpleModalWrapper>
    );
};

