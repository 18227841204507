import React, { useEffect, useMemo, useState } from "react";
import KeyTermGroup from "./KeyTermGroup";
import { Box, Stack, useTheme } from "@mui/material";
import TakerToolbar from "../../components/taker/TakerToolbar/TakerToolbar";
import { useTakerState } from "../../containers/TakerDocumentState/TakerDocumentState";
import { KeyTermsWrapper, HighlightsWrapper } from "../../containers/WidgetWrapper/wrapper";
import { useNavigate } from "react-router-dom";
import MainToolbar from "./MainToolbar";
import CommentsDrawer from "../../components/taker/CommentsDrawer";
import { useSideNav } from "../../components/navigation/SideNav";

const TakerKeyTerms = () => {
    const { isOpen: isSideNavOpen } = useSideNav();
    const { takerPermissionState, taker, takerDocumentId } = useTakerState();
    const navigate = useNavigate();
    const theme = useTheme();

    const isReadOnly = useMemo(() =>
        takerPermissionState.isRead && !takerPermissionState.isReadWrite,
        [takerPermissionState]
    );

    const contentStyle: React.CSSProperties = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    };
    if (isSideNavOpen) {
        contentStyle.width = 'calc(100vw - 200px)';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        });
    } else {
        contentStyle.width = '100vw';
        contentStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
    }

    return (
        <Box style={contentStyle}>
            <KeyTermsWrapper >
                <HighlightsWrapper>
                    <Box
                        sx={{
                            width: "100%",
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 1.5,
                            paddingRight: 1.5,
                            borderRadius: 1,
                            backgroundColor: "#F0F4F8",
                            borderBottom: "1px solid rgb(228, 228, 228, 0.9)"
                        }}
                    >
                        <Stack width="100%">
                            <TakerToolbar
                                data-testid="taker-toolbar"
                                onClickForward={() => {
                                    if (taker) {
                                        navigate(`/mainTaker/${taker.id}/analysis`);
                                    }
                                }}
                                expectedCommentTypes={["DOCUMENT_HIGHLIGHT", "KEY_TERM"]}
                                workflowDisplay={"Key Terms"}
                                workflowDescription={`Please upload a document to begin annotating.`}
                            />
                            <MainToolbar data-testid="main-toolbar" readOnly={isReadOnly} />
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            overflow: 'hidden'
                        }}
                        flexGrow={1}
                    >
                        <CommentsDrawer commentTypes={["DOCUMENT_HIGHLIGHT", "KEY_TERM"]}>
                            <KeyTermGroup data-testid="key-term-group" readOnly={isReadOnly} />
                        </CommentsDrawer>
                    </Box>
                </HighlightsWrapper>
            </KeyTermsWrapper>
        </Box>
    );
}

export default TakerKeyTerms;
