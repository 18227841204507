import { useState } from "react";
import SideNav from "../../components/navigation/SideNav";
import { Box, Paper, Typography, Fab } from "@mui/material/";
import { ApplicationTable } from "./applicationTable";
import { PlusIcon } from "../../assets/icons";
import { CreateApplicationModal } from "./CreateApplicationModal";


const ManageApplications = () => {
  const [createAppToggle, setCreateAppToggle] = useState(false);
  return (
    <SideNav>
      <Box
        sx={{
          padding: 2,
          width: "100%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Manage Application Configurations
            </Typography>
          </Box>
          <Fab
            variant="extended"
            color="primary"
            size="large"
            sx={{ fontWeight: "bold" }}
            component="button"
            onClick={(e: any) => setCreateAppToggle(true)}
          >
            <PlusIcon sx={{ mr: 1 }} />
            Create Application
          </Fab>
        </Box>
      </Box>
      <Box
        sx={{
          padding: 2,
          width: "100%"
        }}
      >
        <ApplicationTable />
      </Box>
      <CreateApplicationModal open={createAppToggle} setOpen={setCreateAppToggle} />
    </SideNav>
  );
};

export default ManageApplications;
