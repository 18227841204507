import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IndexedGuidance } from "../../redux/models/dataModelTypes";
import PagedTable from '../../components/tables/pagedTable';
import { useListIndexedGuidancesQuery } from '../../redux/services/indexedGuidance';
import { CopyAll, PlayArrow } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { CrudButtonGroup } from '../../components/buttons/crudButtonGroup';

export const IndexedGuidanceTable = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const navigate = useNavigate();

    const { data: indexedGuidance } = useListIndexedGuidancesQuery({ page, limit });

    return (
        <>
            <PagedTable
                paginatedResponse={indexedGuidance}
                tableSpan={4}
                rowMapper={(ig: IndexedGuidance) => (
                    <TableRow
                        key={ig.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {`${ig.name} `}
                            <IconButton 
                                size="small"
                                onClick={() => {
                                    navigator.clipboard.writeText(ig.id);
                                }}
                            >
                                <CopyAll/>
                            </IconButton>
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                            {ig.description}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                            {new Date(ig.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell>
                            <CrudButtonGroup 
                                buttons={[
                                    {
                                        icon: (<PlayArrow fontSize="small" />),
                                        handleClick: () => {
                                            navigate(`/indexedGuidance/${ig.id}`);
                                        }
                                    }
                                ]} 
                            />
                        </TableCell>
                    </TableRow>
                )}
                headers={[
                    "Name",
                    "Description",
                    "Created",
                    ""
                ]}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
            />
        </>
    );
}
