export const dummyTakerStateData = {
    "lockedStatus": {},
    "questionAnalyses": {
        "data": {
            "fields": [],
            "type": "ANALYSES"
        },
        "moduleId": "top",
        "nested": [
            {
                "data": {
                    "fields": [],
                    "type": "ANALYSES"
                },
                "moduleId": "background",
                "nested": []
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANALYSES"
                },
                "moduleId": "asc606Scope",
                "nested": [
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "EFFECTIVE_DATE_QID",
                                    "value": "Per the Key Terms, the Agreement was signed on 7/1/2024."
                                },
                                {
                                    "key": "TERM_QIS",
                                    "value": "Although the Agreement states the Term is 5 years, knowing Termination for Cause allows for Termination each 12 month anniversary of the Effective Date, for accounting purposes the term is 1 year."
                                }
                            ],
                            "type": "ANALYSES"
                        },
                        "moduleId": "introDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "contractQtc",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "PARTIES_QTC",
                                            "value": ""
                                        }
                                    ],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "partiesQtc",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "partialScope",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "contractPartsQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "partialScopeLoop",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANALYSES"
                },
                "moduleId": "step1IdentifyTheContracts",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "setupCosts",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "setupActivitiesQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "setupActivitiesCapitalizedLoop",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_QUALIFICATION_QRS",
                                    "value": "As Company enters into agreements similar to Agreement with other customers, and given that the Customer has previously done business with the Company, Management knows the Agreement qualifies as a contract under ASC 606-10-25-1."
                                }
                            ],
                            "type": "ANALYSES"
                        },
                        "moduleId": "contractQualification",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_MODIFICATION_QRS",
                                    "value": "The Agreement is unrelated to any other agreement; therefore, Management knows it is not a modification under ASC 606-10-25-10 and ASC 606-10-25-11."
                                }
                            ],
                            "type": "ANALYSES"
                        },
                        "moduleId": "contractModification",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "allocateToPriorPobs",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANALYSES"
                },
                "moduleId": "step2IdentifyThePerformanceObligations",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "identifyDistinctPromises",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "contingencyOrGuarantee",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "IMMATERIAL_PROMISE_QTM",
                                            "value": "Management knows similar arrangements with other customers often include tickets to various events the Company hosts. According to company policy, these additional values are considered immaterial to the context of the contract and are therefore expensed as incurred."
                                        }
                                    ],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "immaterialPromiseBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "SERIES_QTS",
                                            "value": "According to Example RR 9-5 and Section 3.3.2 Promise to Transfer a Series of Distinct Goods or Services in the PwC 606 Guide, the License should be treated as a single performance obligation, representing a series of distinct goods and services. Additionally, since the License grants a right to use intellectual property, it will be recognized evenly over the license term.\n\nAlthough the Advertising Services are a bundle of goods and services, they should be accounted for as a single performance obligation in this context. Each specific advertising service is capable of being distinct, as Customer could benefit from each service on its own or together with other readily available resources. However, these advertising services are not separately identifiable from the other advertising promises in Agreement. Company’s overall promise in the agreement is to deliver a combined advertising campaign, where the individual services are integral components.\n\nKnowing this, Management will account for the Advertising Services as a single performance obligation, representing a series of distinct goods and services. The revenue for Advertising Services will be recognized using the input method based on costs incurred."
                                        }
                                    ],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "seriesBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "promiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": "Time elapsed is the most accurate representation."
                                    }
                                ],
                                "type": "ANALYSES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": "Costs explicitly chargeable to Customer under the Agreement related to the Advertising Services is the most accurate representation."
                                    }
                                ],
                                "type": "ANALYSES"
                            }
                        ],
                        "moduleId": "satisfactionOfPerformanceObligations",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANALYSES"
                },
                "moduleId": "step3DetermineTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "considerationRecievable",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "CONSDIERATION_RECIEVABLE_BREAKOUT_QTS",
                                            "value": "Milestone payments qualify for the variable consideration exception under ASC 606-10-55-65. Knowing this, Management does not need to estimate the variable consideration."
                                        }
                                    ],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "considerationTypeCount",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "considerationPayableToTheCustomer",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "considerationPayableBreakout",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "considerationPayableForDistinctGoodOrServiceStart",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "Stated in License Fee."
                                    }
                                ],
                                "type": "ANALYSES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "Stated in Advertising Services Fee."
                                    }
                                ],
                                "type": "ANALYSES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "Stated in License Fee."
                                    }
                                ],
                                "type": "ANALYSES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "Stated in License Fee."
                                    }
                                ],
                                "type": "ANALYSES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "Stated in License Fee."
                                    }
                                ],
                                "type": "ANALYSES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "Stated in License Fee."
                                    }
                                ],
                                "type": "ANALYSES"
                            }
                        ],
                        "moduleId": "considerationToValueLoop",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANALYSES"
                },
                "moduleId": "step4AllocateTheTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "transactionPriceAllocationForSeriesDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "seriesPromiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "noConsiderationToAllocate",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "considerationAllocation",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "ALLOCATE_BY_PERCENT_OF_TRANSACTION_PRICE_QTM",
                                            "value": "Management knows each performance obligation is priced relatively close to its standalone selling price; therefore, each performance obligation's transaction price reflects the amount Customer is paying for the specific good or service."
                                        }
                                    ],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "allocateByPercentOfConsideration",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANALYSES"
                },
                "moduleId": "step5RecoginzeRevenue",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "immaterialPromisesAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "IMMATERIAL_PROMISE_COST_BREAKOUT_QTS",
                                            "value": ""
                                        }
                                    ],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "immaterialPromiseBreakout2",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "shippingAndHandlingAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "accrualOfShipping",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "journalEntries",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "MANUAL_ENTRIES_END_QTM",
                                            "value": "With the License Fee recognized evenly over time and Event Tickets transferred 30 days prior to the event, management has clarity on the scheduling of these entries. Revenue from Advertising Services should be recognized with a debit to Accounts Receivable or cash and a credit to revenue as the services are performed. Revenue related to Royalty Goals should be recognized in the period when those goals are met, also with a debit to Accounts Receivable or cash and a credit to revenue."
                                        }
                                    ],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "manualJeTool",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANALYSES"
                        },
                        "moduleId": "outOfScopeAccounting",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANALYSES"
                                },
                                "moduleId": "outOfScopePobsAfterAllocationCheck",
                                "nested": []
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "questionAnswers": {
        "data": {
            "fields": [],
            "type": "ANSWERS"
        },
        "moduleId": "top",
        "nested": [
            {
                "data": {
                    "fields": [
                        {
                            "key": "COMPANY_QIS",
                            "value": "Company"
                        },
                        {
                            "key": "CURRENCY_QIS",
                            "value": "USD"
                        },
                        {
                            "key": "TRANSACTION_QIS",
                            "value": "MSLA for IP"
                        },
                        {
                            "key": "STANDARD_QIS",
                            "value": "US GAAP"
                        },
                        {
                            "key": "PUBLIC_QIS",
                            "value": "yes"
                        },
                        {
                            "key": "AUDITOR_QIS",
                            "value": "PwC"
                        }
                    ],
                    "type": "ANSWERS"
                },
                "moduleId": "background",
                "nested": []
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANSWERS"
                },
                "moduleId": "asc606Scope",
                "nested": [
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "EFFECTIVE_DATE_QID",
                                    "value": "7/1/2024"
                                },
                                {
                                    "key": "TERM_QIS",
                                    "value": "12 months"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "introDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "CONTRACT_QIS",
                                            "value": [
                                                "MSLA"
                                            ]
                                        },
                                        {
                                            "key": "PART_OR_ENT_SCOPE_QRS",
                                            "value": [
                                                "Entirely"
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "contractQtc",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "PARTY_QIS",
                                            "value": [
                                                "Customer"
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "partiesQtc",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANSWERS"
                        },
                        "moduleId": "partialScope",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "contractPartsQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "partialScopeLoop",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANSWERS"
                },
                "moduleId": "step1IdentifyTheContracts",
                "nested": [
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "SETUP_ACTIVITIES_QRS",
                                    "value": "no"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "setupCosts",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "setupActivitiesQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "setupActivitiesCapitalizedLoop",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_QUALIFICATION_QRS",
                                    "value": "yes"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "contractQualification",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_MODIFICATION_QRS",
                                    "value": "no"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "contractModification",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "allocateToPriorPobs",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANSWERS"
                },
                "moduleId": "step2IdentifyThePerformanceObligations",
                "nested": [
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "DISTINCT_PROMISES_CHECK_QSS",
                                    "value": "1,2"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "identifyDistinctPromises",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "contingencyOrGuarantee",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "PROMISE_QIS",
                                            "value": [
                                                "300 Tickets"
                                            ]
                                        },
                                        {
                                            "key": "COST_QIN",
                                            "value": [
                                                50000
                                            ]
                                        },
                                        {
                                            "key": "DELIVERY_QDS",
                                            "value": [
                                                "30 days before the applicable Event."
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "immaterialPromiseBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "POB_NAME_QIS",
                                            "value": [
                                                "License",
                                                "Advertising Services"
                                            ]
                                        },
                                        {
                                            "key": "DISTINCT_PROMISE_COUNT_QIN",
                                            "value": [
                                                5,
                                                50000
                                            ]
                                        },
                                        {
                                            "key": "GENERAL_TYPE_QSS",
                                            "value": [
                                                "License",
                                                "None of the above"
                                            ]
                                        },
                                        {
                                            "key": "THIRD_PARTY_CHECK_QRS",
                                            "value": [
                                                "no",
                                                "no"
                                            ]
                                        },
                                        {
                                            "key": "SPECIAL_TYPE_QRS",
                                            "value": [
                                                "Regular",
                                                "Regular"
                                            ]
                                        },
                                        {
                                            "key": "DELIVERY_QDS",
                                            "value": [
                                                "Upon Effective Date.",
                                                "Upon performance."
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "seriesBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "promiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "yes"
                                    },
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": "input"
                                    }
                                ],
                                "type": "ANSWERS"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "yes"
                                    },
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": "input"
                                    }
                                ],
                                "type": "ANSWERS"
                            }
                        ],
                        "moduleId": "satisfactionOfPerformanceObligations",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "ANSWERS"
                },
                "moduleId": "step3DetermineTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "ANSWERS"
                        },
                        "moduleId": "considerationRecievable",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "CONSIDERATION_NAME_QIS",
                                            "value": [
                                                "Licensee Fee",
                                                "Advertising Fee",
                                                "Royalty Goal One",
                                                "Royalty Goal Two",
                                                "Royalty Goal Three",
                                                "Royalty Goal Four"
                                            ]
                                        },
                                        {
                                            "key": "COUNTER_PARTY_QAS",
                                            "value": [
                                                "Customer",
                                                "Customer",
                                                "Customer",
                                                "Customer",
                                                "Customer",
                                                "Customer"
                                            ]
                                        },
                                        {
                                            "key": "PAID_ON_QAS",
                                            "value": [
                                                "Upon Invoice.",
                                                "Upon Invoice.",
                                                "Upon respective goal met.",
                                                "Upon respective goal met.",
                                                "Upon respective goal met.",
                                                "Upon respective goal met."
                                            ]
                                        },
                                        {
                                            "key": "VARIABLE_QRS",
                                            "value": [
                                                "no",
                                                "no",
                                                "no",
                                                "no",
                                                "no",
                                                "no"
                                            ]
                                        },
                                        {
                                            "key": "SIGNIFICANT_FINANCING_QRS",
                                            "value": [
                                                "no",
                                                "no",
                                                "no",
                                                "no",
                                                "no",
                                                "no"
                                            ]
                                        },
                                        {
                                            "key": "FORM_QRS",
                                            "value": [
                                                "Cash",
                                                "Cash",
                                                "Cash",
                                                "Cash",
                                                "Cash",
                                                "Cash"
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "considerationTypeCount",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONSIDERATION_PAYABLE_CHECK_QRS",
                                    "value": "no"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "considerationPayableToTheCustomer",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "considerationPayableBreakout",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "considerationPayableForDistinctGoodOrServiceStart",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "Okay"
                                    },
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "10000000"
                                    }
                                ],
                                "type": "ANSWERS"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "Okay"
                                    },
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "5000000"
                                    }
                                ],
                                "type": "ANSWERS"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "Okay"
                                    },
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "5000000"
                                    }
                                ],
                                "type": "ANSWERS"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "Okay"
                                    },
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "10000000"
                                    }
                                ],
                                "type": "ANSWERS"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "Okay"
                                    },
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "15000000"
                                    }
                                ],
                                "type": "ANSWERS"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "ACK",
                                        "value": "Okay"
                                    },
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": "20000000"
                                    }
                                ],
                                "type": "ANSWERS"
                            }
                        ],
                        "moduleId": "considerationToValueLoop",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [
                        {
                            "key": "ACK",
                            "value": "yes"
                        }
                    ],
                    "type": "ANSWERS"
                },
                "moduleId": "step4AllocateTheTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "BREAKOUT_SERIES_QRS",
                                    "value": "no"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "transactionPriceAllocationForSeriesDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "seriesPromiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANSWERS"
                        },
                        "moduleId": "noConsiderationToAllocate",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANSWERS"
                        },
                        "moduleId": "considerationAllocation",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "ALLOCATED_VARIABLE_CONSIDERATION_QRS",
                                            "value": [
                                                "no",
                                                "no"
                                            ]
                                        },
                                        {
                                            "key": "ALLOCATED_DISCOUNT_QRS",
                                            "value": [
                                                "no",
                                                "no"
                                            ]
                                        },
                                        {
                                            "key": "ALLOCATED_PERCENT_QIN",
                                            "value": [
                                                92,
                                                8
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "allocateByPercentOfConsideration",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [
                        {
                            "key": "ACK",
                            "value": "yes"
                        }
                    ],
                    "type": "ANSWERS"
                },
                "moduleId": "step5RecoginzeRevenue",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "ANSWERS"
                        },
                        "moduleId": "immaterialPromisesAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "POB_QRS",
                                            "value": [
                                                "Advertising Services"
                                            ]
                                        },
                                        {
                                            "key": "REV_REC_BEFORE_IMMATERIAL_COST_QRS",
                                            "value": [
                                                "no"
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "immaterialPromiseBreakout2",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "SHIPPING_CHECK_AFTER_CONTROL_CHECK_QRS",
                                    "value": "no"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "shippingAndHandlingAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "accrualOfShipping",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "JE_DRAFT_TYPE_QRS",
                                    "value": "Manual"
                                }
                            ],
                            "type": "ANSWERS"
                        },
                        "moduleId": "journalEntries",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "JOURNAL_DATE_QIS",
                                            "value": [
                                                "2024-11-30T08:00:00.000Z",
                                                "2024-11-30T08:00:00.000Z",
                                                "2024-09-30T07:00:00.000Z",
                                                "2024-09-30T07:00:00.000Z",
                                                "2024-12-31T08:00:00.000Z",
                                                "2024-12-31T08:00:00.000Z",
                                                "2025-03-31T07:00:00.000Z",
                                                "2025-03-31T07:00:00.000Z",
                                                "2025-06-30T07:00:00.000Z",
                                                "2025-06-30T07:00:00.000Z",
                                                "2025-09-30T07:00:00.000Z",
                                                "2025-09-30T07:00:00.000Z",
                                                "2025-12-31T08:00:00.000Z",
                                                "2025-12-31T08:00:00.000Z",
                                                "2026-03-31T07:00:00.000Z",
                                                "2026-03-31T07:00:00.000Z",
                                                "2026-06-30T07:00:00.000Z",
                                                "2026-06-30T07:00:00.000Z",
                                                "2026-09-30T07:00:00.000Z",
                                                "2026-09-30T07:00:00.000Z",
                                                "2026-12-31T08:00:00.000Z",
                                                "2026-12-31T08:00:00.000Z",
                                                "2027-03-31T07:00:00.000Z",
                                                "2027-03-31T07:00:00.000Z",
                                                "2027-06-30T07:00:00.000Z",
                                                "2027-06-30T07:00:00.000Z",
                                                "2027-09-30T07:00:00.000Z",
                                                "2027-09-30T07:00:00.000Z",
                                                "2027-12-31T08:00:00.000Z",
                                                "2027-12-31T08:00:00.000Z",
                                                "2028-03-31T07:00:00.000Z",
                                                "2028-03-31T07:00:00.000Z",
                                                "2028-06-30T07:00:00.000Z",
                                                "2028-06-30T07:00:00.000Z",
                                                "2028-09-30T07:00:00.000Z",
                                                "2028-09-30T07:00:00.000Z",
                                                "2028-12-31T08:00:00.000Z",
                                                "2028-12-31T08:00:00.000Z",
                                                "2029-03-31T07:00:00.000Z",
                                                "2029-03-31T07:00:00.000Z",
                                                "2029-06-30T07:00:00.000Z",
                                                "2029-06-30T07:00:00.000Z"
                                            ]
                                        },
                                        {
                                            "key": "DESCR_QIS",
                                            "value": [
                                                "Event Tickets",
                                                "Event Tickets",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License",
                                                "License"
                                            ]
                                        },
                                        {
                                            "key": "ACCOUNT_QIS",
                                            "value": [
                                                "Expense",
                                                "Cash / AR",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue",
                                                "Cash / AR",
                                                "Revenue"
                                            ]
                                        },
                                        {
                                            "key": "DEBIT_QIN",
                                            "value": [
                                                50000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0
                                            ]
                                        },
                                        {
                                            "key": "CREDIT_QIN",
                                            "value": [
                                                0,
                                                50000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000,
                                                0,
                                                500000
                                            ]
                                        }
                                    ],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "manualJeTool",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "ANSWERS"
                        },
                        "moduleId": "outOfScopeAccounting",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "ANSWERS"
                                },
                                "moduleId": "outOfScopePobsAfterAllocationCheck",
                                "nested": []
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "questionReferences": {
        "data": {
            "fields": [],
            "type": "REFERENCES"
        },
        "moduleId": "top",
        "nested": [
            {
                "data": {
                    "fields": [],
                    "type": "REFERENCES"
                },
                "moduleId": "background",
                "nested": []
            },
            {
                "data": {
                    "fields": [],
                    "type": "REFERENCES"
                },
                "moduleId": "asc606Scope",
                "nested": [
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "EFFECTIVE_DATE_QID",
                                    "value": []
                                },
                                {
                                    "key": "TERM_QIS",
                                    "value": []
                                }
                            ],
                            "type": "REFERENCES"
                        },
                        "moduleId": "introDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "contractQtc",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "PARTIES_QTC",
                                            "value": []
                                        }
                                    ],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "partiesQtc",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "partialScope",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "contractPartsQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "partialScopeLoop",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "REFERENCES"
                },
                "moduleId": "step1IdentifyTheContracts",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "setupCosts",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "setupActivitiesQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "setupActivitiesCapitalizedLoop",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_QUALIFICATION_QRS",
                                    "value": [
                                        {
                                            "data": {
                                                "alias": "606-10-25-1",
                                                "label": "Revenue",
                                                "packageTag": "latest",
                                                "parentId": "gaap-revenue"
                                            },
                                            "id": "c8379c2f-86de-4e99-a872-fe47d868dc8b",
                                            "type": "CUSTOM_GUIDANCE"
                                        }
                                    ]
                                }
                            ],
                            "type": "REFERENCES"
                        },
                        "moduleId": "contractQualification",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_MODIFICATION_QRS",
                                    "value": [
                                        {
                                            "data": {
                                                "alias": "606-10-25-10",
                                                "label": "Revenue",
                                                "packageTag": "latest",
                                                "parentId": "gaap-revenue"
                                            },
                                            "id": "0f03091f-d5d4-4f1d-b62e-168216a856ba",
                                            "type": "CUSTOM_GUIDANCE"
                                        },
                                        {
                                            "data": {
                                                "alias": "606-10-25-11",
                                                "label": "Revenue",
                                                "packageTag": "latest",
                                                "parentId": "gaap-revenue"
                                            },
                                            "id": "d6693755-97b6-4a1a-bfd7-e97e4d74a76d",
                                            "type": "CUSTOM_GUIDANCE"
                                        }
                                    ]
                                }
                            ],
                            "type": "REFERENCES"
                        },
                        "moduleId": "contractModification",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "allocateToPriorPobs",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "REFERENCES"
                },
                "moduleId": "step2IdentifyThePerformanceObligations",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "identifyDistinctPromises",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "contingencyOrGuarantee",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "IMMATERIAL_PROMISE_QTM",
                                            "value": [
                                                {
                                                    "data": {
                                                        "alias": "606-10-25-16A",
                                                        "label": "Revenue",
                                                        "packageTag": "latest",
                                                        "parentId": "gaap-revenue"
                                                    },
                                                    "id": "7fdcb28a-7e2d-4689-9998-251f51b29cc7",
                                                    "type": "CUSTOM_GUIDANCE"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "immaterialPromiseBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "SERIES_QTS",
                                            "value": [
                                                {
                                                    "data": {
                                                        "alias": "606-10-25-21",
                                                        "label": "Revenue",
                                                        "packageTag": "latest",
                                                        "parentId": "gaap-revenue"
                                                    },
                                                    "id": "7b0e5152-f354-4c81-abe6-0b6d586eef82",
                                                    "type": "CUSTOM_GUIDANCE"
                                                },
                                                {
                                                    "data": {
                                                        "alias": "3.3.2 (Section 1)",
                                                        "label": "PwC: Revenue from contracts with customers (Nov 2023)",
                                                        "packageTag": "latest",
                                                        "parentId": "pwc-revenue-from-contracts"
                                                    },
                                                    "id": "b1c27023-19a0-49f6-9094-d6fc2b8627b3",
                                                    "type": "CUSTOM_GUIDANCE"
                                                },
                                                {
                                                    "data": {
                                                        "alias": "Example RR 9-5",
                                                        "label": "PwC: Revenue from contracts with customers (Nov 2023)",
                                                        "packageTag": "latest",
                                                        "parentId": "pwc-revenue-from-contracts"
                                                    },
                                                    "id": "de586faf-40ae-4f5a-a736-57fe3e4440a7",
                                                    "type": "CUSTOM_GUIDANCE"
                                                },
                                                {
                                                    "data": {
                                                        "alias": "9.4 Accounting for a license bundled with other goods or services",
                                                        "label": "PwC: Revenue from contracts with customers (Nov 2023)",
                                                        "packageTag": "latest",
                                                        "parentId": "pwc-revenue-from-contracts"
                                                    },
                                                    "id": "367af747-312e-4716-ae74-3c772d4e2f30",
                                                    "type": "CUSTOM_GUIDANCE"
                                                },
                                                {
                                                    "data": {
                                                        "alias": "9.5 (Section 1)",
                                                        "label": "PwC: Revenue from contracts with customers (Nov 2023)",
                                                        "packageTag": "latest",
                                                        "parentId": "pwc-revenue-from-contracts"
                                                    },
                                                    "id": "e161477d-6fae-4187-9a14-7cc6c79cca09",
                                                    "type": "CUSTOM_GUIDANCE"
                                                },
                                                {
                                                    "data": {
                                                        "alias": "Example RR 3-2",
                                                        "label": "PwC: Revenue from contracts with customers (Nov 2023)",
                                                        "packageTag": "latest",
                                                        "parentId": "pwc-revenue-from-contracts"
                                                    },
                                                    "id": "f225aac6-f32a-4102-aebe-4fdf13d3dfc5",
                                                    "type": "CUSTOM_GUIDANCE"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "seriesBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "promiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": [
                                            {
                                                "data": {
                                                    "alias": "606-10-25-31",
                                                    "label": "Revenue",
                                                    "packageTag": "latest",
                                                    "parentId": "gaap-revenue"
                                                },
                                                "id": "75106080-2a75-436a-9811-ed5b2fcf13d7",
                                                "type": "CUSTOM_GUIDANCE"
                                            },
                                            {
                                                "data": {
                                                    "alias": "606-10-55-20",
                                                    "label": "Revenue",
                                                    "packageTag": "latest",
                                                    "parentId": "gaap-revenue"
                                                },
                                                "id": "2c8f8814-79ea-48a0-9774-9b5c75e9531e",
                                                "type": "CUSTOM_GUIDANCE"
                                            }
                                        ]
                                    }
                                ],
                                "type": "REFERENCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": []
                                    }
                                ],
                                "type": "REFERENCES"
                            }
                        ],
                        "moduleId": "satisfactionOfPerformanceObligations",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "REFERENCES"
                },
                "moduleId": "step3DetermineTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "considerationRecievable",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "CONSDIERATION_RECIEVABLE_BREAKOUT_QTS",
                                            "value": [
                                                {
                                                    "data": {
                                                        "alias": "Example RR 9-11",
                                                        "label": "PwC: Revenue from contracts with customers (Nov 2023)",
                                                        "packageTag": "latest",
                                                        "parentId": "pwc-revenue-from-contracts"
                                                    },
                                                    "id": "c3cf4327-720b-4a9c-a96f-9fa38aa4fedc",
                                                    "type": "CUSTOM_GUIDANCE"
                                                },
                                                {
                                                    "data": {
                                                        "alias": "9.8 (Section 1)",
                                                        "label": "PwC: Revenue from contracts with customers (Nov 2023)",
                                                        "packageTag": "latest",
                                                        "parentId": "pwc-revenue-from-contracts"
                                                    },
                                                    "id": "c2c99cb9-3741-44d0-9f89-ada67f130cce",
                                                    "type": "CUSTOM_GUIDANCE"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "considerationTypeCount",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "considerationPayableToTheCustomer",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "considerationPayableBreakout",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "considerationPayableForDistinctGoodOrServiceStart",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": []
                                    }
                                ],
                                "type": "REFERENCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": []
                                    }
                                ],
                                "type": "REFERENCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": []
                                    }
                                ],
                                "type": "REFERENCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": []
                                    }
                                ],
                                "type": "REFERENCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": []
                                    }
                                ],
                                "type": "REFERENCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": []
                                    }
                                ],
                                "type": "REFERENCES"
                            }
                        ],
                        "moduleId": "considerationToValueLoop",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "REFERENCES"
                },
                "moduleId": "step4AllocateTheTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "transactionPriceAllocationForSeriesDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "seriesPromiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "noConsiderationToAllocate",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "considerationAllocation",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "ALLOCATE_BY_PERCENT_OF_TRANSACTION_PRICE_QTM",
                                            "value": []
                                        }
                                    ],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "allocateByPercentOfConsideration",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "REFERENCES"
                },
                "moduleId": "step5RecoginzeRevenue",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "immaterialPromisesAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "IMMATERIAL_PROMISE_COST_BREAKOUT_QTS",
                                            "value": []
                                        }
                                    ],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "immaterialPromiseBreakout2",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "shippingAndHandlingAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "accrualOfShipping",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "journalEntries",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "MANUAL_ENTRIES_END_QTM",
                                            "value": []
                                        }
                                    ],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "manualJeTool",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "REFERENCES"
                        },
                        "moduleId": "outOfScopeAccounting",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "REFERENCES"
                                },
                                "moduleId": "outOfScopePobsAfterAllocationCheck",
                                "nested": []
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "questionSources": {
        "data": {
            "fields": [],
            "type": "SOURCES"
        },
        "moduleId": "top",
        "nested": [
            {
                "data": {
                    "fields": [],
                    "type": "SOURCES"
                },
                "moduleId": "background",
                "nested": []
            },
            {
                "data": {
                    "fields": [],
                    "type": "SOURCES"
                },
                "moduleId": "asc606Scope",
                "nested": [
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "EFFECTIVE_DATE_QID",
                                    "value": [
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "a6e75f0a-6121-4e47-9c19-928fcb4b43fb",
                                            "keyTermName": "Effective Date",
                                            "type": "KEY_TERM"
                                        }
                                    ]
                                },
                                {
                                    "key": "TERM_QIS",
                                    "value": [
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "31fbc1c5-6e4f-4e23-9fad-15168d5671b0",
                                            "keyTermName": "Agreement",
                                            "type": "KEY_TERM"
                                        },
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "05415501-dbaf-41c3-8a73-6e6243fb5de9",
                                            "keyTermName": "Termination for Cause",
                                            "type": "KEY_TERM"
                                        },
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "583353e8-14a8-4003-a8a4-6b2ee5bfaae3",
                                            "keyTermName": "Termination for Convenience",
                                            "type": "KEY_TERM"
                                        },
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "526f8da0-e181-4e70-81f1-6c6932abfcd8",
                                            "keyTermName": "Termination Due to Issuing Bank",
                                            "type": "KEY_TERM"
                                        },
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "b9548d14-d47a-4f56-944f-b657648a4aa9",
                                            "keyTermName": "Term",
                                            "type": "KEY_TERM"
                                        }
                                    ]
                                }
                            ],
                            "type": "SOURCES"
                        },
                        "moduleId": "introDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "contractQtc",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "PARTIES_QTC",
                                            "value": [
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "b8f216d5-dc3c-4383-80d4-74bc4a33a188",
                                                    "keyTermName": "Parties",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "713bf90e-8566-468a-888e-1642a3af5b06",
                                                    "keyTermName": "Company",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "0e5123c9-6d67-45ce-8b1e-d2923e3db169",
                                                    "keyTermName": "Customer",
                                                    "type": "KEY_TERM"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "SOURCES"
                                },
                                "moduleId": "partiesQtc",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "partialScope",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "contractPartsQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "partialScopeLoop",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "SOURCES"
                },
                "moduleId": "step1IdentifyTheContracts",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "setupCosts",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "setupActivitiesQtc",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "setupActivitiesCapitalizedLoop",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_QUALIFICATION_QRS",
                                    "value": [
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "31fbc1c5-6e4f-4e23-9fad-15168d5671b0",
                                            "keyTermName": "Agreement",
                                            "type": "KEY_TERM"
                                        },
                                        {
                                            "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                            "keyTermGroupName": "MSLA",
                                            "keyTermIdentifier": "713bf90e-8566-468a-888e-1642a3af5b06",
                                            "keyTermName": "Company",
                                            "type": "KEY_TERM"
                                        }
                                    ]
                                }
                            ],
                            "type": "SOURCES"
                        },
                        "moduleId": "contractQualification",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [
                                {
                                    "key": "CONTRACT_MODIFICATION_QRS",
                                    "value": []
                                }
                            ],
                            "type": "SOURCES"
                        },
                        "moduleId": "contractModification",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "allocateToPriorPobs",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "SOURCES"
                },
                "moduleId": "step2IdentifyThePerformanceObligations",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "identifyDistinctPromises",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "contingencyOrGuarantee",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "IMMATERIAL_PROMISE_QTM",
                                            "value": [
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "a30aff8e-058c-4f9a-b77a-e7baeb327e15",
                                                    "keyTermName": "Tickets",
                                                    "type": "KEY_TERM"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "SOURCES"
                                },
                                "moduleId": "immaterialPromiseBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "SERIES_QTS",
                                            "value": [
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "31fbc1c5-6e4f-4e23-9fad-15168d5671b0",
                                                    "keyTermName": "Agreement",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "f3ee76ae-a061-4904-95f4-018079877c76",
                                                    "keyTermName": "Schedule E",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                    "keyTermName": "Schedule D",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "d4b354f0-8dd6-49f5-a655-2e97ea15ca08",
                                                    "keyTermName": "License",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "ddc36cba-f5cb-45a5-ad94-f45214e24f06",
                                                    "keyTermName": "Advertising Services",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "2ee7579a-5634-4c6f-a8e6-e480b841ed0c",
                                                    "keyTermName": "Schedule F",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "e4e7b849-189c-4870-8a76-2d797426f1b3",
                                                    "keyTermName": "Schedule G",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "4eaef7ef-e5ef-427c-98be-5361b2b82e77",
                                                    "keyTermName": "Company Characters",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "e53cae5a-ce52-44ab-8387-a790cc19460a",
                                                    "keyTermName": "Customer Products",
                                                    "type": "KEY_TERM"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "SOURCES"
                                },
                                "moduleId": "seriesBreakout",
                                "nested": []
                            },
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "promiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "b9548d14-d47a-4f56-944f-b657648a4aa9",
                                                "keyTermName": "Term",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                "keyTermName": "Schedule D",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "OVERTIME_ACCOUNTING_QRS",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "f3ee76ae-a061-4904-95f4-018079877c76",
                                                "keyTermName": "Schedule E",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "ddc36cba-f5cb-45a5-ad94-f45214e24f06",
                                                "keyTermName": "Advertising Services",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "b999df4e-4b23-4af2-bbee-240155f828df",
                                                "keyTermName": "Advertising Services Fee",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            }
                        ],
                        "moduleId": "satisfactionOfPerformanceObligations",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "SOURCES"
                },
                "moduleId": "step3DetermineTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "considerationRecievable",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "CONSDIERATION_RECIEVABLE_BREAKOUT_QTS",
                                            "value": [
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "859ed97f-d72c-4847-8694-e9fb060cceb2",
                                                    "keyTermName": "IP License Fee",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "b999df4e-4b23-4af2-bbee-240155f828df",
                                                    "keyTermName": "Advertising Services Fee",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                    "keyTermName": "Schedule D",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "f3ee76ae-a061-4904-95f4-018079877c76",
                                                    "keyTermName": "Schedule E",
                                                    "type": "KEY_TERM"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "SOURCES"
                                },
                                "moduleId": "considerationTypeCount",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "considerationPayableToTheCustomer",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "considerationPayableBreakout",
                                "nested": []
                            },
                            {
                                "data": [],
                                "moduleId": "considerationPayableForDistinctGoodOrServiceStart",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": [
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                "keyTermName": "Schedule D",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "859ed97f-d72c-4847-8694-e9fb060cceb2",
                                                "keyTermName": "IP License Fee",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "f3ee76ae-a061-4904-95f4-018079877c76",
                                                "keyTermName": "Schedule E",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "b999df4e-4b23-4af2-bbee-240155f828df",
                                                "keyTermName": "Advertising Services Fee",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                "keyTermName": "Schedule D",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "859ed97f-d72c-4847-8694-e9fb060cceb2",
                                                "keyTermName": "IP License Fee",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                "keyTermName": "Schedule D",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "859ed97f-d72c-4847-8694-e9fb060cceb2",
                                                "keyTermName": "IP License Fee",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                "keyTermName": "Schedule D",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "859ed97f-d72c-4847-8694-e9fb060cceb2",
                                                "keyTermName": "IP License Fee",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            },
                            {
                                "fields": [
                                    {
                                        "key": "TRSNACTION_PRICE_QIN",
                                        "value": [
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "859ed97f-d72c-4847-8694-e9fb060cceb2",
                                                "keyTermName": "IP License Fee",
                                                "type": "KEY_TERM"
                                            },
                                            {
                                                "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                "keyTermGroupName": "MSLA",
                                                "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                "keyTermName": "Schedule D",
                                                "type": "KEY_TERM"
                                            }
                                        ]
                                    }
                                ],
                                "type": "SOURCES"
                            }
                        ],
                        "moduleId": "considerationToValueLoop",
                        "nested": []
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "SOURCES"
                },
                "moduleId": "step4AllocateTheTransactionPrice",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "transactionPriceAllocationForSeriesDetails",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "seriesPromiseBreakout",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "noConsiderationToAllocate",
                        "nested": []
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "considerationAllocation",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "ALLOCATE_BY_PERCENT_OF_TRANSACTION_PRICE_QTM",
                                            "value": [
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "d4d60482-3697-4c8e-afed-e6f8d64651ec",
                                                    "keyTermName": "Schedule D",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "f3ee76ae-a061-4904-95f4-018079877c76",
                                                    "keyTermName": "Schedule E",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "b999df4e-4b23-4af2-bbee-240155f828df",
                                                    "keyTermName": "Advertising Services Fee",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "ddc36cba-f5cb-45a5-ad94-f45214e24f06",
                                                    "keyTermName": "Advertising Services",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "d4b354f0-8dd6-49f5-a655-2e97ea15ca08",
                                                    "keyTermName": "License",
                                                    "type": "KEY_TERM"
                                                },
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "859ed97f-d72c-4847-8694-e9fb060cceb2",
                                                    "keyTermName": "IP License Fee",
                                                    "type": "KEY_TERM"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "SOURCES"
                                },
                                "moduleId": "allocateByPercentOfConsideration",
                                "nested": []
                            }
                        ]
                    }
                ]
            },
            {
                "data": {
                    "fields": [],
                    "type": "SOURCES"
                },
                "moduleId": "step5RecoginzeRevenue",
                "nested": [
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "immaterialPromisesAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "IMMATERIAL_PROMISE_COST_BREAKOUT_QTS",
                                            "value": [
                                                {
                                                    "keyTermGroupIdentifier": "cd120ff9-0276-9b24-1b26-bbbd95f0d43e",
                                                    "keyTermGroupName": "MSLA",
                                                    "keyTermIdentifier": "a30aff8e-058c-4f9a-b77a-e7baeb327e15",
                                                    "keyTermName": "Tickets",
                                                    "type": "KEY_TERM"
                                                }
                                            ]
                                        }
                                    ],
                                    "type": "SOURCES"
                                },
                                "moduleId": "immaterialPromiseBreakout2",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "shippingAndHandlingAccrual",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "accrualOfShipping",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "journalEntries",
                        "nested": [
                            {
                                "data": {
                                    "fields": [
                                        {
                                            "key": "MANUAL_ENTRIES_END_QTM",
                                            "value": []
                                        }
                                    ],
                                    "type": "SOURCES"
                                },
                                "moduleId": "manualJeTool",
                                "nested": []
                            }
                        ]
                    },
                    {
                        "data": {
                            "fields": [],
                            "type": "SOURCES"
                        },
                        "moduleId": "outOfScopeAccounting",
                        "nested": [
                            {
                                "data": {
                                    "fields": [],
                                    "type": "SOURCES"
                                },
                                "moduleId": "outOfScopePobsAfterAllocationCheck",
                                "nested": []
                            }
                        ]
                    }
                ]
            }
        ]
    }
};