import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import DefaultLoadingScreen from "../../components/loading/defaultLoadingScreen";

import "./SignIn.css";

export default function SignIn() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  /*
   * Adding refresh logic to the SignIn page for Auth0 since Auth0 uses a provider 
   */
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate("/auth0Login");
    } else if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  return (
    <DefaultLoadingScreen />
  );
}
