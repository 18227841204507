import { fileUploadAction } from "../actions/fileUploadActions";
import {
  FileUploadsState,
  FileUploadActionType
} from "../models/fileUploadTypes";
import {
  PaginatedResult,
  PaginatedState
} from "../models/commonTypes";
import {
  FileUpload,
  FileUploadItem
} from "../models/dataModelTypes";

const initialPaginatedState: PaginatedState = {
  data: [],
  filter: null,
  totalPages: 0
};

const initialState: FileUploadsState = {
  fileUploads: Object.assign({}, initialPaginatedState),
  isLoading: false,
  isUpdating: false,
  error: null
};

const fileUploadReducer = (state = initialState, action: FileUploadActionType) => {
  switch (action.type) {
    case fileUploadAction.FETCH_FILE_UPLOADS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case fileUploadAction.FETCH_FILE_UPLOADS_SUCCESS:
      let { paginatedResult, filter } = action.payload;
      return {
        ...state,
        fileUploads: {
          data: [...state.fileUploads.data, paginatedResult],
          filter: filter,
          totalPages: paginatedResult.totalPages
        } as PaginatedState,
        isLoading: false,
        error: null
      };
    case fileUploadAction.FETCH_FILE_UPLOADS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case fileUploadAction.ADD_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isAdding: true,
        error: null
      };
    case fileUploadAction.ADD_FILE_UPLOAD_SUCCESS:
      let fileUploadCopy: PaginatedState = Object.assign({}, state.fileUploads);
      if (fileUploadCopy.data.length > 0) {
        const d = fileUploadCopy.data[0];
        d.models.unshift(action.payload);
      } else {
        fileUploadCopy.data.push({
          models: [action.payload],
          pageNumber: 0,
          pageLimit: 10,
          totalPages: 1
        } as PaginatedResult)
      }
      return {
        ...state,
        fileUploads: fileUploadCopy,
        isAdding: false,
        error: null
      };
    case fileUploadAction.ADD_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload
      };
    case fileUploadAction.UPDATE_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null
      };
    case fileUploadAction.UPDATE_FILE_UPLOAD_SUCCESS:
      let copyForFileUploads = Object.assign({}, state.fileUploads) as PaginatedState;
      for (const fileUploadsPage of copyForFileUploads.data) {
        for (let i in fileUploadsPage.models) {

          if (action.payload.id === fileUploadsPage.models[i].id) {
            fileUploadsPage.models[i] = action.payload;
          }
        }
      }
      return {
        ...state,
        fileUploads: copyForFileUploads,
        isUpdating: false,
        error: null
      };
    case fileUploadAction.UPDATE_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };

    case fileUploadAction.ADD_FILE_UPLOAD_ITEM_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null
      };
    case fileUploadAction.ADD_FILE_UPLOAD_ITEM_SUCCESS:
      let copyForFileUploadItem = Object.assign({}, state.fileUploads) as PaginatedState;
      for (const fileUploadsPage of copyForFileUploadItem.data) {
        for (const fileUpload of (fileUploadsPage.models as FileUpload[])) {
          if (action.payload.fileUploadId === fileUpload.id) {
            fileUpload.fileUploadItems.push(action.payload);
          }
        }
      }
      return {
        ...state,
        fileUploads: copyForFileUploadItem,
        isUpdating: false,
        error: null
      };
    case fileUploadAction.ADD_FILE_UPLOAD_ITEM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
    case fileUploadAction.ADD_FILE_UPLOAD_ITEM_CONTENT_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: null
      };
    case fileUploadAction.ADD_FILE_UPLOAD_ITEM_CONTENT_SUCCESS:
      let copyForFileUploadItemContent = Object.assign({}, state.fileUploads) as PaginatedState;
      for (const fileUploadsPage of copyForFileUploadItemContent.data) {
        for (const fileUpload of fileUploadsPage.models as FileUpload[]) {
          if (action.payload.fileUploadId === fileUpload.id) {
            const fileUploadItems = fileUpload.fileUploadItems;
            for (let j in fileUpload.fileUploadItems) {
              if (action.payload.id === fileUploadItems[j].id) {
                fileUploadItems[j] = action.payload;
              }
            }
          }
        }
      }
      return {
        ...state,
        fileUploads: copyForFileUploadItemContent,
        isUpdating: false,
        error: null
      };
    case fileUploadAction.ADD_FILE_UPLOAD_ITEM_CONTENT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
      case fileUploadAction.ARCHIVE_FILE_UPLOAD_ITEM_REQUEST:
        return {
          ...state,
          isUpdating: true,
          error: null
        };
      case fileUploadAction.ARCHIVE_FILE_UPLOAD_ITEM_SUCCESS:
        let copyForArchiveFileUploadItemContent = Object.assign({}, state.fileUploads) as PaginatedState;
        for (const fileUploadsPage of copyForArchiveFileUploadItemContent.data) {
          for (const fileUpload of fileUploadsPage.models as FileUpload[]) {
            if (action.payload.fileUploadId === fileUpload.id) {
              const fileUploadItems = fileUpload.fileUploadItems;
              for (let j in fileUpload.fileUploadItems) {
                if (action.payload.id === fileUploadItems[j].id) {
                  fileUploadItems[j] = action.payload;
                }
              }
            }
          }
        }
        return {
          ...state,
          fileUploads: copyForArchiveFileUploadItemContent,
          isUpdating: false,
          error: null
        };
    case fileUploadAction.ARCHIVE_FILE_UPLOAD_ITEM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default fileUploadReducer;
