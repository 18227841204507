import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export interface ConfirmationDialogRawProps {
    title: string;
    confirmationText: string;
    open: boolean;
    onClose: (confirmed: boolean) => void;
    confirmBtnText: string;
    cancelBtnText: string;
}

function ConfirmationDialogRaw({
    title,
    confirmationText,
    open,
    onClose,
    confirmBtnText,
    cancelBtnText
}: ConfirmationDialogRawProps) {
    const [disableUserInput, setDisableUserInput] = useState(!open);
    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{confirmationText}</DialogContent>
            <DialogActions>
                <Button 
                    autoFocus 
                    onClick={() => {
                        setDisableUserInput(false);
                        onClose(false);
                    }}
                    disabled={disableUserInput}
                >
                    {cancelBtnText}
                </Button>
                <Button 
                    onClick={() => {
                        setDisableUserInput(false);
                        onClose(true);
                    }}
                    disabled={disableUserInput}
                >
                    {confirmBtnText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface ConfirmationDialogProps {
    title: string;
    confirmationText: string;
    onConfirmed: () => void;
    onCancelled?: () => void;
    confirmBtnText?: string;
    cancelBtnText?: string;
}

export default function ConfirmationDialog({
    title,
    confirmationText,
    onConfirmed,
    onCancelled,
    confirmBtnText = 'Okay',
    cancelBtnText = 'Cancel'
}: ConfirmationDialogProps) {
    const [open, setOpen] = useState(true);
    return (
        <ConfirmationDialogRaw
            title={title}
            confirmationText={confirmationText}
            open={open}
            onClose={(confirmed) => {
                setOpen(false);
                if (confirmed) {
                    onConfirmed();
                } else if (onCancelled) {
                    onCancelled();
                }
            }}
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
        />
    );
};