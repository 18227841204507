import { useState, forwardRef, useCallback } from "react";
import clsx from "clsx";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { 
    Delete, 
    ExpandMore, 
    Close 
} from "@mui/icons-material";
import axios from "axios";
import { CircularProgress } from "@mui/material";

interface PendingJobProps extends CustomContentProps {
    takerDocumentUploadAnalysisId?: string;
    takerDocumentAnalysisId?: string;
}

const useStyles: any = makeStyles(() => ({
    root: {
        "@media (min-width:600px)": {
            minWidth: "344px !important"
        }
    },
    card: {
        width: "100%"
    },
    typography: {
        paddingLeft: "10px",
        color: "#000"
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        justifyContent: "space-between"
    },
    icons: {
        marginLeft: "auto"
    },
    expand: {
        padding: "8px 8px",
        transform: "rotate(0deg)",
        color: "#000",
        transition: "all .2s"
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    paper: {
        backgroundColor: "#fff",
        padding: 16
    },
    deleteIcon: {
        fontSize: 20,
        paddingRight: 4
    },
    button: {
        padding: 0,
        textTransform: "none"
    }
  }));

export const PendingJob = forwardRef<HTMLDivElement, PendingJobProps>(({
    id,
    message,
    takerDocumentUploadAnalysisId,
    takerDocumentAnalysisId,
    ...other
}, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <SnackbarContent 
            ref={ref} 
            {...other}
            className={classes.root}
        >
            <Card className={classes.card} style={{ backgroundColor: "#ddd" }}>
                <CardActions classes={{ root: classes.actionRoot }}>
                    <CircularProgress size={20}/>
                    <Typography 
                        variant="body2" 
                        className={classes.typography}
                    >
                        {message}
                    </Typography>
                    <div className={classes.icons}>
                        <IconButton
                            aria-label="Show more"
                            size="small"
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded
                            })}
                            onClick={handleExpandClick}
                        >
                            <ExpandMore />
                        </IconButton>
                        <IconButton
                            size="small"
                            className={classes.expand}
                            onClick={handleDismiss}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </div>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper className={classes.paper}>
                        <Button 
                            size="small" 
                            color="inherit" 
                            className={classes.button}
                            onClick={async () => {
                                if (takerDocumentAnalysisId) {
                                    let res = await axios({
                                        url: `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/v1/taker_document_analysis/${takerDocumentAnalysisId}/cancel_job`,
                                        method: "POST",
                                        withCredentials: true
                                    });
                                    //console.log(res.data);
                                } else if (takerDocumentUploadAnalysisId) {
                                    let res = await axios({
                                        url: `${window.__RUNTIME_CONFIG__.API_ENDPOINT}/v1/taker_document_upload_analysis/${takerDocumentUploadAnalysisId}/cancel_job`,
                                        method: "POST",
                                        withCredentials: true
                                    });
                                    //console.log(res.data);
                                }
                            }}
                        >
                            <Delete className={classes.deleteIcon} />
                            Cancel Job
                        </Button>
                    </Paper>
                </Collapse>
            </Card>
        </SnackbarContent>
    )
});

PendingJob.displayName = "PendingJob";

export default PendingJob;
