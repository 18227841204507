export enum Algorithm {
  Linear = 'linear',
  CatmullRom = 'catmull-rom',
  BezierCatmullRom = 'bezier-catmull-rom',
}

export const COLORS = {
  [Algorithm.Linear]: '#dddddd',
  [Algorithm.BezierCatmullRom]: '#68D391',
  [Algorithm.CatmullRom]: '#FF0072',
};
