import { 
  AccountCircle, 
  AdminPanelSettings, 
  DataObject, 
  Home, 
  Logout, 
  Psychology, 
  QuestionMark,
  DataObjectOutlined
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";

export interface PageItem {
  route: string;
  icon?: any;
  name?: string;
}

export const pageOption: Record<string, PageItem> = {
  SIGN_IN: {
    route: "/",
    icon: <Logout/>,
    name: "Sign Out"
  },
  AUTH0_ERROR: {
    route: "/authError",
  },
  HOME: {
    route: "/home",
    icon: <Home htmlColor="white"/>,
    name: "Home"
  },
  MAIN_BUILDER: {
    route: "/mainBuilder/:id",
  },
  MAIN_TAKER: {
    route: "/mainTaker/:id",
  },
  TAKER_KEY_TERMS: {
    route: "/mainTaker/:id/keyTerms",
  },
  TAKER_ANALYSIS: {
    route: "/mainTaker/:id/analysis",
  },
  TAKER_REPORT: {
    route: "/mainTaker/:id/report",
  },
  ERROR: {
    route: "/error",
    icon: <QuestionMark htmlColor="white"/>,
    name: "Error"
  },
  MANAGE_USERS: {
    route: "/manageUsers",
    icon: <AdminPanelSettings htmlColor="white"/>,
    name: "Users"
  },
  MANAGE_ORGANIZATIONS: {
    route: "/manageOrganizations",
    icon: <AdminPanelSettings htmlColor="white"/>,
    name: "Organizations"
  },
  USER_SETTINGS: {
    route: "/userSettings",
    icon: <AccountCircle htmlColor="white"/>,
    name: "Account Settings",
  },
  MANAGE_APP_CONFIGS: {
    route: "/manageAppConfigs",
    icon: <DataObject htmlColor="white"/>,
    name: "App Configs"
  },
  MANAGE_MODELS: {
    route: "/manageModels",
    icon: <Psychology htmlColor="white"/>,
    name: "Models"
  },
  LANGUAGE_MODEL: {
    route: "/languageModel/:id",
  },
  INDEXED_GUIDANCE_LIST: {
    route: "/indexedGuidanceList",
    icon: <DataObjectOutlined htmlColor="white"/>,
    name: "Guidance"
  },
  INDEXED_GUIDANCE: {
    route: "/indexedGuidance/:id"
  },
  ASSISTANT: {
    route: "/assistant/:id",
  },
  AUTH0_LOGIN: {
    route: "/auth0Login",
  }
};

