import { useEffect, useState } from "react";
import { BuilderDocument, BuilderDocumentGuidance, IndexedGuidance } from "../../redux/models/dataModelTypes";
import { useLazyGetIndexedGuidancePackageVersionsQuery, useSearchIndexedGuidanceByNameQuery } from "../../redux/services/indexedGuidance";
import { Autocomplete, Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material/";
import { 
    useAddBuilderDocumentGuidanceMutation, 
    useRemoveBuilderDocumentGuidanceMutation 
} from "../../redux/services/builder";

import { Delete } from "@mui/icons-material";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";

interface Props {
    builderDocument: BuilderDocument;
}

export const GuidanceAssignment = ({ builderDocument }: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<IndexedGuidance | null>(null);
    const [version, setVersion] = useState<string>('latest');

    const [availableVersions, setAvalailableVersions] = useState<string[]>([]);
    const { data: indexedGuidanceOptions } = useSearchIndexedGuidanceByNameQuery({
        page: 0,
        limit: 100,
        name: inputValue
    });

    const [addBuilderDocumentGuidance, addBuilderDocumentGuidanceRes] = useAddBuilderDocumentGuidanceMutation();
    const [removeBuilderDocumentGuidance, removeBuilderDocumentGuidanceRes] = useRemoveBuilderDocumentGuidanceMutation();
    const [trigger] = useLazyGetIndexedGuidancePackageVersionsQuery();

    useEffect(() => {
        if (value) {
            trigger(value.id).then((res) => {
                if (res.isSuccess) {
                    setAvalailableVersions(res.data.map(
                        s => s.substring(0, s.lastIndexOf("."))
                    ));
                }
            }).catch((error) => {
                console.error(error);
            });
            setVersion("latest");
        }
    }, [value])

    return (
        <Grid container>
            <Grid
                xs={12}
                item
                paddingTop={1}
                paddingBottom={2}
            >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                variant="head"
                                sx={{ fontWeight: "bolder" }}
                            >
                                Created At
                            </TableCell>
                            <TableCell />
                        </TableHead>
                        <TableBody>
                            {(builderDocument.builderDocumentGuidances.length > 0) ? builderDocument.builderDocumentGuidances.map((bdg: BuilderDocumentGuidance) => (
                                <TableRow
                                    key={bdg.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {bdg.indexedGuidance?.name}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="right">
                                        {new Date(bdg.createdAt).toLocaleString()}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="right">
                                        <CrudButtonGroup
                                            buttons={[{
                                                icon: (<Delete fontSize="small" />),
                                                handleClick: () => {
                                                    removeBuilderDocumentGuidance(bdg);
                                                }
                                            }]}
                                        />
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={3} align="left">
                                        <i>none</i>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid
                item
                xs={12}
                paddingBottom={2}
            >
                <Stack direction="row" spacing={2}>
                    <Autocomplete
                        size="small"
                        sx={{ minWidth: "250px" }}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.name
                        }
                        filterOptions={(x) => x}
                        options={indexedGuidanceOptions?.data || []}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={value}
                        noOptionsText="No Guidance"
                        onChange={(event: any, newValue: IndexedGuidance | null) => {
                            setValue(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Add guidance" fullWidth />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    <Grid container alignItems="center">
                                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                            <Box component="span">
                                                {option.name}
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                Added {new Date(option.createdAt).toLocaleString()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                            );
                        }}
                    />
                    {value && (
                        <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                size="small"
                                data-testid="guidance-version-select"
                                value={version}
                                onChange={(event: SelectChangeEvent<string>) => {
                                    setVersion(event.target.value);
                                }}
                                label=""
                            >
                                {availableVersions.map((av) => (
                                    <MenuItem value={av}>
                                        {av}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (value) {
                                addBuilderDocumentGuidance({
                                    builderDocumentId: builderDocument.id,
                                    indexedGuidanceId: value.id,
                                    indexedGuidanceVersion: version
                                });
                            }
                        }}
                    >
                        Add
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default GuidanceAssignment;
