import { Add } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { FormContextType, IconButtonProps, RJSFSchema, StrictRJSFSchema, TranslatableString } from '@rjsf/utils';

/** The `AddButton` renders a button that represent the `Add` action on a form
 */
export default function AddButton<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  uiSchema,
  registry,
  ...props
}: IconButtonProps<T, S, F>) {
  const { translateString } = registry;
  return (
    <Button
      data-testid='add-button'
      endIcon={<Add/>}
      size='small'
      title={translateString(TranslatableString.AddItemButton)} 
      {...props}
      color='primary'
      variant='outlined'
    >
      add
    </Button>
  );
}
