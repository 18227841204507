import React, { CSSProperties, useEffect, useLayoutEffect, useRef, useState, memo } from "react";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot, LexicalEditor, TextNode } from "lexical";
import { Box, Button, styled } from "@mui/material";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

interface SyncEditorPluginProps {
    value?: Object;
}

/**
 * SyncEditorPlugin is a plugin that synchronizes the editor with the value given.
 * When the value changes, it replaces the current editor state with the new value.
 * 
 * @param {{value?: Object}} props
 * @param {Object} props.value The value to synchronize the editor with.
 */
const SyncEditorPlugin = ({ 
    value,
}: SyncEditorPluginProps) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!!value) {
            const currentStringValue = JSON.stringify(editor.getEditorState().toJSON());
            const valueAsString = JSON.stringify(value);
            const incomingValueDiffers = currentStringValue !== valueAsString;
            if (valueAsString !== "" && incomingValueDiffers) {
                const editorState = editor.parseEditorState(valueAsString);
                if (!editorState.isEmpty()) {
                    editor.setEditorState(editorState);
                }
            }
        }
    }, [value, editor]);

    return null;
};

interface CommentLexicalProps {
    namespace: string,
    lexical?: Object,
    onUpdate?: (l: Object, t: string) => void;
    maxContentHight?: number;
    commentsOpen?: boolean;
}

const CustomContentEditable = styled(ContentEditable)`
    & p {
        margin-top: 0;
        margin-bottom: 0;
    }
    outline: 0;
    min-height: 100%;
`;

/**
 * A lexical editor component that takes a serialized lexical editor state and
 * renders it as a plaintext contenteditable element. The component also accepts
 * an `onUpdate` function that will be called with the updated serialized lexical
 * editor state whenever the user makes changes to the content.
 *
 * @example
 * <CommentLexical
 *     lexical={yourSerializedLexicalEditorState}
 *     onUpdate={(lexical, text) => {
 *         console.log(lexical);
 *         console.log(text);
 *     }}
 * />
 *
 * @param {String} namespace
 * The namespace to use for the editor.
 * 
 * @param {Object} lexical
 * The serialized lexical editor state to render.
 *
 * @param {Function} onUpdate
 * A function that will be called whenever the user makes changes to the
 * content. The function will receive two arguments: the updated serialized
 * lexical editor state, and the text content of the editor.
 *
 * @param {Number} maxContentHight
 * The maximum height of the contenteditable element.
 * 
 * @param {Boolean} commentsOpen
 * Whether the comments are open or not.
 *
 * @returns {ReactElement}
 * The rendered lexical editor component.
 */
const CommentLexical = ({
    namespace,
    lexical,
    onUpdate,
    maxContentHight,
    commentsOpen
}: CommentLexicalProps) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [expanded, setExpanded] = useState(false);
    const canUpdate = !!onUpdate;

    let initialConfig = {
        editorState: (editor: LexicalEditor) => {
            if (lexical) {
                const editorState = editor.parseEditorState(JSON.stringify(lexical));
                if (!editorState.isEmpty()) {
                    editor.setEditorState(editorState);
                }
            }
        },
        editable: canUpdate,
        namespace,
        onError(error: any) {
            throw error;
        },
        nodes: [TextNode]
    } as InitialConfigType;

    useLayoutEffect(() => {
        if (targetRef.current && targetRef.current.offsetWidth > 0) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, [commentsOpen]);

    // Add a max height if the editor is not expanded and can't be updated.
    const boxStyle: CSSProperties = {};
    const shouldCollapse = !canUpdate && !expanded;
    const hideOverflow = maxContentHight !== undefined && (shouldCollapse && dimensions.height > maxContentHight);
    if (hideOverflow) {
        boxStyle.overflow = "hidden";
        boxStyle.height = `${maxContentHight}px`;
    }

    // Show the hide button
    const showOverflow = !canUpdate && expanded;

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <RichTextPlugin
                contentEditable={
                    <>
                        <Box ref={targetRef} style={boxStyle}>
                            <CustomContentEditable autoFocus />
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="flex-start"
                        >
                            {hideOverflow && (
                                <Button
                                    sx={{ padding: 0 }}
                                    variant="text"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        setExpanded(true);
                                    }}
                                >
                                    Expand
                                </Button>
                            )}
                            {showOverflow && (
                                <Button
                                    sx={{ padding: 0 }}
                                    variant="text"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        setExpanded(false);
                                    }}
                                >
                                    Hide
                                </Button>
                            )}
                        </Box>
                    </>
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <OnChangePlugin
                onChange={(editorState, editor) => {
                    editorState.read(() => {
                        if (canUpdate) {
                            onUpdate(
                                JSON.parse(JSON.stringify(editorState)),
                                $getRoot().getTextContent()
                            );
                        }
                    });
                }}
            />
            <SyncEditorPlugin value={lexical} />
        </LexicalComposer>
    );
}

export default CommentLexical;
