import { useParams } from "react-router-dom";
import {
    Box,
    Button,
    Chip,
    CircularProgress, Divider, FormHelperText, Grid, IconButton, Menu, MenuItem, Select, Stack, TextField, Typography
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import {
    useGetIndexedGuidanceQuery,
    useUpdateIndexedGuidanceMutation,
    useUpsertIndexedGuidanceContentMutation,
    useLazyGetIndexedGuidanceContentQuery,
    useCreateIndexedGuidancePackageMutation
} from "../../redux/services/indexedGuidance";
import { useEffect, useMemo, useState, useRef } from "react";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Add, ChevronRight, CopyAll, ExpandMore, More, MoreHoriz, Save } from "@mui/icons-material";
import RichTextEditor from "../../components/lexical/wrappers/RichTextEditor";
import { v4 as uuidv4 } from 'uuid';
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import ExportGuidancePackageButton from "./ExportGuidancePackageButton";
import UpdateIndexedGuidanceDetails from "./UpdateIndexedGuidanceDetails";

const API_ENDPOINT = window.__RUNTIME_CONFIG__.API_ENDPOINT;

interface AutoScalingInputProps {
    name: string | undefined;
    onChange: (s: string) => void;
}

const AutoScalingInput = ({
    name,
    onChange
}: AutoScalingInputProps) => {
    const [width, setWidth] = useState<number>(0);
    const spanElem = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanElem.current) {
            setWidth(spanElem.current.offsetWidth * 1.1);
        }
    }, [name]);

    const changeHandler = (evt: any) => {
        onChange(evt.target.value);
    };

    return (
        <Box>
            <span
                ref={spanElem}
                style={{
                    position: "absolute",
                    opacity: 0,
                    zIndex: -100,
                    whiteSpace: "pre",
                    fontSize: "1.4em"
                }}>
                {name}
            </span>
            <input
                type="text"
                style={{
                    fontSize: "1.4em",
                    width: width,
                    border: "none"
                }}
                autoFocus
                onChange={changeHandler}
                value={name}
            />
        </Box>
    );
};

interface TreeItemClickPayload {
    targetEntry: any;
    targetEntryIsFirst: boolean;
    targetEntryIsLast: boolean;
    targetEntryIsTopLevel: boolean;
    targetEntryHasNoSiblings: boolean;
}

const IndexedGuidance = () => {
    const { id } = useParams<{ id: any }>();
    const {
        data: indexedGuidance,
        isFetching,
        isLoading
    } = useGetIndexedGuidanceQuery(id);
    const [updateIndexedGuidance, updateIndexedGuidanceRes] = useUpdateIndexedGuidanceMutation();
    const [upsertIndexedGuidanceContent, upsertIndexedGuidanceContentRes] = useUpsertIndexedGuidanceContentMutation();
    const [trigger] = useLazyGetIndexedGuidanceContentQuery();
    const [createIndexedGuidancePackage, createIndexedGuidancePackageRes] = useCreateIndexedGuidancePackageMutation();

    const [labeledRefs, setLabeledRefs] = useState<any[]>([]);
    const [selectedNodeId, setSelectedNodeId] = useState<string>();

    const [targetEntry, setTargetEntry] = useState<any>();
    const [targetEntryIsFirst, setTargetEntryIsFirst] = useState<undefined | boolean>();
    const [targetEntryIsLast, setTargetEntryIsLast] = useState<undefined | boolean>();
    const [targetEntryIsTopLevel, setTargetEntryIsTopLevel] = useState<undefined | boolean>();
    const [targetEntryHasNoSiblings, setTargetEntryHasNoSiblings] = useState<undefined | boolean>();

    const [targetLabel, setTargetLabel] = useState<string>();
    const [targetHtmlContent, setTargetHtmlContent] = useState<string>();
    const [targetLexicalContent, setTargetLexicalContent] = useState<string>();
    const [targetContent, setTargetContent] = useState<string>();
    const [contentInputState, setContentInputState] = useState<null | "HEADER" | "CONTENT" | "EDIT_HEADER">(null);
    const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
    const [indexedGuidanceName, setIndexedGuidanceName] = useState<string>();
    const [needsSaveName, setNeedsSaveName] = useState<boolean>(false);

    const [movingUnderSibilingHeader, setMovingUnderSibilingHeader] = useState<boolean>(false);
    const [sibilingHeaderEntryId, setSibilingHeaderEntryId] = useState<undefined | string>();

    const [anchorEl, setAnchorEl] = useState<Element>();
    const menuOpen = Boolean(anchorEl);

    const { user } = useSelector((state: RootReducerType) => state.auth);    

    const canImportExportPackage = useMemo(
        () => user && user.roles.includes("SUPERADMIN"), 
        [user]
    );

    const isUploadingPackage = useMemo(
        () => createIndexedGuidancePackageRes.isLoading, 
        [createIndexedGuidancePackageRes]
    );

    const assetUrl: string | undefined = useMemo(() => {
        if (!indexedGuidance) {
            return
        }
        let upload = indexedGuidance.fileUpload;
        if (upload) {
            return `${API_ENDPOINT}/v1/file_uploads/${upload.id}/items/${upload.fileUploadItems[0].id}/content`;
        }
    }, [isLoading]);

    const pdfIFrame = useMemo(() => {
        if (!assetUrl && isLoading) {
            return (
                <CircularProgress />
            );
        } else if (!assetUrl) {
            return (
                <span>no reference document</span>
            );
        }
        return (
            <iframe src={assetUrl} width="100%" height="100%" />
        );
    }, [assetUrl, isLoading]);

    useEffect(() => {
        if (indexedGuidance?.referenceMap['labeledRefs']) {
            setLabeledRefs(indexedGuidance?.referenceMap['labeledRefs']);
        }
    }, [indexedGuidance?.referenceMap]);

    useEffect(() => {
        if (upsertIndexedGuidanceContentRes.isSuccess) {
            let nodeId = upsertIndexedGuidanceContentRes.originalArgs?.id;
            const updateLabeledRef = (indexEntries: any[]) => {
                for (const indexEntry of indexEntries) {
                    if (indexEntry['id'] === nodeId) {
                        indexEntry['hasContent'] = true;
                    } else {
                        updateLabeledRef(indexEntry['nested']);
                    }
                }
            };

            if (indexedGuidance) {
                let newLabeledRefs = JSON.parse(JSON.stringify(labeledRefs));
                updateLabeledRef(newLabeledRefs);
                updateIndexedGuidance({
                    id: indexedGuidance.id,
                    guidanceId: indexedGuidance.guidanceId,
                    name: indexedGuidance.name,
                    description: indexedGuidance.description,
                    canonicalLabel: indexedGuidance.canonicalLabel,
                    referenceMap: {
                        'labeledRefs': newLabeledRefs
                    }
                });
                setLabeledRefs(newLabeledRefs);
            }
        }
    }, [upsertIndexedGuidanceContentRes]);

    const renderSubTrees = (depth: number, indexEntries: any[], onClickMore: (e: HTMLButtonElement, d: TreeItemClickPayload) => void) => {
        const treeItems = []
        for (let i = 0; i < indexEntries.length; i++) {
            const indexEntry = indexEntries[i];
            const isFirst = (i === 0);
            const isLast = (i === indexEntries.length - 1);
            const isTopLevel = (depth === 0);
            const hasNoSiblings = (indexEntries.length === 1);
            if (indexEntry['nested'] && indexEntry['nested'].length > 0) {
                treeItems.push(
                    <TreeItem
                        nodeId={indexEntry['id']}
                        label={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 0.5,
                                    pr: 0,
                                }}
                            >
                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                    {indexEntry['label']}
                                </Typography>
                                <IconButton
                                    onClick={(e) => {
                                        onClickMore(e.currentTarget, {
                                            targetEntry: indexEntry,
                                            targetEntryIsFirst: isFirst,
                                            targetEntryIsLast: isLast,
                                            targetEntryIsTopLevel: isTopLevel,
                                            targetEntryHasNoSiblings: hasNoSiblings
                                        });
                                        e.stopPropagation();
                                    }}
                                    size="small"
                                >
                                    <MoreHoriz />
                                </IconButton>
                            </Box>
                        }
                    >
                        {renderSubTrees(depth + 1, indexEntry['nested'], onClickMore)}
                    </TreeItem>
                );
            } else {
                treeItems.push(
                    <TreeItem
                        nodeId={indexEntry['id']}
                        label={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 0.5,
                                    pr: 0,
                                }}
                            >
                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                    {indexEntry['label']}
                                    {indexEntry['hasContent'] && (
                                        <>
                                        <Chip
                                            size="small"
                                            label="has content"
                                            sx={{ marginLeft: "5px" }}
                                        />
                                        <IconButton 
                                            size="small"
                                            onClick={() => {
                                                navigator.clipboard.writeText(indexEntry["id"]);
                                            }}
                                        >
                                            <CopyAll/>
                                        </IconButton>
                                        </>
                                    )}
                                </Typography>
                                <IconButton
                                    onClick={(e) => {
                                        onClickMore(e.currentTarget, {
                                            targetEntry: indexEntry,
                                            targetEntryIsFirst: isFirst,
                                            targetEntryIsLast: isLast,
                                            targetEntryIsTopLevel: isTopLevel,
                                            targetEntryHasNoSiblings: hasNoSiblings
                                        });
                                        e.stopPropagation();
                                    }}
                                    size="small"
                                >
                                    <MoreHoriz />
                                </IconButton>
                            </Box>
                        }
                    />
                );
            }
        }
        return treeItems;
    }

    const treeView = useMemo(() => {
        return (
            <>
                <TreeView
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    selected={selectedNodeId}
                    onNodeSelect={(e, nodeId) => {
                        setSelectedNodeId(nodeId);
                        e.stopPropagation();
                    }}
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                >
                    {renderSubTrees(0, labeledRefs, (e, d) => {
                        setAnchorEl(e);
                        setTargetEntry(d.targetEntry);
                        setTargetEntryIsFirst(d.targetEntryIsFirst);
                        setTargetEntryIsLast(d.targetEntryIsLast);
                        setTargetEntryIsTopLevel(d.targetEntryIsTopLevel);
                        setTargetEntryHasNoSiblings(d.targetEntryHasNoSiblings);
                    })}
                </TreeView>
                {targetEntry && (
                    <Menu
                        elevation={1}
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={() => setAnchorEl(undefined)}
                    >
                        <MenuItem
                            color="inherit"
                            disableRipple
                            onClick={(e: any) => {
                                setAnchorEl(undefined);
                                startEditHeaderToSelected();
                            }}
                        >
                            Edit Header
                        </MenuItem>
                        {!targetEntry['hasContent'] && (
                            <MenuItem
                                color="inherit"
                                disableRipple
                                onClick={(e: any) => {
                                    setAnchorEl(undefined);
                                    startAddHeaderToSelected();
                                }}
                            >
                                Add Header
                            </MenuItem>
                        )}
                        <Divider />
                        {(!targetEntry['nested'] || targetEntry['nested'].length == 0) && (
                            <MenuItem
                                color="inherit"
                                disableRipple
                                onClick={(e: any) => {
                                    setAnchorEl(undefined);
                                    startAddContentToSelected();
                                }}
                            >
                                {targetEntry['hasContent'] ?
                                    "Edit Content" :
                                    "Add Content"
                                }
                            </MenuItem>
                        )}
                        <MenuItem
                            color="danger"
                            disableRipple
                            onClick={(e: any) => {
                                setAnchorEl(undefined);
                                removeHeaderToSelected();
                            }}
                        >
                            Remove
                        </MenuItem>
                        <Divider />
                        {!targetEntryIsFirst && (
                            <MenuItem
                                color="danger"
                                disableRipple
                                onClick={(e: any) => {
                                    setAnchorEl(undefined);
                                    moveHeaderUp();
                                }}
                            >
                                Move Up
                            </MenuItem>
                        )}
                        {!targetEntryIsLast && (
                            <MenuItem
                                color="danger"
                                disableRipple
                                disabled={targetEntryIsLast}
                                onClick={(e: any) => {
                                    setAnchorEl(undefined);
                                    moveHeaderDown();
                                }}
                            >
                                Move Down
                            </MenuItem>
                        )}
                        {!targetEntryIsTopLevel && (
                            <MenuItem
                                color="danger"
                                disableRipple
                                onClick={(e: any) => {
                                    setAnchorEl(undefined);
                                    moveIntoParentHeader();
                                }}
                            >
                                Move Into Parent Header
                            </MenuItem>
                        )}
                        {!targetEntryHasNoSiblings && (
                            <MenuItem
                                color="danger"
                                disableRipple
                                onClick={(e: any) => {
                                    setAnchorEl(undefined);
                                    startMoveUnderSiblingHeader();
                                }}
                            >
                                Move Under Sibling Header
                            </MenuItem>
                        )}
                    </Menu>
                )}
            </>
        );
    }, [labeledRefs, anchorEl, menuOpen, targetEntry, selectedNodeId]);

    const targetEntryLabel: string | undefined = useMemo(() => {
        if (targetEntry) {
            return targetEntry['label'];
        }
    }, [targetEntry]);

    const targetEntryId: string | undefined = useMemo(() => {
        if (targetEntry) {
            return targetEntry['id'];
        }
    }, [targetEntry]);

    const sibilingSelectOptions: any[] = useMemo(() => {
        if (targetEntryId !== undefined) {
            const options: any[] = [];
            const populateOptions = (indexEntries: any[]) => {
                let entries = [];
                let flag = false;
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] === targetEntryId) {
                        flag = true;
                    } else {
                        entries.push(indexEntry);
                    }
                    populateOptions(indexEntry['nested']);
                }

                if (flag) {
                    for (const e of entries) {
                        options.push({
                            id: e["id"],
                            display: e["label"]
                        });
                    }
                }
            };

            populateOptions(labeledRefs);
            return options;
        }
        return [];
    }, [targetEntryId]);

    const removeHeaderToSelected = () => {
        if (targetEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] !== targetEntryId) {
                        indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                        entries.push(indexEntry);
                    }
                }
                return entries;
            };

            if (indexedGuidance) {
                let newLabeledRefs = updateLabeledRef(JSON.parse(JSON.stringify(labeledRefs)));
                updateIndexedGuidance({
                    id: indexedGuidance.id,
                    name: indexedGuidance.name,
                    description: indexedGuidance.description,
                    referenceMap: {
                        'labeledRefs': newLabeledRefs
                    }
                });
                setLabeledRefs(newLabeledRefs);
            }
            cancelUpdate();
        }
    };

    const startAddHeaderToSelected = () => {
        setContentInputState("HEADER");
    };

    const startEditHeaderToSelected = () => {
        if (targetEntryId !== undefined && indexedGuidance) {
            setContentInputState("EDIT_HEADER");
            setTargetLabel(targetEntryLabel);

            // load the content for a given entry if it exists
            trigger({
                id: targetEntryId,
                indexedGuidanceId: indexedGuidance.id
            }).then((res) => {
                if (res.isSuccess) {
                    let indexedGuidanceContent = res.data;
                    setTargetHtmlContent(indexedGuidanceContent?.htmlContent);
                    setTargetLexicalContent(indexedGuidanceContent?.lexicalContent);
                    setTargetContent(indexedGuidanceContent?.content);
                } else {
                    setTargetLexicalContent("");
                }
            }).catch((error) => {
                console.error(error);
                setTargetLexicalContent("");
            });
        }
    };

    const startAddContentToSelected = () => {
        if (targetEntryId !== undefined && indexedGuidance) {
            setContentInputState("CONTENT");

            // load the content for a given entry if it exists
            trigger({
                id: targetEntryId,
                indexedGuidanceId: indexedGuidance.id
            }).then((res) => {
                if (res.isSuccess) {
                    let indexedGuidanceContent = res.data;
                    setTargetHtmlContent(indexedGuidanceContent?.htmlContent);
                    setTargetLexicalContent(indexedGuidanceContent?.lexicalContent);
                    setTargetContent(indexedGuidanceContent?.content);
                } else {
                    setTargetLexicalContent("");
                }
            }).catch((error) => {
                console.error(error);
                setTargetLexicalContent("");
            });
        }
    };

    const moveHeaderUp = () => {
        if (targetEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                let toSwap: undefined | number = undefined;
                for (let i = 0; i < indexEntries.length; i++) {
                    let indexEntry = indexEntries[i];
                    indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                    entries.push(indexEntry);
                    if (indexEntry['id'] === targetEntryId) {
                        toSwap = i;
                    }
                }

                if (toSwap !== undefined) {
                    const temp = entries[toSwap];
                    entries[toSwap] = entries[toSwap - 1];
                    entries[toSwap - 1] = temp;
                }
                return entries;
            };

            if (indexedGuidance) {
                let newLabeledRefs = updateLabeledRef(JSON.parse(JSON.stringify(labeledRefs)));
                updateIndexedGuidance({
                    id: indexedGuidance.id,
                    name: indexedGuidance.name,
                    description: indexedGuidance.description,
                    referenceMap: {
                        'labeledRefs': newLabeledRefs
                    }
                });
                setLabeledRefs(newLabeledRefs);
            }
            cancelUpdate();
        }
    };

    const moveHeaderDown = () => {
        if (targetEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                let toSwap: undefined | number = undefined;
                for (let i = 0; i < indexEntries.length; i++) {
                    let indexEntry = indexEntries[i];
                    indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                    entries.push(indexEntry);
                    if (indexEntry['id'] === targetEntryId) {
                        toSwap = i;
                    }
                }

                if (toSwap !== undefined) {
                    const temp = entries[toSwap];
                    entries[toSwap] = entries[toSwap + 1];
                    entries[toSwap + 1] = temp;
                }
                return entries;
            };

            if (indexedGuidance) {
                let newLabeledRefs = updateLabeledRef(JSON.parse(JSON.stringify(labeledRefs)));
                updateIndexedGuidance({
                    id: indexedGuidance.id,
                    name: indexedGuidance.name,
                    description: indexedGuidance.description,
                    referenceMap: {
                        'labeledRefs': newLabeledRefs
                    }
                });
                setLabeledRefs(newLabeledRefs);
            }
            cancelUpdate();
        }
    };

    const moveIntoParentHeader = () => {
        if (targetEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                let removedEntries: any[] = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] === targetEntryId) {
                        removedEntries.push(indexEntry);
                    } else {
                        let res = updateLabeledRef(indexEntry['nested']);
                        indexEntry['nested'] = res.entries;

                        // Add original followed by any that were removed in the nested entries
                        entries.push(indexEntry);
                        if (res.removedEntries.length > 0) {
                            res.removedEntries.forEach((re) => entries.push(re));
                        }
                    }
                }
                return {
                    entries,
                    removedEntries
                };
            };

            if (indexedGuidance) {
                let res = updateLabeledRef(JSON.parse(JSON.stringify(labeledRefs)));
                updateIndexedGuidance({
                    id: indexedGuidance.id,
                    name: indexedGuidance.name,
                    description: indexedGuidance.description,
                    referenceMap: {
                        'labeledRefs': res.entries
                    }
                });
                setLabeledRefs(res.entries);                
            }
            cancelUpdate();
        }
    };

    const startMoveUnderSiblingHeader = () => {
        setMovingUnderSibilingHeader(true);
    };

    const moveUnderSiblingHeader = () => {
        if (targetEntryId !== undefined && sibilingHeaderEntryId !== undefined) {
            const removedEntries: any[] = [];
            const updateLabeledRef = (indexEntries: any[]) => {
                let entries = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    if (indexEntry['id'] === targetEntryId) {
                        removedEntries.push(indexEntry);
                    } else {
                        indexEntry['nested'] = updateLabeledRef(indexEntry['nested']);
                        entries.push(indexEntry);
                    }
                }
                return entries;
            };

            const insertLabeledRef = (newEntry: any, indexEntries: any[]) => {
                let entries = [];
                for (let i in indexEntries) {
                    let indexEntry = indexEntries[i];
                    indexEntry['nested'] = insertLabeledRef(newEntry, indexEntry['nested']);

                    // insert as last child
                    if (indexEntry['id'] === sibilingHeaderEntryId) {
                        indexEntry['nested'].push(newEntry);
                    } 
                    entries.push(indexEntry);
                    
                }
                return entries;
            };

            if (indexedGuidance) {
                // Update without target
                let newLabeledRefs = updateLabeledRef(JSON.parse(JSON.stringify(labeledRefs)));
                
                if (removedEntries.length === 1) {
                    const removedEntry = removedEntries[0];
                    newLabeledRefs = insertLabeledRef(removedEntry, newLabeledRefs)
                    updateIndexedGuidance({
                        id: indexedGuidance.id,
                        name: indexedGuidance.name,
                        description: indexedGuidance.description,
                        referenceMap: {
                            'labeledRefs': newLabeledRefs
                        }
                    });
                    setLabeledRefs(newLabeledRefs);
                }
                
            }
            cancelUpdate();
        }
    };

    const cancelUpdate = () => {
        setTargetLabel(undefined);
        setTargetHtmlContent(undefined);
        setTargetLexicalContent(undefined);
        setTargetContent(undefined);
        setContentInputState(null);
        setNeedsUpdate(false);
        setTargetEntry(undefined);
        setTargetEntryIsFirst(undefined);
        setTargetEntryIsLast(undefined);
        setTargetEntryIsTopLevel(undefined);
        setTargetEntryHasNoSiblings(undefined);
        setMovingUnderSibilingHeader(false);
        setSibilingHeaderEntryId(undefined);
    };

    const updateHeader = () => {
        let newLabeledRefs = JSON.parse(JSON.stringify(labeledRefs));
        if (targetEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                for (const indexEntry of indexEntries) {
                    if (indexEntry['id'] === targetEntryId) {
                        indexEntry['nested'].push({
                            id: uuidv4(),
                            label: targetLabel,
                            nested: [],
                            hasContent: false
                        })
                    } else {
                        updateLabeledRef(indexEntry['nested']);
                    }
                }
            };

            updateLabeledRef(newLabeledRefs);
        } else {
            newLabeledRefs.push({
                id: uuidv4(),
                label: targetLabel,
                nested: [],
                hasContent: false
            });
        }

        if (indexedGuidance) {
            updateIndexedGuidance({
                id: indexedGuidance.id,
                name: indexedGuidance.name,
                description: indexedGuidance.description,
                referenceMap: {
                    'labeledRefs': newLabeledRefs
                }
            });
            setLabeledRefs(newLabeledRefs);
        }
        cancelUpdate();
    };

    const updateHeaderInPlace = () => {
        let newLabeledRefs = JSON.parse(JSON.stringify(labeledRefs));
        if (targetEntryId !== undefined) {
            const updateLabeledRef = (indexEntries: any[]) => {
                for (const indexEntry of indexEntries) {
                    if (indexEntry['id'] === targetEntryId) {
                        indexEntry['label'] = targetLabel;
                    } else {
                        updateLabeledRef(indexEntry['nested']);
                    }
                }
            };
            updateLabeledRef(newLabeledRefs);
        }

        if (indexedGuidance) {
            updateIndexedGuidance({
                id: indexedGuidance.id,
                name: indexedGuidance.name,
                description: indexedGuidance.description,
                referenceMap: {
                    'labeledRefs': newLabeledRefs
                }
            });
            setLabeledRefs(newLabeledRefs);
        }
        cancelUpdate();
    };

    const doContentSave = () => {
        if (indexedGuidance) {
            upsertIndexedGuidanceContent({
                id: targetEntryId,
                indexedGuidanceId: indexedGuidance.id,
                content: targetContent,
                htmlContent: targetHtmlContent,
                lexicalContent: targetLexicalContent
            });
            cancelUpdate();
        }
    };

    const displayTree = (contentInputState === null || contentInputState === "HEADER" || contentInputState === "EDIT_HEADER");

    return (
        <SideNav>
            {isFetching ? (
                <div style={{ padding: 2 }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%'
                        }}>
                        <Grid
                            container
                            direction="row"
                            sx={{ height: '100%' }}
                        >
                            <Grid
                                xs={12}
                                sx={{
                                    height: '5%',
                                    display: 'inline-flex',
                                    alignItems: "center"
                                }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            alignItems: "center"
                                        }}
                                    >
                                        {indexedGuidance && (
                                            <AutoScalingInput
                                                name={indexedGuidanceName || indexedGuidance.name}
                                                onChange={(tn) => {
                                                    setIndexedGuidanceName(tn);
                                                    setNeedsSaveName(true);
                                                }}
                                            />
                                        )}
                                        {needsSaveName && (
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                sx={{ marginLeft: "15px" }}
                                                onClick={() => {
                                                    if (indexedGuidance) {
                                                        updateIndexedGuidance({
                                                            id: indexedGuidance.id,
                                                            name: indexedGuidanceName,
                                                            description: indexedGuidance.description,
                                                            referenceMap: indexedGuidance.referenceMap
                                                        });
                                                        setNeedsSaveName(false);
                                                    }
                                                }}
                                            >
                                                save name
                                            </Button>
                                        )}
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            disabled={contentInputState === "HEADER"}
                                            startIcon={<Add />}
                                            sx={{ marginLeft: "15px" }}
                                            onClick={() => {
                                                setTargetEntry(undefined);
                                                startAddHeaderToSelected();
                                            }}
                                        >
                                            header
                                        </Button>
                                        {(indexedGuidance && canImportExportPackage) && (
                                            <>
                                                <UpdateIndexedGuidanceDetails indexedGuidance={indexedGuidance}/>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    sx={{ marginLeft: "15px" }}
                                                    disabled={isUploadingPackage}
                                                    onClick={() => {
                                                        if (indexedGuidance) {
                                                            createIndexedGuidancePackage(indexedGuidance.id);
                                                        }
                                                    }}
                                                >
                                                    {isUploadingPackage ? "uploading package" : "create package"}
                                                </Button>
                                                <ExportGuidancePackageButton indexedGuidanceId={indexedGuidance.id} />
                                            </>
                                        )}
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid
                                xs={12}
                                container
                                sx={{
                                    height: '90%'
                                }}
                            >
                                <Grid xs={6} sx={{ height: '100%', overflow: "auto" }}>
                                    <Box display={displayTree ? "grid" : "none"}>
                                        {treeView}
                                    </Box>
                                    {contentInputState === "CONTENT" && (
                                        <>
                                            <Box sx={{ paddingTop: "10px" }}>
                                                {`Inserting under header "${targetEntryLabel}"`}
                                            </Box>
                                            <Box sx={{ paddingTop: "10px" }}>
                                                {(targetLexicalContent !== undefined) ? (
                                                    <RichTextEditor
                                                        contentLexical={targetLexicalContent}
                                                        onUpdate={
                                                            (content, htmlContent, lexicalContent) => {
                                                                setTargetContent(content);
                                                                setTargetHtmlContent(htmlContent);
                                                                setTargetLexicalContent(lexicalContent)
                                                                setNeedsUpdate(true);
                                                            }
                                                        }
                                                    />
                                                ) : (
                                                    <CircularProgress />
                                                )}
                                            </Box>
                                            <Box sx={{ paddingTop: "10px" }}>
                                                <Button
                                                    variant="outlined"
                                                    disabled={!needsUpdate}
                                                    startIcon={<Save />}
                                                    sx={{ marginLeft: "5px" }}
                                                    onClick={() => doContentSave()}
                                                >
                                                    save content
                                                </Button>
                                                <Button
                                                    sx={{ marginLeft: "5px" }}
                                                    variant="outlined"
                                                    onClick={cancelUpdate}
                                                >
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                </Grid>
                                <Grid xs={6} sx={{ height: '100%' }}>
                                    {pdfIFrame}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <SimpleModalWrapper
                        headerText={targetEntryLabel ? (
                            (contentInputState === "HEADER") ? (
                                `Inserting under header "${targetEntryLabel}"`
                            ) : (
                                `Editing header "${targetEntryLabel}"`
                            )
                        ) : (
                            "Inserting at root"
                        )}
                        open={contentInputState === "HEADER" || contentInputState === "EDIT_HEADER"}
                        handleClose={() => cancelUpdate()}
                        maxWidth='md'
                    >
                        <Box sx={{ paddingTop: "10px" }}>
                            <Box>
                                <TextField
                                    fullWidth
                                    multiline
                                    label="Header Label"
                                    placeholder="enter a label for the header"
                                    value={targetLabel}
                                    onChange={(e) => setTargetLabel(e.target.value)}
                                />
                            </Box>
                            <Box
                                sx={{
                                    paddingTop: "20px",
                                    textAlign: "right"
                                }}
                            >
                                {(contentInputState === "HEADER") && (
                                    <Button
                                        variant="outlined"
                                        disabled={targetLabel === ""}
                                        onClick={updateHeader}
                                    >
                                        Add
                                    </Button>
                                )}
                                {(contentInputState === "EDIT_HEADER") && (
                                    <Button
                                        variant="outlined"
                                        disabled={targetLabel === ""}
                                        onClick={updateHeaderInPlace}
                                    >
                                        Update
                                    </Button>
                                )}
                                <Button
                                    sx={{ marginLeft: "5px" }}
                                    variant="outlined"
                                    onClick={cancelUpdate}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </SimpleModalWrapper>
                    <SimpleModalWrapper
                        headerText="Inserting Under Sibling"
                        open={movingUnderSibilingHeader}
                        handleClose={() => cancelUpdate()}
                        maxWidth='md'
                    >
                        <Box sx={{ paddingTop: "10px" }}>
                            <Box>
                                <Select
                                    fullWidth
                                    size="small"
                                    sx={{ minWidth: "200px" }}
                                    label=""
                                    defaultValue={sibilingHeaderEntryId}
                                    onChange={(e: any) => {
                                        setSibilingHeaderEntryId(e.target.value);
                                    }}>
                                    {sibilingSelectOptions.map((o) =>
                                        <MenuItem value={o["id"]}>
                                            {o["display"]}
                                        </MenuItem>
                                    )}
                                </Select>
                                <FormHelperText>
                                    This will move your header after the last child header of the selected sibiling header
                                </FormHelperText>
                            </Box>
                            <Box
                                sx={{
                                    paddingTop: "20px",
                                    textAlign: "right"
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    disabled={!sibilingHeaderEntryId}
                                    onClick={moveUnderSiblingHeader}
                                >
                                    Move
                                </Button>
                                <Button
                                    sx={{ marginLeft: "5px" }}
                                    variant="outlined"
                                    onClick={cancelUpdate}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </SimpleModalWrapper>
                </>
            )}
        </SideNav>
    );
}

export default IndexedGuidance;

