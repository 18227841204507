import SocketIO from 'socket.io-client';
import { AUTH0_TOKEN_KEY } from './constants';

const URL = !!window.__RUNTIME_CONFIG__ ? window.__RUNTIME_CONFIG__.WS_ENDPOINT : "http://localhost:8083"
const FB_TOKEN_KEY = "fb-token";;

const auth0token = localStorage.getItem(AUTH0_TOKEN_KEY);
const fbtoken = localStorage.getItem(FB_TOKEN_KEY);
const tokenToAssign = auth0token ? auth0token : fbtoken;

const socket = SocketIO(URL, {
    autoConnect: false,
    auth: (cb) => {
        cb({
            token: tokenToAssign
        });
    }
});

export default socket;