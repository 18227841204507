import React, { useEffect } from "react";
import { List, ListItem } from "@mui/material/";
import Box from '@mui/material/Box';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { ReportModifier } from "../../../types/builderv2.generated";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Edit } from "@mui/icons-material";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";

interface NodeRowProps {
    reportModifier: ReportModifier;
    setEditReportModifier: (n: ReportModifier) => void;
}

const Row = ({
    reportModifier,
    setEditReportModifier
}: NodeRowProps) => {
    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    {reportModifier.evaluationStrategy}
                </TableCell>
                <TableCell scope="row">
                    <List>
                        {reportModifier.conditionGroups?.map(cg => (
                            <ListItem>
                                {cg.conditionResolver?.conditionDefinition?.expression}
                            </ListItem>
                        ))}
                    </List>
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup buttons={[
                        {
                            icon: (<Edit fontSize="small" />),
                            handleClick: () => {
                                setEditReportModifier(reportModifier);
                            }
                        },
                    ]}
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

interface ReportModifiersEditorProps {
    fullSchema: RJSFSchema;
    initialReportModifiers?: ReportModifier[];
    onUpdate: (rts: ReportModifier[]) => void;
}

const ReportModifiersEditor = ({
    fullSchema,
    initialReportModifiers,
    onUpdate
}: ReportModifiersEditorProps) => {
    const [editReportModifierIndex, setEditReportModifierIndex] = React.useState<number | null>(null);
    const [editReportModifier, setEditReportModifier] = React.useState<ReportModifier | null>(null);
    const [reportModifiers, setReportModifiers] = React.useState<ReportModifier[]>([]);

    useEffect(() => {
        if (initialReportModifiers) {
            setReportModifiers(JSON.parse(JSON.stringify(initialReportModifiers)));
        }
    }, [initialReportModifiers]);

    const updateReportModifier = (rt: ReportModifier) => {
        const newReportModifiers = JSON.parse(JSON.stringify(reportModifiers));
        for (let i = 0; i < newReportModifiers.length; i++) {
            if (editReportModifierIndex === i) {
                newReportModifiers[i] = rt;
            }
        }
        onUpdate(newReportModifiers);
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Strategy
                            </TableCell>
                            <TableCell>
                                Condition
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportModifiers.map((rm, i) => (
                            <Row
                                reportModifier={rm}
                                setEditReportModifier={(locRm) => {
                                    setEditReportModifierIndex(i);
                                    setEditReportModifier(locRm);
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleModalWrapper
                headerText="Edit Report"
                open={!!editReportModifier}
                handleClose={() => setEditReportModifier(null)}
                maxWidth='md'
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '3%',
                        alignContent: "center"
                    }}
                >
                    {editReportModifier && (
                        <Form
                            formData={editReportModifier}
                            onSubmit={(data, event) => {
                                updateReportModifier(data.formData);
                                setEditReportModifier(null)
                            }}
                            schema={{
                                title: "Report Modifier",
                                type: "object",
                                properties: {
                                    evaluationStrategy: {
                                        "type": "string",
                                        "enum": [
                                            "ifelse",
                                            "ifelse-else",
                                            "all"
                                        ]
                                    },
                                    conditionGroups: {
                                        "type": "array",
                                        "items": {
                                            "$ref": "#/$defs/conditionGroup"
                                        }
                                    }
                                },
                                $defs: fullSchema.$defs
                            } as RJSFSchema}
                            validator={validator}
                        />
                    )}
                </Box>
            </SimpleModalWrapper>
        </>
    );
}

export default ReportModifiersEditor;
