import { createApi } from '@reduxjs/toolkit/query/react';
import { AddApplicationPayload, Application, ApplicationConfigurationEntry, ApplicationTag } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { ListResponse, PaginatedResponse } from '../models/commonTypes';

export const applicationApi = createApi({
    reducerPath: 'applicationApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Applications', 'ApplicationTags'],
    endpoints: (build) => ({
        getCurrentApplication: build.query<ListResponse<ApplicationTag>, void>({
            query: () => ({
                url: `current_applications`,
                method: "GET"
            }),
            providesTags: (result, error) => [{ type: 'ApplicationTags', id: "CURRENT" }],
        }),
        listApplications: build.query<PaginatedResponse<Application>, { page: number | void, limit: number | void }>({
            query: ({page = 1, limit = 10}) => ({
                url: `applications?page=${page}&limit=${limit}`,
                method: "GET"
            }),            
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'Applications', id } as const)),
                        { type: 'Applications', id: 'LIST' },
                    ]
                :
                    [{ type: 'Applications', id: 'LIST' }],
        }),
        addApplication: build.mutation<Application, Partial<AddApplicationPayload>>({
            query(data) {
                const { tag } = data;
                return {
                    url: `applications`,
                    method: 'POST',
                    data: {
                        tag_type: tag?.type,
                        tag_data: tag?.data
                    }
                }
            },
            invalidatesTags: [{ type: 'Applications', id: 'LIST' }],
        }),
        getApplication: build.query<Application, string>({
            query: (id) => ({
                url: `applications/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'Applications', id }],
        }),
        addAppConfigEntry: build.mutation<Application, Partial<ApplicationConfigurationEntry> & {applicationId: string;}>({
            query(payload) {
                const { 
                    key, 
                    data, 
                    applicationId,
                    applicationConfigurationId 
                } = payload;
                return {
                    url: `applications/${applicationId}/configurations/${applicationConfigurationId}/entry`,
                    method: 'POST',
                    data: {
                        key: key,
                        data: data
                    }
                }
            },
            invalidatesTags: (result, error, { applicationId }) => [{ type: 'Applications', id: applicationId }],
        }),
        removeAppConfigEntry: build.mutation<Application, Partial<ApplicationConfigurationEntry> & {applicationId: string;}>({
            query(payload) {
                const {
                    id, 
                    applicationId,
                    applicationConfigurationId 
                } = payload;
                return {
                    url: `applications/${applicationId}/configurations/${applicationConfigurationId}/entry/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { applicationId }) => [{ type: 'Applications', id: applicationId }],
        }),
        deleteApplicationTag: build.mutation<ApplicationTag, string>({
            query: (id) => ({
                url: `application_tags/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Applications', id: result?.applicationId }],
        })
    }),
})

export const {
    useGetCurrentApplicationQuery,
    useListApplicationsQuery,
    useAddApplicationMutation,
    useGetApplicationQuery,
    useAddAppConfigEntryMutation,
    useRemoveAppConfigEntryMutation,
    useDeleteApplicationTagMutation
} = applicationApi;