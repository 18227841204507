import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { PlayArrow } from "@mui/icons-material";
import { Assistant } from "../../redux/models/dataModelTypes";
import { useListAssistantsQuery } from "../../redux/services/assistant";
import PagedTable from "../../components/tables/pagedTable";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 5

interface Props {
    languageModelId: string;
}

const MyAssistants = ({ languageModelId }: Props) => {
    const [page, setPage] = useState<number>(DEFAULT_PAGE);
    const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);
    const navigate = useNavigate();

    const { data: assistants } = useListAssistantsQuery({ page, limit, languageModelId });

    return (
        <PagedTable
            paginatedResponse={assistants}
            tableSpan={5}
            rowMapper={(a: Assistant) => (
                <TableRow
                    key={a.id}
                >
                    <TableCell>
                        {a.name ? a.name : "no name"}
                    </TableCell>
                    <TableCell>{a.description}</TableCell>
                    <TableCell>{new Date(a.createdAt).toLocaleString()}</TableCell>
                    <TableCell>
                        <CrudButtonGroup 
                            buttons={[
                                {
                                    icon: (<PlayArrow fontSize="small"/>),
                                    handleClick: () => {
                                        navigate(`/assistant/${a.id}`);
                                    }
                                }
                            ]} 
                        />
                    </TableCell>
                </TableRow>
            )}
            headers={[
                "Label",
                "Description",
                "Created",
                ""
            ]}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
        />
        
    );
}

export default MyAssistants;
