import { useMemo, useState } from 'react';
import { 
    Box, Button, List, ListItem, ListItemText, ListItemIcon, Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import { TakerDocumentAnalysis } from '../../../redux/models/dataModelTypes';
import { SimpleModalWrapper } from '../../../components/dialog/wrappers/simpleModalWrapper';
import { Info, Warning } from '@mui/icons-material';
import { useSelector } from '../../../redux/reduxUtils/functions';
import { RootReducerType } from '../../../redux/models/reduxTypes';
import { useReportState } from '../../../containers/TakerDocumentState/ReportState';
import { ReportRevision } from '../../../types/taker/reportstate.generated';

interface SyncReportModalProps {
    open: boolean;
    onClose: () => void;
    latestMemoGenAnalysis: TakerDocumentAnalysis;
    reportRevision: ReportRevision;
};

const SyncReportModal = ({
    open,
    onClose,
    latestMemoGenAnalysis,
    reportRevision
}: SyncReportModalProps) => {
    const { updateSycnedWithGenerationId } = useReportState();
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const debugLogs: Array<any> = useMemo(() => {
        let finalTaskState = latestMemoGenAnalysis.data['final_task_state'];
        if (!finalTaskState || !finalTaskState['logs']) {
            return [];
        }
        return finalTaskState['logs']['debug'];
    }, [latestMemoGenAnalysis]);

    const warningLogs: Array<any> = useMemo(() => {
        let finalTaskState = latestMemoGenAnalysis.data['final_task_state'];
        if (!finalTaskState || !finalTaskState['logs']) {
            return [];
        }
        return finalTaskState['logs']['warning'];
    }, [latestMemoGenAnalysis]);

    const { user } = useSelector((state: RootReducerType) => state.auth);

    const isDebugger = useMemo(() =>
        !!user?.roles.includes('DEBUGGER'),
        [user]
    );

    return (
        <SimpleModalWrapper
            headerText="Sync Report"
            open={open}
            handleClose={() => onClose()}
            maxWidth='md'
            buttonElements={[
                <Button
                    variant="contained"
                    onClick={() => {
                        updateSycnedWithGenerationId(latestMemoGenAnalysis.id);
                        onClose();
                    }}
                >
                    Start Sync
                </Button>,
                <Button
                    sx={{ float: "right" }}
                    variant="outlined"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </Button>
            ]}
        >
            <Box padding={1}>
                {isDebugger && (
                    <Accordion expanded={expanded === 'debuglogs'} onChange={handleChange('debuglogs')}>
                        <AccordionSummary aria-controls="debuglogs-content" id="debuglogs-header">
                            <Typography>Debug Logs</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {(debugLogs.length > 0) ? (
                                <Box>
                                    <List dense={true}>
                                        {debugLogs.map((w: any, i: number) => (
                                            <ListItem key={i}>
                                                <ListItemIcon>
                                                    <Warning fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={w['text']} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            ) : (
                                <Box>
                                    None
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )}
                <Accordion expanded={expanded === 'warnings'} onChange={handleChange('warnings')}>
                    <AccordionSummary aria-controls="warnings-content" id="warnings-header">
                        <Typography>Warnings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(warningLogs.length > 0) ? (
                            <Box>
                                <List dense={true}>
                                    {warningLogs.map((w: any, i: number) => (
                                        <ListItem key={i}>
                                            <ListItemIcon>
                                                <Info fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary={w['text']} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        ) : (
                            <Box>
                                None
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'summary'} onChange={handleChange('summary')}>
                    <AccordionSummary aria-controls="summary-content" id="summary-header">
                        <Typography>Summary</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Coming Soon!
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'details'} onChange={handleChange('details')}>
                    <AccordionSummary aria-controls="details-content" id="details-header">
                        <Typography>Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Coming Soon!
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </SimpleModalWrapper>
    );
};

export default SyncReportModal;