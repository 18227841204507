import { useMemo, useState } from "react";
import SideNav from "../../components/navigation/SideNav";
import { Box, Typography, Fab } from "@mui/material/";
import { PlusIcon } from "../../assets/icons";
import { CreateIndexedGuidanceModal } from "./CreateIndexedGuidanceModal";
import { IndexedGuidanceTable } from "./IndexedGuidanceTable";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";


const ManageIndexedGuidance = () => {
    const [createToggle, setCreateToggle] = useState(false);

    const { user } = useSelector((state: RootReducerType) => state.auth);

    const canCreateIndexedGuidance = useMemo(
        () => user && user.roles.includes("SUPERADMIN"),
        [user]
    );

    return (
        <SideNav>
            <Box
                sx={{
                    padding: 2,
                    width: "100%"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Indexed Guidance
                        </Typography>
                    </Box>
                    <Fab
                        variant="extended"
                        color="primary"
                        size="large"
                        sx={{ fontWeight: "bold" }}
                        component="button"
                        onClick={(e: any) => setCreateToggle(true)}
                    >
                        <PlusIcon sx={{ mr: 1 }} />
                        Create
                    </Fab>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    padding: 2,
                }}
            >
                <IndexedGuidanceTable />
            </Box>
            {canCreateIndexedGuidance && (
                <CreateIndexedGuidanceModal
                    open={createToggle}
                    setOpen={setCreateToggle}
                />
            )}
        </SideNav>
    );
};

export default ManageIndexedGuidance;
