import { Box, Button, Typography } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react";
import './auth0Failure.css'

const Auth0Failure = () => {
    const { logout } = useAuth0();
    return (
        <Box
            id="auth0-failure-page"
            sx={{
                textAlign: 'center',
                width: '100%',
                height: "100vh",
                placeItems: "center",
                display: "grid",
            }}
        >
            <Box>
                <img src={require("../../assets/images/logo_icon_300Px.png")} alt="Logo" style={{ width: 100, height: 100, marginBottom: 10 }} />
                <Typography variant="h4" color="primary" sx={{ mb: 1 }}>Login Failed</Typography>
                <Typography variant="body1" sx={{ width: "30%", margin: "auto", mb: 1 }}>
                    There was an issue logging you in. Please try again.
                </Typography>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                    sx={{ mt: 1, mb: 1 }}
                >
                    Try Again
                </Button>
                <Typography variant="body1" sx={{ width: "50%", margin: "auto", mt: 1 }}>
                    If the issue persists, please contact support at <a href="mailto:support@praxi.io">support@praxi.io</a> or use the help button.
                </Typography>
            </Box>
        </Box>
    )
}

export default Auth0Failure;