import React from "react";
import { Box, CircularProgress } from "@mui/material";

const RectangularLoading = () => {
    return (    
        <Box 
            height="100%"
            width="100%"
            display="block"
            alignItems="center"
            alignContent="center"
            textAlign="center"
        >
            <CircularProgress />
        </Box> 
    );
};

export default RectangularLoading;