import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
} from "@mui/material/";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { Builder, User } from "../../redux/models/dataModelTypes";
import { useUpdateBuilderMutation, useDeleteBuilderMutation } from "../../redux/services/builder";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootReducerType } from "../../redux/models/reduxTypes";

interface ConfirmArchiveDialogProps {
  builderId: string;
  showDialog: boolean;
  setShowDialog: (s: boolean) => void;
}

export const ConfirmArchiveDialog = ({
  builderId,
  showDialog,
  setShowDialog
}: ConfirmArchiveDialogProps) => {
  const [deleteBuilder, deleteBuilderResult] = useDeleteBuilderMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (deleteBuilderResult.isSuccess) {
      // notify user that we archived
      navigate("home");
    }
  }, [deleteBuilderResult]);

  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Archive "}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure? This will deprecate this automation, and this action will be visible to users of this automation.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            deleteBuilder(builderId);
            setShowDialog(false);
          }}
          autoFocus
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setShowDialog(false);

          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface PropertiesEditorProps {
  builder: Builder;
}

const PropertiesEditor = ({ builder }: PropertiesEditorProps) => {
  const { user } = useSelector((state: RootReducerType) => state.auth);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [updateBuilder, updateBuilderResult] = useUpdateBuilderMutation();

  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required()
  });

  const defaultValues = {
    name: builder.name,
    description: builder.description
  };

  const submitCustom: SubmitHandler<any> = (d) => {
    //TODO: update mutators so only specified data is changed...
    updateBuilder({
      id: builder.id,
      name: d.name,
      description: d.description,
      status: builder.status
    });
  };

  const publishBuilder: () => void = () => {
    //TODO: update mutators so only specified data is changed...
    updateBuilder({
      id: builder.id,
      name: builder.name,
      description: builder.description,
      status: 'PUBLISHED'
    });
  };


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string;
    description: string;
  }>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  return (
    <>
      <form
        onSubmit={handleSubmit(submitCustom)}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextFieldRhf
          sx={{ marginTop: "20px" }}
          label="Name"
          fullWidth={false}
          fieldName="name"
          variant="outlined"

          control={control}
          errors={errors}
        />
        <TextFieldRhf
          sx={{ marginTop: "20px" }}
          label="Description"
          multiline={true}
          fieldName="description"
          variant="outlined"
          control={control}
          errors={errors}
        />
        <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <Button
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
      <Divider />
      {user?.roles.includes('BUILDER') && (
      <Box sx={{ marginTop: "20px" }}>
        <Button
          variant="contained" 
          style={{ margin: "20px" }}
          onClick={publishBuilder}
        >
          Publish Automation
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setShowDialog(true)}
        >
          Archive Automation
        </Button>
        <ConfirmArchiveDialog
          builderId={builder.id}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
        />
      </Box>
      )}
    </>
  );
}

export default PropertiesEditor;
