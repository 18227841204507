import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { $getRoot, LexicalEditor } from 'lexical';
import { KeyTerm } from '../../../../types/taker/documentkeyterms.generated';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../wrappers/RichTextEditor/theme";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { KeyTermNode } from '../../nodes/KeyTermNode';
import { DeleteIcon } from '../../../../assets/icons';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import '../../wrappers/RichTextEditor/index.css';

interface SummaryPopupProps {
    editor: LexicalEditor;
    onClose: (e: React.MouseEvent) => void;
    originalRangeRect: DOMRect;
    keyTerm: KeyTerm;
}

const SyncEditorPlugin = ({ defaultValue }: { defaultValue: string }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        try {
            const editorState = editor.parseEditorState(defaultValue);
            editor.setEditorState(editorState);
        } catch (e) {
            // catch all errors here and clear the editor
            editor.update(() => {
                const root = $getRoot();
                root.clear();
            });
        }
    }, [defaultValue, editor]);

    return null;
};

const SummaryPopup = ({
    editor,
    onClose,
    originalRangeRect,
    keyTerm
}: SummaryPopupProps) => {
    const boxRef = useRef<HTMLDivElement>(null);

    const selectionState = useMemo(
        () => ({
            container: document.createElement('div'),
            elements: [],
        }),
        [],
    );

    const updateLocation = useCallback(() => {
        if (originalRangeRect) {
            const boxElem = boxRef.current;
            if (boxElem !== null) {
                const { left, top, bottom, width } = originalRangeRect;
                let correctedLeft = left + (width / 2) - boxElem.offsetWidth / 2;
                if (correctedLeft < 10) {
                    correctedLeft = 10;
                }
                if (correctedLeft + boxElem.offsetWidth > window.innerWidth - 10) {
                    correctedLeft = window.innerWidth - 10 - boxElem.offsetWidth;
                }
                let correctedTop = bottom + 20 + (window.pageYOffset || document.documentElement.scrollTop);

                // Checks if the box is off screen, if so, put it above the selection
                let above = false;
                if (correctedTop + boxElem.offsetHeight > window.innerHeight) {
                    correctedTop = top - boxElem.offsetHeight - 20;
                    above = true;
                }

                boxElem.style.left = `${correctedLeft}px`;
                boxElem.style.top = `${correctedTop}px`;
            }
        }
    }, [
        editor,
        selectionState,
        originalRangeRect,
        boxRef
    ]);

    useLayoutEffect(() => {
        updateLocation();
        const container = selectionState.container;
        const body = document.body;
        if (body !== null) {
            body.appendChild(container);
            return () => {
                body.removeChild(container);
            };
        }
    }, [selectionState.container, updateLocation]);

    useEffect(() => {
        window.addEventListener('resize', updateLocation);
        return () => {
            window.removeEventListener('resize', updateLocation);
        };
    }, [updateLocation]);

    return (
        <div 
            className="KeyTermPopupPlugin_SummaryPopup" 
            ref={boxRef}
        >
            <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center"
                m={1}
            >
                <Typography 
                    variant="subtitle2"
                    sx={{ 
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }}
                    flexGrow={1}
                >
                    {keyTerm.termName}
                </Typography>
                <IconButton
                    data-testid="exit-summary-popup-button"
                    size='small'
                    onClick={onClose}
                >
                    <DeleteIcon htmlColor="black" />
                </IconButton>
            </Box>
            <Box 
                m={1}
                onClick={(e) => { e.stopPropagation() }}
            >
                <LexicalComposer
                    initialConfig={{
                        editable: false,
                        namespace: `view-summary-${keyTerm.termName}`,
                        theme: EditorThemeClasses,
                        onError(error: any) {
                            console.error(error);
                        },
                        nodes: [
                            HeadingNode,
                            ListNode,
                            ListItemNode,
                            TableNode,
                            TableCellNode,
                            TableRowNode,
                            KeyTermNode
                        ]
                    }}
                >
                    <SyncEditorPlugin defaultValue={keyTerm.summary} />
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable
                                style={{
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    minHeight: '25px'
                                }}
                            />
                        }
                        placeholder={<div className="editor-placeholder"></div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ListPlugin />
                    <TabIndentationPlugin />
                    <HorizontalRulePlugin />
                    <ClearEditorPlugin />
                    <TablePlugin hasCellMerge />
                </LexicalComposer>
            </Box>
        </div>
    );
}

export default SummaryPopup;






