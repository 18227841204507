import React, { CSSProperties } from "react";
import { InputVariantType } from "../models/muiTypes";
import { Controller, FieldErrors } from "react-hook-form";
import { FormHelperText, TextField } from "@mui/material/";

interface TextFieldRhfMemoProps {
  label: string;
  variant?: InputVariantType;
  fullWidth?: boolean;
  fieldName: string;
  disabled?: boolean;
  sx?: CSSProperties;
  control: any;
  errors: FieldErrors<any>;
  multiline?: boolean
  formHelperText?: string;
}

export const TextFieldRhf = ({
  label,
  variant,
  fullWidth,
  fieldName,
  disabled,
  sx,
  control,
  errors,
  multiline,
  formHelperText
}: TextFieldRhfMemoProps) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <>
          <TextField
            {...field}
            multiline={multiline ?? false}
            sx={sx ?? {}}
            disabled={disabled ?? false}
            label={label}
            variant={variant ?? "standard"}
            fullWidth={fullWidth ?? false}
            error={!!errors[`${fieldName}`]}
            helperText={(errors[`${fieldName}`]?.message as string | undefined | null) ?? ""}
          />
          {formHelperText && (
            <FormHelperText>
              {formHelperText}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};
