import React from "react";
import { TakerDocument } from "../../redux/models/dataModelTypes";
import { Chip } from "@mui/material";

interface TakerDocumentStatusProps {
  takerDocument: TakerDocument;
}

const TakerDocumentStatus = ({
  takerDocument
}: TakerDocumentStatusProps) => {
  if (takerDocument.state && takerDocument.state.reviewState === "COMPLETED") {
    return (
      <Chip 
        color="success"
        label="Completed"
      />
    );
  } else if (takerDocument.state && takerDocument.state.reviewState === "DRAFT") {
    return (
      <Chip 
        color="info"
        label="Draft"
      />
    );
  } else if (takerDocument.state && takerDocument.state.reviewState === "PENDING") {
    return (
      <Chip 
        color="default"
        label="In Review"
      />
    );
  }
  return (
    <Chip 
      color="warning"
      label="Unknown"
    />
  );
}

export default TakerDocumentStatus;