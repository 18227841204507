import React, { useEffect } from "react";
import { Box, Button } from '@mui/material';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Delete, Edit } from "@mui/icons-material";
import { VariableDefinition } from "../../../types/builderv2.generated";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";


interface VariableRowProps {
    variable: VariableDefinition;
    setEditVariable: (n: VariableDefinition) => void;
    onDelete: () => void;
}

const VariableRow = ({
    variable,
    setEditVariable,
    onDelete
}: VariableRowProps) => {
    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    {variable.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {variable.label}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup 
                        buttons={[
                            {
                                icon: (<Edit fontSize="small" />),
                                handleClick: () => {
                                    setEditVariable(variable);
                                }
                            },
                            {
                                icon: (<Delete fontSize="small" />),
                                handleClick: () => onDelete()
                            }
                        ]} 
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

interface ReportEditorProps {
    fullSchema: RJSFSchema;
    initialVariables?: VariableDefinition[];
    onUpdate: (vs: VariableDefinition[]) => void;
}

const VariablesEditor = ({
    fullSchema,
    initialVariables,
    onUpdate
}: ReportEditorProps) => {
    const [editVariable, setEditVariable] = React.useState<VariableDefinition | null>(null);
    const [variables, setVariables] = React.useState<VariableDefinition[]>([]);
    const [adding, setAdding] = React.useState<boolean>(false);

    useEffect(() => {
        if (initialVariables) {
            setVariables(JSON.parse(JSON.stringify(initialVariables)));
        }
    }, [initialVariables]);

    const updateVariable = (v: VariableDefinition) => {
        const newVariables = JSON.parse(JSON.stringify(variables));
        if (adding) {
            newVariables.push(v);
        } else {
            for (let i = 0; i < newVariables.length; i++) {
                if (v.id === newVariables[i].id) {
                    newVariables[i] = v;
                }
            }
        }
        onUpdate(newVariables);
        setAdding(false);
    };

    const onDelete = (i: number) => {
        const newVariables = JSON.parse(JSON.stringify(variables));
        newVariables.splice(i, 1);
        onUpdate(newVariables);
    };

    return (
        <>
            <Button
                sx={{ m: 1 }}
                variant="contained"
                size="small"
                onClick={() => {
                    setAdding(true);
                    setEditVariable({} as VariableDefinition);
                }}
            >
                Add
            </Button>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                ID
                            </TableCell>
                            <TableCell>
                                Label
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {variables.map((v, i) => (
                            <VariableRow
                                key={v.id}
                                variable={v}
                                setEditVariable={setEditVariable}
                                onDelete={() => onDelete(i)}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleModalWrapper
                headerText="Edit Variable"
                open={!!editVariable}
                handleClose={() => setEditVariable(null)}
                maxWidth='md'
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '3%',
                        alignContent: "center"
                    }}
                >
                    {editVariable && (
                        <Form
                            formData={editVariable}
                            onSubmit={(data, event) => {
                                updateVariable(data.formData);
                                setEditVariable(null);
                            }}
                            schema={({
                                $ref: "#/$defs/variableDefinition",
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator}
                        />
                    )}
                </Box>
            </SimpleModalWrapper>
        </>
    );
}

export default VariablesEditor;
