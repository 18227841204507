import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { authAction } from "../../redux/actions/authActions";
import { useEffect } from "react";
import { store } from "../../redux/store";
import { User } from "../../redux/models/dataModelTypes";
import { useNavigate } from "react-router-dom";
import { AUTH0_TOKEN_KEY } from "../../utils/constants";
import DefaultLoadingScreen from "../../components/loading/defaultLoadingScreen";

const API_ENDPOINT = window.__RUNTIME_CONFIG__.API_ENDPOINT;

const Auth0Landing = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const fetchProtectedData = async () => {
        const token = await getAccessTokenSilently();
        let response;
        try {
            response = await axios.post(`${API_ENDPOINT}/v1/current_user_auth0`, {}, {
                headers: { 'Auth0-Authorization': `Bearer ${token}` },
                withCredentials: true
            })
        } catch (error) {
            //do nothing
        }
        if (response) {
            localStorage.setItem(AUTH0_TOKEN_KEY, token);
            const userData = response?.data as User;
            store.dispatch({
                type: authAction.SET_USER_SUCESS,
                payload: userData
            });
            navigate("/");
        } else {
            navigate("/authError");
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            fetchProtectedData();
        }
    }, [isAuthenticated]);

    return (
        <DefaultLoadingScreen />
    )
}

export default Auth0Landing;