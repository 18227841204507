import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box
} from "@mui/material/";
import SideNav from "../../components/navigation/SideNav";
import BuilderEditor from "./builderEditor"

import MemoTemplateLexicalEditor from "../../components/lexical/wrappers/MemoTemplateEditor";
import PropertiesEditor from "./propertiesEditor";
import { useGetBuilderDocumentQuery, useGetBuilderQuery } from "../../redux/services/builder";
import BuilderEditorV2 from "./builderEditorV2";
import ModelSettings from "./modelSettings";
import TabPanel, { a11yProps } from "../../components/navigation/TabPanel";
import GuidanceAssignment from "./GuidanceAssignment";
import { Builder } from "../../redux/models/dataModelTypes";
import Simulation from "./simulation";

interface InnerBuilderProps {
  builder: Builder;
  builderDocumentId: string;
}

const InnerBuilder = ({
  builder,
  builderDocumentId
}: InnerBuilderProps) => {
  const [tabValue, setTabValue] = useState(0);
  const { data: builderDocument } = useGetBuilderDocumentQuery(builderDocumentId);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  return (
    <div
      style={{
        padding: "10px",
        height: "100%"
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
      >
        <Tab label="Properties" {...a11yProps(0)} />
        <Tab label="Configuration" {...a11yProps(1)} />
        <Tab label="Spec Builder" {...a11yProps(2)} />
        <Tab label="Memo Template" {...a11yProps(3)} />
        <Tab label="Configure Co-Pilot" {...a11yProps(4)} />
        <Tab label="Configure Guidance" {...a11yProps(5)} />
        <Tab label="Simulation" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <PropertiesEditor builder={builder} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {builderDocument && (
          <BuilderEditor builderDocument={builderDocument} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {builderDocument && (
          <BuilderEditorV2 initialBuilderDocument={builderDocument} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        {builderDocument && (
          <MemoTemplateLexicalEditor builderDocument={builderDocument} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        {builderDocument && (
          <ModelSettings builderDocumentId={builderDocument.id} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        {builderDocument && (
          <GuidanceAssignment builderDocument={builderDocument} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
          <Box
              height="100%"
              sx={{
                  overflowY: "scroll"
              }}
          >
            {builderDocument && (
              <Simulation builderDocument={builderDocument}/>
            )}
          </Box>
      </TabPanel>
    </div>
  );
}

const BuilderWrapper = () => {
  const { id } = useParams<{ id: any }>();
  const { data: builder } = useGetBuilderQuery(id);
  return (
    <SideNav>
      {builder && builder?.builderDocuments[0] && (
        <InnerBuilder
          builder={builder}
          builderDocumentId={builder.builderDocuments[0].id}
        />
      )}
    </SideNav>
  );
};

export default BuilderWrapper;
