import {
    DecoratorNode,
    LexicalEditor,
    NodeKey,
    SerializedLexicalNode,
} from 'lexical';
import * as React from 'react';
import KeyTermComponent from './KeyTermComponent';

type SerializedKeyTermNode = SerializedLexicalNode & {
    identifier: string;
    name: string;
    type: 'key-term';
    version: 1;
};

export class KeyTermNode extends DecoratorNode<React.ReactElement> {
    __identifier: string;
    __name: string;
    __shouldHighlight: boolean;

    static getType(): string {
        return 'key-term';
    }

    static clone(node: KeyTermNode): KeyTermNode {
        return new KeyTermNode(node.__identifier, node.__name, node.__key);
    }

    constructor(identifier: string, name: string, key?: NodeKey) {
        super(key);
        this.__identifier = identifier;
        this.__name = name;
        this.__shouldHighlight = false;
    }

    createDOM(): HTMLElement {
        return document.createElement('span');
    }

    updateDOM(): false {
        return false;
    }

    decorate(editor: LexicalEditor, config: any): React.ReactElement {
        return (
            <KeyTermComponent 
                editor={editor}
                identifier={this.__identifier}
                name={this.__name} 
                shouldHighlight={this.__shouldHighlight}
            />
        );
    }

    static importJSON(serializedNode: SerializedKeyTermNode): KeyTermNode {
        const node = new KeyTermNode(serializedNode.identifier, serializedNode.name);
        return node;
    }

    exportJSON(): SerializedKeyTermNode {
        return {
            identifier: this.__identifier,
            name: this.__name,
            type: 'key-term',
            version: 1,
        };
    }
}

function $createKeyTermNode(identifier: string, name: string): KeyTermNode {
    return new KeyTermNode(identifier, name);
}

function $isKeyTermNode(node: any): node is KeyTermNode {
    return node instanceof KeyTermNode;
}

export { $createKeyTermNode, $isKeyTermNode };
