import React, { useState } from "react";
import { SimpleModalWrapper } from "../../../dialog/wrappers/simpleModalWrapper";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ReportSectionUpdatePayload } from '../../nodes/ReportSectionNode';
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../themes/theme";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { EditorState } from "lexical";
import { useReportState } from "../../../../containers/TakerDocumentState/ReportState";
import { UpdateReportSectionCommandPayload } from "../common";
import { ArrowForward } from "@mui/icons-material";
import AiGeneratedResponse from "./AiGeneratedResponse";
import { InnerReportSectionNode } from "../../nodes/InnerReportSectionNode";

import "./UpdateReportSectionModal.css"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import TableCellResizerPlugin from "../../wrappers/RichTextEditor/TableCellResizer";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "../../wrappers/RichTextEditor/ToolbarPlugin";
import TableCellActionMenuPlugin from "../../wrappers/RichTextEditor/TableActionMenuPlugin";
import { KeyTermNode } from "../../nodes/KeyTermNode";

interface Props {
    readOnly: boolean;
    commandPayload?: UpdateReportSectionCommandPayload;
    onClose: () => void;
    targetReportSectionNode: ReportSectionUpdatePayload;
}

function UpdateReportSectionModal({
    readOnly,
    commandPayload,
    onClose,
    targetReportSectionNode
}: Props) {
    const { upsertEditLexical } = useReportState();
    const [localEditorState, setLocalEditorState] = useState<null | EditorState>(null);
    const [loading, setLoading] = useState(false);

    const SUPPORTED_NODES = [
        HeadingNode,
        ListNode,
        ListItemNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        InnerReportSectionNode,
        KeyTermNode
    ];

    const buttonElements = [];
    if (!!commandPayload?.basicEdit) {
        buttonElements.push(
            <Button
                variant="contained"
                onClick={() => {
                    const edited = !!localEditorState ? JSON.stringify(localEditorState) : targetReportSectionNode.editLexical;
                    upsertEditLexical(
                        targetReportSectionNode.editIdentifier,
                        edited,
                        targetReportSectionNode.sectionHash
                    );
                    onClose();
                }}
            >
                Save
            </Button>
        );
    }
    if (!!commandPayload?.resync) {
        buttonElements.push(
            <Button
                variant="contained"
                onClick={() => {
                    upsertEditLexical(
                        targetReportSectionNode.editIdentifier,
                        targetReportSectionNode.editLexical,
                        targetReportSectionNode.sectionHash,
                        true
                    );
                    onClose();
                }}
            >
                Keep Current
            </Button>
        );
        buttonElements.push(
            <Button
                variant="contained"
                onClick={() => {
                    const edited = !!localEditorState ? JSON.stringify(localEditorState) : null;
                    upsertEditLexical(
                        targetReportSectionNode.editIdentifier,
                        edited,
                        targetReportSectionNode.sectionHash,
                        true
                    );
                    onClose();
                }}
            >
                Accept Incoming
            </Button>
        );
    }
    buttonElements.push(
        <Button
            variant="outlined"
            onClick={() => {
                onClose();
            }}
        >
            Cancel
        </Button>
    );


    return (
        <SimpleModalWrapper
            headerText="Report Section"
            open={!!commandPayload}
            handleClose={() => onClose()}
            maxWidth='md'
            buttonElements={buttonElements}
        >
            <Box
                padding={1}
                data-testid="report-section-update-modal"
            >
                {targetReportSectionNode && !!commandPayload?.basicEdit && (
                    <LexicalComposer
                        initialConfig={{
                            editable: !readOnly && !loading,
                            namespace: "edit-report-section",
                            theme: EditorThemeClasses,
                            onError(error: any) {
                                console.error(error);
                            },
                            editorState: (editor) => {
                                const editorState = editor.parseEditorState(targetReportSectionNode.editLexical);
                                if (!editorState.isEmpty()) {
                                    editor.setEditorState(editorState);
                                }
                            },
                            nodes: SUPPORTED_NODES
                        }}
                    >

                        <ToolbarPlugin
                            hideAlign
                            hideFormat
                            hideInsert
                            extraToolbarElement={(
                                <AiGeneratedResponse
                                    editIdentifier={targetReportSectionNode.editIdentifier}
                                    onSetLoading={(l) => {
                                        setLoading(l)
                                    }}
                                />
                            )}
                        />
                        <RichTextPlugin
                            contentEditable={
                                <Box
                                    height="100%"
                                    padding={`calc(2% + ${loading ? "0px" : "2px"})`}
                                    width="100%"
                                    overflow="auto"
                                    sx={{
                                        borderWidth: loading ? "3px" : "1px",
                                        borderStyle: "solid",
                                        borderColor: "#ddd"
                                    }}
                                    className={loading ? "gradient-border" : ""}
                                >
                                    <ContentEditable className="editor-input" />
                                </Box>
                            }
                            placeholder={<div className="editor-placeholder"></div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <ListPlugin />
                        <OnChangePlugin
                            onChange={(editorState, editor) => setLocalEditorState(editorState)} />
                        <HistoryPlugin />
                        <AutoFocusPlugin />
                        <TabIndentationPlugin />
                        <HorizontalRulePlugin />
                        <ClearEditorPlugin />
                        <TablePlugin />
                    </LexicalComposer>
                )}
                {targetReportSectionNode && !!commandPayload?.resync && (
                    <Stack
                        direction="row"
                        width="100%"
                        divider={
                            <Box padding={1} alignContent="center">
                                <ArrowForward />
                            </Box>
                        }
                    >
                        <LexicalComposer
                            initialConfig={{
                                editable: false,
                                namespace: "edit-report-section",
                                theme: EditorThemeClasses,
                                onError(error: any) {
                                    console.error(error);
                                },
                                editorState: (editor) => {
                                    const editorState = editor.parseEditorState(targetReportSectionNode.editLexical);
                                    if (!editorState.isEmpty()) {
                                        editor.setEditorState(editorState);
                                    }
                                },
                                nodes: SUPPORTED_NODES
                            }}
                        >
                            <Stack>
                                <Typography variant="subtitle1" textAlign="center">
                                    Current
                                </Typography>
                                <Box
                                    border="1px solid #ddd"
                                    borderRadius={2}
                                    height="100%"
                                    padding="2%"
                                    overflow="auto"
                                >
                                    <ToolbarPlugin />
                                    <RichTextPlugin
                                        contentEditable={
                                            <ContentEditable
                                                className="editor-input"
                                            />
                                        }
                                        placeholder={<div className="editor-placeholder"></div>}
                                        ErrorBoundary={LexicalErrorBoundary}
                                    />
                                    <ListPlugin />
                                    <HistoryPlugin />
                                    <TablePlugin />
                                </Box>
                            </Stack>
                        </LexicalComposer>
                        <LexicalComposer
                            initialConfig={{
                                editable: !readOnly,
                                namespace: "edit-report-section",
                                theme: EditorThemeClasses,
                                onError(error: any) {
                                    console.error(error);
                                },
                                editorState: (editor) => {
                                    const editorState = editor.parseEditorState(targetReportSectionNode.ogLexical);
                                    if (!editorState.isEmpty()) {
                                        editor.setEditorState(editorState);
                                    }
                                },
                                nodes: SUPPORTED_NODES
                            }}
                        >
                            <Stack>
                                <Typography variant="subtitle1" textAlign="center">
                                    Incoming
                                </Typography>
                                <Box
                                    border="1px solid #ddd"
                                    borderRadius={2}
                                    height="100%"
                                    padding="2%"
                                    overflow="auto"
                                >
                                    <RichTextPlugin
                                        contentEditable={
                                            <ContentEditable
                                                className="editor-input"
                                            />
                                        }
                                        placeholder={<div className="editor-placeholder"></div>}
                                        ErrorBoundary={LexicalErrorBoundary}
                                    />
                                    <ListPlugin />
                                    <TablePlugin />
                                    <OnChangePlugin
                                        onChange={(editorState, editor) => setLocalEditorState(editorState)} />
                                </Box>
                            </Stack>
                        </LexicalComposer>
                    </Stack>
                )}
                {!targetReportSectionNode && (
                    <Box>Nothing to edit</Box>
                )}
            </Box>
        </SimpleModalWrapper>
    );
}

export default UpdateReportSectionModal;
