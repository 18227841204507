import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import {
    Chip,
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    Stack,
    FormControl,
    InputLabel,
    FormControlLabel,
    Switch
} from '@mui/material';
import {
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    GridSelection,
    KEY_DELETE_COMMAND,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    NodeSelection,
    RangeSelection,
    SELECTION_CHANGE_COMMAND,
    SerializedLexicalNode,
    Spread
} from 'lexical';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
    $getNodeByKey,
    $getSelection,
    $isNodeSelection,
    $applyNodeReplacement,
    DecoratorNode
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SimpleModalWrapper } from '../../dialog/wrappers/simpleModalWrapper';
import { INSERT_PRAXI_TEMPATE_COMMAND } from '../plugins/PraxiTemplatePlugin';
import { Abc, Note, Notes } from '@mui/icons-material';


export interface PraxiTemplatePayload {
    praxiTemplateType: string;
    praxiArgs: Array<any>;
}

interface PraxiTemplateNodeComponentProps {
    nodeKey: NodeKey;
    praxiTemplateType: string;
    praxiArgs: Array<any>;
}

interface UpdatePraxiTemplateDialogProps {
    activeEditor: LexicalEditor;
    nodeKey: NodeKey;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CreatePraxiTemplateDialogProps {
    editor: LexicalEditor;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function convertElement(domNode: Node): null | DOMConversionOutput {
    return null;
}

export type SerializedPraxiTemplateNode = Spread<
    {
        praxiTemplateType: string,
        praxiArgs: Array<any>,
        type: "praxi-template",
        version: number
    },
    SerializedLexicalNode
>;

const PRAXI_TEMPLATE_OPTIONS = [
    { label: "Variable", value: "variable" },
    { label: "Summary", value: "summary" },
    { label: "Key Terms", value: "key_terms" },
    { label: "Documents", value: "documents" },
    { label: "Report Outline", value: "report_outline" },
    { label: "Report Expansion", value: "report_expansion" },
];

const CITATION_OPTIONS = [
    { label: "Table", value: "table" },
    { label: "Text", value: "text" }
];

const LOCATION_IMPORT_OPTIONS = [
    { label: "Top", value: "top" },
    { label: "Bottom", value: "bottom" }
];

export function CreatePraxiTemplateDialog({
    editor,
    open,
    setOpen
}: CreatePraxiTemplateDialogProps): JSX.Element {
    const [praxiTemplateType, setPraxiTemplateType] = useState<string>(PRAXI_TEMPLATE_OPTIONS[0].value);
    const [praxiArgs, setPraxiArgs] = useState<Array<string | null>>([]);

    useEffect(() => {
        setPraxiArgs([]);
    }, [praxiTemplateType]);

    const handleOnConfirm = () => {
        editor.dispatchCommand(INSERT_PRAXI_TEMPATE_COMMAND, {
            praxiTemplateType,
            praxiArgs
        });
        setOpen(false);
    };

    const setArg = (i: number, value: string | null) => {
        let newArgs = [];
        for (let j = 0; j < Math.max(praxiArgs.length, (i + 1)); j++) {
            newArgs.push(praxiArgs[j] || "");
        }
        newArgs[i] = value;
        setPraxiArgs(newArgs);
    };

    return (
        <SimpleModalWrapper
            headerText={`Create Template`}
            open={open}
            handleClose={() => setOpen(false)}
            maxWidth='md'
        >
            <Box sx={{ paddingTop: "10px" }}>
                <Box
                    sx={{
                        paddingTop: "10px",
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel id="praxi-template-type-label">
                                Template Type
                            </InputLabel>
                            <Select
                                label="Template Type"
                                labelId="praxi-template-type-label"
                                value={praxiTemplateType}
                                placeholder="select a template type"
                                onChange={(e) => {
                                    setPraxiTemplateType(e.target.value)
                                }}
                            >
                                {PRAXI_TEMPLATE_OPTIONS.map(({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {(praxiTemplateType === "variable") && (
                            <FormControl fullWidth>
                                <TextField
                                    id="variable-name"
                                    label="Variable Name"
                                    placeholder="enter a variable name"
                                    value={praxiArgs[0]}
                                    onChange={(e) => {
                                        let varName = e.target.value;
                                        if (varName) {
                                            setPraxiArgs([varName]);
                                        } else {
                                            setPraxiArgs([])
                                        }
                                    }}
                                />
                            </FormControl>
                        )}
                        {(praxiTemplateType === "report_outline") && [
                            <FormControl fullWidth>
                                <TextField
                                    id="report-depth"
                                    label="Report Depth"
                                    placeholder="select a report depth"
                                    type="number"
                                    value={praxiArgs[0]}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => setArg(0, e.target.value)}
                                    helperText="set to -1 to disable maximum depth"
                                />
                            </FormControl>
                        ]}
                        {(praxiTemplateType === "report_expansion") && [
                            <FormControl fullWidth>
                                <InputLabel id="citation-format-label">
                                    Citation Format
                                </InputLabel>
                                <Select
                                    labelId="citation-format-label"
                                    value={praxiArgs[2] || null}
                                    label="Citation Format"
                                    placeholder="select a format"
                                    onChange={(e) => {
                                        let citationFormat = e.target.value;
                                        setArg(1, citationFormat);
                                    }}
                                >
                                    {CITATION_OPTIONS.map(({ label, value }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>,
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={praxiArgs[3] === "true"}
                                        onChange={(e) => {
                                            let repeatCitations = e.target.checked;
                                            setArg(2, (repeatCitations ? "true" : "false"));
                                        }}
                                    />
                                }
                                label="Repeat Citations"
                            />,
                            <FormControl fullWidth>
                                <InputLabel id="expand-analysis-table-location-import-label">
                                    Expanded Analysis Table Location Import
                                </InputLabel>
                                <Select
                                    labelId="citation-format-label"
                                    value={praxiArgs[4] || null}
                                    label="Expanded Analysis Table Location Import"
                                    placeholder="select a format"
                                    onChange={(e) => {
                                        let locationImport = e.target.value;
                                        setArg(3, locationImport);
                                    }}
                                >
                                    {LOCATION_IMPORT_OPTIONS.map(({ label, value }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>,
                            <FormControl fullWidth>
                                <InputLabel id="citation-format-label">
                                    Expanded Conclusion Table Location Import
                                </InputLabel>
                                <Select
                                    labelId="citation-format-label"
                                    value={praxiArgs[5] || null}
                                    label="Expanded Conclusion Table Location Import"
                                    placeholder="select a format"
                                    onChange={(e) => {
                                        let locationImport = e.target.value;
                                        setArg(4, locationImport);
                                    }}
                                >
                                    {LOCATION_IMPORT_OPTIONS.map(({ label, value }) => (
                                        <MenuItem value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ]}
                    </Stack>
                </Box>
                <Box
                    sx={{
                        paddingTop: "10px"
                    }}
                >
                    <Button
                        data-testid="create-praxi-template-button"
                        variant="contained"
                        type="submit"
                        onClick={() => handleOnConfirm()}
                    >
                        Create
                    </Button>
                    <Button
                        data-testid="cancel-praxi-template-button"
                        sx={{ marginLeft: "10px" }}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </SimpleModalWrapper>
    );
}

export function UpdatePraxiTemplateDialog({
    activeEditor,
    nodeKey,
    open,
    setOpen
}: UpdatePraxiTemplateDialogProps): JSX.Element {
    const editorState = activeEditor.getEditorState();
    const node = editorState.read(
        () => $getNodeByKey(nodeKey) as PraxiTemplateNode,
    );
    const praxiTemplateType = node.__praxiTemplateType;
    const [praxiArgs, setPraxiArgs] = useState(node.__praxiArgs);

    const handleOnConfirm = () => {
        const payload = { praxiTemplateType, praxiArgs };
        if (node) {
            activeEditor.update(() => {
                node.update(payload);
            });
        }
        setOpen(false);
    };

    const setArg = (i: number, value: string | null) => {
        let newArgs = [...praxiArgs];
        for (let j = 0; j < Math.max(praxiArgs.length, (i + 1)); j++) {
            newArgs.push(praxiArgs[i] || "");
        }
        newArgs[i] = value;
        setPraxiArgs(newArgs);
    };

    return (
        <SimpleModalWrapper
            headerText={`Update ${praxiTemplateType}`}
            open={open}
            handleClose={() => setOpen(false)}
            maxWidth='sm'
        >
            <Box sx={{ paddingTop: "10px" }}>
                <Box
                    sx={{
                        paddingTop: "10px",
                    }}
                >
                    {(praxiTemplateType === "variable") && (
                        <FormControl fullWidth>
                            <TextField
                                data-testid="variable-name-input"
                                label="Variable Name"
                                placeholder="enter a variable name"
                                value={praxiArgs[0]}
                                onChange={(e) => {
                                    setPraxiArgs([e.target.value]);
                                }}
                            />
                        </FormControl>
                    )}
                    {(praxiTemplateType === "report_outline") && [
                        <FormControl fullWidth>
                            <TextField
                                data-testid="report-depth-input"
                                label="Report Depth"
                                placeholder="select a report depth"
                                type="number"
                                value={praxiArgs[0]}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                onChange={(e) => setArg(0, e.target.value)}
                                helperText="set to -1 to disable maximum depth"
                            />
                        </FormControl>
                    ]}
                    {(praxiTemplateType === "report_expansion") && [
                        <FormControl fullWidth>
                            <InputLabel id="citation-format-label">
                                Citation Format
                            </InputLabel>
                            <Select
                                data-testid="citation-format-select"
                                labelId="citation-format-label"
                                value={praxiArgs[2] || null}
                                label="Citation Format"
                                placeholder="select a format"
                                onChange={(e) => {
                                    let citationFormat = e.target.value;
                                    setArg(2, citationFormat);
                                }}
                            >
                                {CITATION_OPTIONS.map(({ label, value }) => (
                                    <MenuItem value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>,
                        <FormControlLabel
                            control={
                                <Switch
                                    data-testid="repeat-citations-switch"  
                                    checked={praxiArgs[3] === "true"}
                                    onChange={(e) => {
                                        let repeatCitations = e.target.checked;
                                        setArg(3, (repeatCitations ? "true" : "false"));
                                    }}
                                />
                            }
                            label="Repeat Citations"
                        />,
                        <FormControl fullWidth>
                            <InputLabel id="expand-analysis-table-location-import-label">
                                Expanded Analysis Table Location Import
                            </InputLabel>
                            <Select
                                labelId="citation-format-label"
                                value={praxiArgs[4] || null}
                                label="Expanded Analysis Table Location Import"
                                placeholder="select a format"
                                onChange={(e) => {
                                    let locationImport = e.target.value;
                                    setArg(4, locationImport);
                                }}
                            >
                                {LOCATION_IMPORT_OPTIONS.map(({ label, value }) => (
                                    <MenuItem value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>,
                        <FormControl fullWidth>
                            <InputLabel id="citation-format-label">
                                Expanded Conclusion Table Location Import
                            </InputLabel>
                            <Select
                                labelId="citation-format-label"
                                value={praxiArgs[5] || null}
                                label="Expanded Conclusion Table Location Import"
                                placeholder="select a format"
                                onChange={(e) => {
                                    let locationImport = e.target.value;
                                    setArg(5, locationImport);
                                }}
                            >
                                {LOCATION_IMPORT_OPTIONS.map(({ label, value }) => (
                                    <MenuItem value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ]}
                </Box>
                <Box
                    sx={{
                        paddingTop: "10px"
                    }}
                >
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={() => handleOnConfirm()}
                    >
                        Save
                    </Button>
                    <Button
                        sx={{ marginLeft: "10px" }}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </SimpleModalWrapper>
    );
}

export default function PraxiTemplateNodeComponent({
    nodeKey,
    praxiTemplateType,
    praxiArgs
}: PraxiTemplateNodeComponentProps): JSX.Element {
    const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
    const [editor] = useLexicalComposerContext();
    const [selection, setSelection] = useState<
        RangeSelection | NodeSelection | GridSelection | null
    >(null);
    const activeEditorRef = useRef<LexicalEditor | null>(null);
    const [updatePraxiTemplateModalOpen, setUpdatePraxiTemplateModalOpen] = useState(false);

    const onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (isSelected && $isNodeSelection($getSelection())) {
                const event: KeyboardEvent = payload;
                event.preventDefault();
                const node = $getNodeByKey(nodeKey);
                if ($isPraxiTemplateNode(node)) {
                    node.remove();
                }
            }
            return false;
        },
        [isSelected, nodeKey],
    );

    const onEnter = useCallback(
        (event: KeyboardEvent) => {
            const latestSelection = $getSelection();
            if (
                isSelected &&
                $isNodeSelection(latestSelection) &&
                latestSelection.getNodes().length === 1
            ) {
                event.preventDefault();
                return true;
            }
            return false;
        },
        [isSelected],
    );

    useEffect(() => {
        let isMounted = true;
        const unregister = mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                if (isMounted) {
                    setSelection(editorState.read(() => $getSelection()));
                }
            }),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                onDelete,
                COMMAND_PRIORITY_LOW,
            ),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_, activeEditor) => {
                    activeEditorRef.current = activeEditor;
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            )
        );
        return () => {
            isMounted = false;
            unregister();
        };
    }, [
        clearSelection,
        editor,
        isSelected,
        nodeKey,
        onDelete,
        onEnter,
        setSelected,
    ]);

    const getLabelForValue = (v: string, options: Array<any>) => {
        for (let option of options) {
            if (option.value === v) {
                return option.label;
            }
        }
    }

    return (
        <>
            <Chip
                size="small"
                color="info"
                variant="outlined"

                label={
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex"
                        }}
                    >
                        <>
                            {(praxiTemplateType === "variable") && (
                                <Abc fontSize="small" />
                            )}
                            {(praxiTemplateType === "report" || praxiTemplateType === "summary" || praxiTemplateType === "documents") && (
                                <Notes fontSize="small" />
                            )}
                        </>
                        <span style={{ paddingLeft: "2px" }}>
                            <strong>
                                {getLabelForValue(praxiTemplateType, PRAXI_TEMPLATE_OPTIONS)}
                            </strong>
                        </span>
                        {(praxiTemplateType === "variable") && (
                            <span style={{ paddingLeft: "5px" }}>
                                {praxiArgs[0]}
                            </span>
                        )}
                        {(praxiTemplateType === "report_outline") && (
                            <span style={{ paddingLeft: "5px" }}>
                                ...
                            </span>
                        )}
                        {(praxiTemplateType === "report_expansion") && (
                            <span style={{ paddingLeft: "5px" }}>
                                ...
                            </span>
                        )}
                    </div>
                }
                onClick={() => {
                    setUpdatePraxiTemplateModalOpen(true);
                }}
            />
            <UpdatePraxiTemplateDialog
                activeEditor={editor}
                nodeKey={nodeKey}
                open={updatePraxiTemplateModalOpen}
                setOpen={setUpdatePraxiTemplateModalOpen}
            />
        </>
    );
}

export class PraxiTemplateNode extends DecoratorNode<JSX.Element> {
    __praxiTemplateType: "variable " | string;
    __praxiArgs: [] | Array<any>;

    static getType(): string {
        return "praxi-template";
    }

    static clone(node: PraxiTemplateNode): PraxiTemplateNode {
        return new PraxiTemplateNode(
            node.__praxiTemplateType,
            node.__praxiArgs
        );
    }

    static importJSON(serializedNode: SerializedPraxiTemplateNode): PraxiTemplateNode {
        const { praxiTemplateType, praxiArgs } = serializedNode;
        const node = $createPraxiTemplateNode({
            praxiTemplateType,
            praxiArgs
        });
        return node;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('div');
        element.setAttribute('description', "cannot render");
        return { element };
    }

    static importDOM(): DOMConversionMap | null {
        return {
            img: (node: Node) => ({
                conversion: convertElement,
                priority: 0,
            }),
        };
    }

    constructor(
        praxiTemplateType: string,
        praxiArgs: Array<any>,
        key?: NodeKey
    ) {
        super(key);
        this.__praxiTemplateType = praxiTemplateType;
        this.__praxiArgs = praxiArgs;
    }

    exportJSON(): SerializedPraxiTemplateNode {
        return {
            praxiTemplateType: this.__praxiTemplateType,
            praxiArgs: this.__praxiArgs,
            type: "praxi-template",
            version: 1
        };
    }

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        return span;
    }

    updateDOM(): false {
        return false;
    }

    decorate(): JSX.Element {
        return (
            <Suspense fallback={null}>
                <PraxiTemplateNodeComponent
                    nodeKey={this.getKey()}
                    praxiTemplateType={this.__praxiTemplateType}
                    praxiArgs={this.__praxiArgs}
                />
            </Suspense>
        );
    }
}

export function $createPraxiTemplateNode({
    praxiTemplateType,
    praxiArgs
}: PraxiTemplatePayload): PraxiTemplateNode {
    return $applyNodeReplacement(
        new PraxiTemplateNode(
            praxiTemplateType,
            praxiArgs,
        ),
    );
}

export function $isPraxiTemplateNode(node: LexicalNode | null | undefined,): node is PraxiTemplateNode {
    return node instanceof PraxiTemplateNode;
}