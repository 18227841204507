import { authAction } from "../actions/authActions";
import { AuthActionType } from "../models/authTypes";
import { AuthState } from "../models/authTypes";
import { User } from "../models/dataModelTypes";

const initialState: AuthState = {
  user: null,
  isLoading: false,
  loaded: false,
  error: null,
  authenticated: false,
};

const authReducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case authAction.SET_USER_REQUEST:
      return {
        user: null,
        isLoading: true,
        loaded: false,
        error: null,
        authenticated: false,
      };
    case authAction.SET_USER_SUCESS:
      return {
        user: action.payload as User,
        isLoading: false,
        loaded: true,
        error: null,
        authenticated: true,
      };
    case authAction.SET_USER_FAILURE:
      return {
        user: null,
        isLoading: false,
        loaded: true,
        error: action.payload,
        authenticated: false,
      };
    case authAction.SIGN_OUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        loaded: false,
        error: null,
      };
    case authAction.SIGN_OUT_SUCESS:
      return {
        user: null,
        isLoading: false,
        loaded: true,
        error: null,
        authenticated: false,
      };
    case authAction.SIGN_OUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        loaded: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
