import { useState } from "react";
import SideNav from "../../components/navigation/SideNav";
import { Box, Typography, Fab } from "@mui/material/";
import { PlusIcon } from "./../../assets/icons";
import { CreateUserModal } from "./createUserModal";
import { UserTable } from "./userTable";


const ManageUsers = () => {
  const [createUserToggle, setCreateUserToggle] = useState(false);
  return (
    <SideNav>
      <Box
        sx={{
          padding: 2, 
          width: "100%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              User Panel
            </Typography>
          </Box>
          <Fab
            variant="extended"
            color="primary"
            size="large"
            sx={{ fontWeight: "bold" }}
            component="button"
            onClick={(e: any) => setCreateUserToggle(true)}
          >
            <PlusIcon sx={{ mr: 1 }} />
            Create User
          </Fab>
        </Box>
      </Box>
      <Box 
        sx={{ 
          width: "100%",
          padding: 2, 
        }}
      >
        <UserTable/>
      </Box>
      <CreateUserModal open={createUserToggle} setOpen={setCreateUserToggle} />
    </SideNav>
  );
};

export default ManageUsers;
