import React from "react";
import { RJSFSchema } from '@rjsf/utils';
import { Report } from "../../../types/builderv2.generated";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import ReportTemplateEditor from "./reportTemplate";
import ReportModifiersEditor from "./reportModifiers";

interface ReportsInternalProps {
    fullSchema: RJSFSchema;
    report: Report;
    updateReport: (r: Report) => void;
}

const ReportsInternal = ({
    fullSchema,
    report,
    updateReport
}: ReportsInternalProps) => {
    return (
        <Accordion>
            <AccordionSummary
                aria-controls="variables-content"
                id="variables-header"
            >
                {report.reportId}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12} padding={1}>
                        <ReportTemplateEditor
                            initialReportTemplates={report?.templates}
                            fullSchema={fullSchema}
                            onUpdate={(rts) => {
                                const newReport = JSON.parse(JSON.stringify(report)) as Report;
                                newReport.templates = rts;
                                updateReport(newReport)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} padding={1}>
                        <ReportModifiersEditor
                            initialReportModifiers={report?.modifiers}
                            fullSchema={fullSchema}
                            onUpdate={(rms) => {
                                const newReport = JSON.parse(JSON.stringify(report)) as Report;
                                newReport.modifiers = rms;
                                updateReport(newReport)
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

interface ReportsProps {
    fullSchema: RJSFSchema;
    initialReports: Report[];
    onUpdate: (r: Report[]) => void;
}

const Reports = ({
    fullSchema,
    initialReports,
    onUpdate
}: ReportsProps) => {

    const updateReport = (reportToUpdate: Report) => {
        const newReports = [];
        for (const r of initialReports) {
            if (r.reportId === reportToUpdate.reportId) {
                newReports.push(reportToUpdate);
            } else {
                newReports.push(JSON.parse(JSON.stringify(r)));
            }
        }
        onUpdate(newReports);
    };

    return (
        <Grid container rowGap={1}>
            {initialReports.map(r => (
                <Grid item xs={12}>
                    <ReportsInternal
                        fullSchema={fullSchema}
                        report={r}
                        updateReport={updateReport}
                    />

                </Grid>
            ))}
        </Grid>
    );
}

export default Reports;
