import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { pageOption } from "./../../data/pages";
import DefaultLoadingScreen from "../loading/defaultLoadingScreen";
import UserScopedAppDataContainer from "../../containers/UserScopedAppData/UserScopedAppData";

const Auth0GatedRoutes = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/");
    }
  }, [isAuthenticated, isLoading]);

  if (!user || isLoading) {
    return <DefaultLoadingScreen />
  }
  const shouldAllow = isAuthenticated;

  return shouldAllow ? (
    <UserScopedAppDataContainer>
      <Outlet />
    </UserScopedAppDataContainer>
  ) : (
    <Navigate to={pageOption.SIGN_IN.route} />
  );
}

export default Auth0GatedRoutes;