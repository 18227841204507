import React from "react";
import { Typography, Box, Button } from "@mui/material/";
import { SimpleModalWrapper } from "../wrappers/simpleModalWrapper";

export interface SimpleConfirmModalProps {
  open: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  handleSubmit: React.MouseEventHandler<HTMLButtonElement>;
  mainText: string;
  headerText?: string;
  mainTextStyles?: React.CSSProperties;
}

export const SimpleConfirmModal = (props: SimpleConfirmModalProps) => {
  const { handleClose, open, headerText, mainText, mainTextStyles, handleSubmit } = props;
  return (
    <SimpleModalWrapper
      handleClose={handleClose}
      open={open}
      headerText={headerText ? headerText : "Confirmation"}
      maxWidth="xs"
    >
      <>
        <Box sx={{ padding: "15px 0px 15px 0px" }}>
          <Typography
            sx={mainTextStyles ? mainTextStyles : { fontSize: "1rem", color: "rgba(0, 0, 0, 0.6)" }}
          >
            {mainText}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button disableElevation onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ marginLeft: "10px" }} disableElevation data-testid="submit-modal" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </>
    </SimpleModalWrapper>
  );
};
