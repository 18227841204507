import { type XYPosition } from 'reactflow';
import type { ControlPointData } from '../ControlPoint';

import { getLinearPath, getLinearControlPoints } from './linear';

export function getControlPoints(points: (ControlPointData | XYPosition)[]) {
  return getLinearControlPoints(points);
}

export function getPath(points: XYPosition[]) {
  return getLinearPath(points);
}
