import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Undo,
  Redo,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify
} from '@mui/icons-material';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  LexicalEditor
} from "lexical";
import {
  INSERT_TABLE_COMMAND
} from "@lexical/table";
import {
  $wrapNodes,
  $getSelectionStyleValueForProperty,
  $patchStyleText
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import {
  $createHeadingNode,
  $isHeadingNode
} from "@lexical/rich-text";
import { 
  Button, 
  Divider, 
  IconButton, 
  Menu, 
  MenuItem, 
  Select, 
  Toolbar 
} from "@mui/material";
import { DropDownArrowIcon } from "../../../../assets/icons";
import { CreatePraxiTemplateDialog } from "../../nodes/PraxiTemplateNode";

const LowPriority = 1;

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "h1",
  "h2",
  "ul",
  "ol"
]);

const blockTypeToBlockName: Record<string, string> = {
  h1: "Large Heading",
  h2: "Small Heading",
  h3: "Heading",
  h4: "Heading",
  h5: "Heading",
  ol: "Numbered List",
  paragraph: "Normal",
  ul: "Bulleted List"
};

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['11px', '11px'],
  ['12px', '12px'],
];

interface BlockOptionsDropdownListProps {
    editor: any;
    blockType: any;
}

function BlockOptionsDropdownList({
  editor,
  blockType,
}: BlockOptionsDropdownListProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    handleClose();
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
    handleClose();
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
    handleClose();
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    handleClose();
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    handleClose();
  };

  return (
    <div>
      <Button
        id="block-options-button"
        aria-controls={open ? 'block-options-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<DropDownArrowIcon/>}
        onClick={handleClick}
      >
        {blockTypeToBlockName[blockType]}
      </Button>
      <Menu
        id="block-options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem 
          dense
          selected={blockType === "paragraph"}
          onClick={formatParagraph}>
          Normal
        </MenuItem>
        <MenuItem 
          dense
          selected={blockType === "h1"}
          onClick={formatLargeHeading}>
          Large Heading
        </MenuItem>
        <MenuItem 
          dense
          selected={blockType === "h2"}
          onClick={formatSmallHeading}>
          Small Heading
        </MenuItem>
        <MenuItem 
          dense
          selected={blockType === "ul"}
          onClick={formatBulletList}>
          Bullet List
        </MenuItem>
        <MenuItem 
          dense
          selected={blockType === "ol"}
          onClick={formatNumberedList}>
          Numbered List
        </MenuItem>
      </Menu>
    </div>
  );
}

function FontDropDown({
  editor,
  value,
  style,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  style: string;
  disabled?: boolean;
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [style]: option,
          });
        }
      });
    },
    [editor, style],
  );

  return (
    <Select
      size="small"
      disabled={disabled}
      value={value}
      label={style}
      onChange={(e) => { 
        handleClick(e.target.value)
      }}
    >
      {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(
        ([option, text]) => (
          <MenuItem key={option} value={option}>
            {text}
          </MenuItem>
        ),
      )}
    </Select>
  );
}

export default function MemoTemplateEditorToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  const [blockType, setBlockType] = useState<string>("paragraph");
  const [showCreatePraxiTemplate, setShowCreatePraxiTemplate] = useState<boolean>(false);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const [fontSize, setFontSize] = useState<string>('12px');
  const [fontFamily, setFontFamily] = useState<string>('Georgia');
  
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));

      // Handle buttons
      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '12px'),
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, 'font-family', 'Georgia'),
      );
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <>
      <Toolbar 
        variant="dense"
        ref={toolbarRef}>
        <IconButton
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
          aria-label="Undo"
        >
          <Undo />
        </IconButton>
        <IconButton
          disabled={!canRedo}
          onClick={() => {
            editor.dispatchCommand(REDO_COMMAND, undefined);
          }}
          aria-label="Redo"
        >
          <Redo />
        </IconButton>
        <Divider orientation="vertical" />
        <FontDropDown
          style={'font-family'}
          value={fontFamily}
          editor={editor}
        />
        <FontDropDown
          style={'font-size'}
          value={fontSize}
          editor={editor}
        />
        <Divider />
        <>
          {supportedBlockTypes.has(blockType) && (
            <>
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
              />
              <Divider orientation="vertical" />
            </>
          )}
        </>
        <IconButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
          }}
          className={isBold ? "active" : ""}
          aria-label="Format Bold"
        >
          <FormatBold />
        </IconButton>
        <IconButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
          }}
          className={isItalic ? "active" : ""}
          aria-label="Format Italics"
        >
          <FormatItalic />
        </IconButton>
        <IconButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
          }}
          className={isUnderline ? "active" : ""}
          aria-label="Format Underline"
        >
          <FormatUnderlined />
        </IconButton>
        <Divider orientation="vertical" />
        <IconButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
          }}
          aria-label="Left Align"
        >
          <FormatAlignLeft />
        </IconButton>
        <IconButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
          }}
          aria-label="Center Align"
        >
          <FormatAlignCenter />
        </IconButton>
        <IconButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
          }}
          aria-label="Right Align"
        >
          <FormatAlignRight />
        </IconButton>
        <IconButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
          }}
          aria-label="Justify Align"
        >
          <FormatAlignJustify />
        </IconButton>
        <Divider orientation="vertical" />
        {false && (
          <Button
            size="small"
            onClick={() => {
              editor.dispatchCommand(INSERT_TABLE_COMMAND, {
                rows: '1',
                columns: '1'
              });
            }}
            aria-label="Insert Table"
          >
            Insert Table
          </Button>
        )}
          <Button
            size="small"
            onClick={() => {
              setShowCreatePraxiTemplate(true);
            }}
            aria-label="Insert Praxi Template"
          >
            Insert Praxi Template
          </Button>
      </Toolbar>
      <CreatePraxiTemplateDialog 
        editor={editor}
        open={showCreatePraxiTemplate} 
        setOpen={setShowCreatePraxiTemplate}
      />
    </>
  );
}
