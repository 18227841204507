import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, axiosPostForm } from '../reduxUtils/baseQuery';
import { FileUploadItem } from '../models/dataModelTypes';

export const fileUploadApi = createApi({
    reducerPath: 'fileUploadApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['FileUploadItems'],
    endpoints: (build) => ({
        addFileUploadItem: build.mutation<FileUploadItem, Partial<FileUploadItem>>({
            query(data) {
                let {
                    label,
                    type,
                    fileUploadId,
                    fileMetadata
                } = data;
                return {
                    url: `file_uploads/${fileUploadId}/items`,
                    method: 'POST',
                    data: {
                        label,
                        type,
                        fileMetadata
                    }
                }
            },
            invalidatesTags: (result, error, { id, }) => [
                { type: 'FileUploadItems', id: id }
            ],
        }),
        deleteFileUploadItem: build.mutation<FileUploadItem, Partial<FileUploadItem>>({
            query(data) {
                let {
                    id,
                    fileUploadId
                } = data;
                return {
                    url: `file_uploads/${fileUploadId}/items/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { id, }) => [
                { type: 'FileUploadItems', id: id }
            ],
        }),
        updateFileItem: build.mutation<FileUploadItem, Partial<FileUploadItem>>({
            query(data) {
                let {
                    id,
                    fileUploadId,
                    label,
                } = data;
                return {
                    url: `file_uploads/${fileUploadId}/items/${id}`,
                    method: 'PUT',
                    data: {
                        label
                    }
                }
            },
            invalidatesTags: (result, error, { id, }) => [
                { type: 'FileUploadItems', id: id }
            ],
        }),
    })
});

export const fileContentUploadApi = createApi({
    reducerPath: 'fileContentUploadApi',
    baseQuery: axiosPostForm(),
    tagTypes: [],
    endpoints: (build) => ({
        addFileUploadItemContent: build.mutation<FileUploadItem, Partial<FileUploadItem> & { formData: any }>({
            query(data) {
                let {
                    id,
                    fileUploadId,
                    formData,
                } = data;
                return {
                    url: `file_uploads/${fileUploadId}/items/${id}/content`,
                    formData
                }
            }
        })
    })
});

export const {
    useAddFileUploadItemMutation,
    useDeleteFileUploadItemMutation,
    useUpdateFileItemMutation
} = fileUploadApi;

export const {
    useAddFileUploadItemContentMutation,
} = fileContentUploadApi;