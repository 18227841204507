import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ReportRevision } from "../../../types/taker/reportstate.generated";

interface ReportRevisionRowProps {
    reportRevision: ReportRevision;
}

const ReportRevisionRow = ({
    reportRevision,
}: ReportRevisionRowProps) => {
    return (
        <React.Fragment>
            <TableRow key={reportRevision.revisionId}>
                <TableCell>
                    {(new Date(reportRevision.updatedAt)).toLocaleString()}
                </TableCell>
                <TableCell>
                    {reportRevision.reportEdits.length}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default ReportRevisionRow;