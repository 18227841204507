import {
  Alert,
  AlertTitle,
  Box,
  Button
} from "@mui/material/";
import * as yup from "yup";
import { JsonFieldRhf } from "../../components/form/reactHookForm/jsonFieldRhf";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useUpdateBuilderDocumentMutation } from "../../redux/services/builder";
import { BuilderDocument } from "../../redux/models/dataModelTypes";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

interface formInputs {
  configuration: any;
}

interface Props {
  builderDocument: BuilderDocument;
}

const BuilderEditor = ({ builderDocument }: Props) => {
  const [guidanceErrors, setGuidanceErrors] = useState<string[]>([]);
  const [fulfillmentErrors, setFulfillmentErrors] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [updateBuilderDocument, updateBuilderDocumentResult] = useUpdateBuilderDocumentMutation();

  useEffect(() => {
    if (updateBuilderDocumentResult.isSuccess) {
      enqueueSnackbar("Automation Saved", {
        key: "automation-saved",
        preventDuplicate: true,
        variant: "info",
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      });
    } else if (updateBuilderDocumentResult.isError) {
      let errorMessages = (updateBuilderDocumentResult.error as any)['data'];
      let fErrors = errorMessages['fulfillment_errors'];
      let gErrors = errorMessages['guidance_errors'];
      setFulfillmentErrors(fErrors.map((e: any) => JSON.stringify(e)));
      setGuidanceErrors(gErrors);
      enqueueSnackbar("There were errors in the automation", {
        key: "automation-saved",
        preventDuplicate: true,
        variant: "error",
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      });
    }
  }, [updateBuilderDocumentResult])

  const defaultValues = {
    configuration: JSON.stringify(builderDocument.configuration, null, 4)
  };

  const submitCustom: SubmitHandler<any> = (d) => {
    updateBuilderDocument({
      id: builderDocument.id,
      builderId: builderDocument.builderId,
      configuration: JSON.parse(d.configuration)
    });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<formInputs>({
    resolver: yupResolver(yup.object().shape({
      configuration: yup.string().required()
    })),
    defaultValues: defaultValues,
  });

  return (
    <form
      onSubmit={handleSubmit(submitCustom)}
      style={{
        height: "100%",
      }}
    >
      {guidanceErrors.map((e) => (
        <Alert color="warning">
          {e}
        </Alert>
      ))}
      {fulfillmentErrors.length > 0 && (
        <Alert color="warning">
          <AlertTitle>
            There were errors with fulfillment
          </AlertTitle>
          <Box>
            <ul>
              {fulfillmentErrors.map(fe => (
                <li>{fe}</li>
              ))}
            </ul>
          </Box>
        </Alert>
      )}
      <Box
        sx={{
          height: "90%",
          overflowY: "scroll"
        }}
      >
        <JsonFieldRhf
          label=""
          fieldName="configuration"
          sx={{
            width: "100%"
          }}
          variant="filled"
          control={control}
          errors={errors}
        />
      </Box>
      <Box
        sx={{
          paddingTop: "10px",
          height: "10%"
        }}
      >
        <Button variant="contained" type="submit">
          Save
        </Button>
      </Box>
    </form>
  );
}

export default BuilderEditor;
