import { useState } from "react";
import SideNav from "../../components/navigation/SideNav";
import { Box, Typography, Fab } from "@mui/material/";
import { PlusIcon } from "./../../assets/icons";
import { OrganizationTable } from "./organizationTable";
import { CreateOrganizationModal } from "./createOrganizationModal";


const ManageOrganizations = () => {
    const [createOrganizationToggle, setCreateOrganizationToggle] = useState(false);
    return (
        <SideNav>
            <Box
                sx={{
                    padding: 2,
                    width: "100%",

                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Organization Panel
                        </Typography>
                    </Box>
                    <Fab
                        variant="extended"
                        color="primary"
                        size="large"
                        sx={{ fontWeight: "bold" }}
                        component="button"
                        onClick={(e: any) => setCreateOrganizationToggle(true)}
                    >
                        <PlusIcon sx={{ mr: 1 }} />
                        Create Organization
                    </Fab>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    padding: 2,
                }}
            >
                <OrganizationTable />
            </Box>
            <CreateOrganizationModal
                open={createOrganizationToggle}
                setOpen={setCreateOrganizationToggle}
            />
        </SideNav>
    );
};

export default ManageOrganizations;
