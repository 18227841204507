import { createApi } from '@reduxjs/toolkit/query/react';
import { LanguageModel, LanguageModelConfiguration, LanguageModelConfigurationEntry } from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { PaginatedResponse } from '../models/commonTypes';

export const languageModelApi = createApi({
    reducerPath: 'languageModelApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['LanguageModels'],
    endpoints: (build) => ({
        listLanguageModels: build.query<PaginatedResponse<LanguageModel>, { page: number | void, limit: number | void }>({
            query: ({page = 1, limit = 10}) => ({
                url: `language_models?page=${page}&limit=${limit}`,
                method: "GET"
            }),
            
            // Provides a list of `Posts` by `id`.
            // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
            // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Posts` element was added.
            providesTags: (result) =>
                result
                ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'LanguageModels', id } as const)),
                        { type: 'LanguageModels', id: 'LIST' },
                    ]
                :
                    [{ type: 'LanguageModels', id: 'LIST' }],
        }),
        addLanguageModel: build.mutation<LanguageModel, Partial<LanguageModel>>({
            query(body) {
                return {
                    url: `language_models`,
                    method: 'POST',
                    data: body,
                }
            },
            // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
            // that newly created post could show up in any lists.
            invalidatesTags: [{ type: 'LanguageModels', id: 'LIST' }],
        }),
        getLanguageModel: build.query<LanguageModel, string>({
            query: (id) => ({
                url: `language_models/${id}`,
                method: "GET"
            }),
            providesTags: (result, error, id) => [{ type: 'LanguageModels', id }],
        }),
        updateLanguageModel: build.mutation<LanguageModel, Partial<LanguageModel>>({
            query(data) {
                const { id, ...body } = data
                return {
                    url: `language_models/${id}`,
                    method: 'PUT',
                    data: body
                }
            },
            // Invalidates all queries that subscribe to this Post `id` only.
            // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
            invalidatesTags: (result, error, { id }) => [{ type: 'LanguageModels', id }],
        }),
        deleteLanguageModel: build.mutation<boolean, string>({
            query(id) {
                return {
                    url: `language_models/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'LanguageModels', id }],
        }),
        addLanguageModelConfigurationEntry: build.mutation<LanguageModelConfigurationEntry, {languageModel: LanguageModel, body: Partial<LanguageModelConfigurationEntry>}>({
            query({languageModel, body}) {
                return {
                    url: `language_models/${languageModel.id}/configuration/${languageModel.configuration.id}/entry`,
                    method: 'POST',
                    data: body,
                }
            },
            invalidatesTags: (result, error, { languageModel }) => [
                { type: 'LanguageModels', id: 'LIST' },
                { type: 'LanguageModels', id: languageModel.id }
            ],
        }),
        deleteLanguageModelConfigurationEntry: build.mutation<boolean, { languageModel: LanguageModel, id: string }>({
            query({languageModel, id}) {
                return {
                    url: `language_models/${languageModel.id}/configuration/${languageModel.configuration.id}/entry/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, {languageModel}) => [{ type: 'LanguageModels', id: languageModel.id }],
        }),
        addLanguageModelConfiguration: build.mutation<LanguageModelConfiguration, {languageModel: LanguageModel, body: Partial<LanguageModelConfiguration>}>({
            query({languageModel, body}) {
                return {
                    url: `language_models/${languageModel.id}/configuration`,
                    method: 'POST',
                    data: body,
                }
            },
            invalidatesTags: (result, error, { languageModel }) => [
                { type: 'LanguageModels', id: 'LIST' },
                { type: 'LanguageModels', id: languageModel.id }
            ],
        })
    }),
})

export const {
    useListLanguageModelsQuery,
    useAddLanguageModelMutation,
    useGetLanguageModelQuery,
    useUpdateLanguageModelMutation,
    useDeleteLanguageModelMutation,
    useAddLanguageModelConfigurationMutation,
    useAddLanguageModelConfigurationEntryMutation,
    useDeleteLanguageModelConfigurationEntryMutation,
} = languageModelApi;