import React, { useState } from "react";
import {
  Box,
  Button
} from "@mui/material/";
import * as yup from "yup";
import { TextFieldRhf } from "../../components/form/reactHookForm/textFieldRhf";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "../../redux/models/dataModelTypes";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
});

interface formInputs {
  firstName: string;
  lastName: string;
  email: string;
}

interface UserSettingsFormProps {
  user: User;
  isUpdatingUser: boolean;
  onUpdateUser: (u: Partial<User>) => void;
};

const UserSettingsForm = ({ 
  user,
  isUpdatingUser,
  onUpdateUser
}: UserSettingsFormProps) => {
  const [editing, setEditing] = useState(false);

  const defaultValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<formInputs>({
    resolver: yupResolver(schema),
    defaultValues
  });

  const handleCancel = () => {
    setEditing(!editing);
    reset(defaultValues);
  };

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleEditClick = (e: any) => {
    e.preventDefault();
    toggleEdit();
  };

  const onSubmit = (data: formInputs) => {
    onUpdateUser({
      id: user.id,
      firstName: data.firstName,
      lastName: data.lastName
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <TextFieldRhf
        label="First Name"
        fieldName="firstName"
        disabled={!editing}
        sx={{ minHeight: "71px" }}
        control={control}
        errors={errors}
      />
      <TextFieldRhf
        label="Last Name"
        fieldName="lastName"
        disabled={!editing}
        sx={{ minHeight: "71px" }}
        control={control}
        errors={errors}
      />
      <TextFieldRhf
        label="Email"
        fieldName="email"
        disabled={true}
        sx={{ minHeight: "71px" }}
        control={control}
        errors={errors}
      />
      {editing ? (
        <Box>
          <Button
            variant="contained"
            type="submit"
            disabled={isUpdatingUser}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            type="button"
            sx={{ marginLeft: "20px" }}
            onClick={handleCancel}
            disabled={isUpdatingUser}
          >
            Cancel
          </Button>
        </Box>
      ) : (
        <Box>
          <Button variant="contained" onClick={handleEditClick}>
            edit details
          </Button>
        </Box>
      )}
    </form>
  );
}

export default UserSettingsForm;