import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Application } from "../../redux/models/dataModelTypes";
import PagedTable from '../../components/tables/pagedTable';
import { 
  useListApplicationsQuery,
  useDeleteApplicationTagMutation
} from '../../redux/services/application';
import { UpdateApplicationModal } from './updateApplicationModal';
import DeleteWithConfirmationButton from '../../components/buttons/DeleteWithConfirmationButton';
import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';


export const ApplicationTable = () => {
  const [applicationIdToEdit, setApplicationIdToEdit] = useState<string>();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const { data: applications } = useListApplicationsQuery({ page, limit });
  const [deleteAppTag, deleteAppTagRes] = useDeleteApplicationTagMutation();

  const hasApplicationToEdit = Boolean(applicationIdToEdit);

  const rowMapper = (app: Application) => {
    const hasTag = Boolean(app.tag);
    return (
      <TableRow
        key={app.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {hasTag ? (
            app.tag?.type
          ) : (
            "Archived"
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {app.tag?.data}
        </TableCell>
        <TableCell style={{ width: 160 }} align="right">
          <Box display="flex">
            <IconButton
              disabled={!hasTag}
              color="default"
              onClick={() => {
                setApplicationIdToEdit(app.id);
              }}
              sx={{marginRight: "5px"}}
            >
              <Edit data-testid="edit-application-button" />
            </IconButton>
            <DeleteWithConfirmationButton
              disabled={!hasTag}
              icon={<Delete data-testid="delete-application-button" />}
              onDelete={() => {
                if (app?.tag) {
                  deleteAppTag(app.tag.id);
                }
              }}
              confirmationText="Are you sure you want to delete this application configuration?"
            />
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <PagedTable
        paginatedResponse={applications}
        tableSpan={5}
        rowMapper={rowMapper}
        headers={[
          "Type",
          "Data",
          ""
        ]}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
      {applicationIdToEdit !== undefined && (
        <UpdateApplicationModal
          applicationId={applicationIdToEdit}
          open={hasApplicationToEdit}
          onClose={() => setApplicationIdToEdit(undefined)} />
      )}
    </>
  );
}
