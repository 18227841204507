import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "../../components/navigation/SideNav";
import { Box, Typography, Grid, Toolbar, CircularProgress, Drawer, Button, Tooltip, IconButton, useTheme, Divider } from "@mui/material/";
import MyRecentBuilders from "./myRecentBuilders";
import MyRecentTakers from "./myRecentTakers";
import { Builder, User } from "../../redux/models/dataModelTypes";
import { useAddTakerMutation } from "../../redux/services/taker";
import { useAddBuilderMutation } from "../../redux/services/builder";
import { useUserScopedAppData } from "../../containers/UserScopedAppData/UserScopedAppData";
import SharedTakers from "./sharedTakers";
import { useSelector } from "../../redux/reduxUtils/functions";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { Add, Edit, KeyboardTab } from "@mui/icons-material";

import "./Home.css";

interface ViewBuilderModalProps {
  user: User | null;
  hasBuilderRole?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  builder: Builder | null;
}

export function ViewBuilderModal({
  user,
  hasBuilderRole,
  open,
  setOpen,
  builder
}: ViewBuilderModalProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const [addTaker, addTakerResult] = useAddTakerMutation();

  useEffect(() => {
    if (addTakerResult.isSuccess) {
      navigate(`/mainTaker/${addTakerResult.data.id}/keyTerms`);
    }
  }, [addTakerResult]);

  const createNewTaker = () => {
    if (!builder) {
      return;
    }

    addTaker({
      name: "new taker",
      description: "",
      builderId: builder.id,
      initialBuilderDocumentId: builder.builderDocuments[0].id
    });
  };

  if (!builder) {
    return null;
  }

  const canEditBuilder = (user && builder.ownerUserId === user.id) && hasBuilderRole;
  const builderDocument = builder.builderDocuments[0];
  return (
    <Drawer
      open={open}
      variant="temporary"
      anchor="right"
      onClose={() => setOpen(false)}
      ModalProps={{
        hideBackdrop: true
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#FBFCFE",
        }
      }}
    >
      <Grid
        marginTop="48px"
        maxWidth="50vw"
        container
      >
        <Grid 
          item 
          xs={12}
          sx={{
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <Toolbar
            variant="dense"
            disableGutters
            sx={{
              width: "100%",
              justifyContent: 'space-between',
            }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                component="span"
                variant="h6"
                noWrap
              >
                {builder.name}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-end"
            >
              {canEditBuilder && (
                <Tooltip title={"Edit Workflow"}>
                  <IconButton
                    data-testid={`edit-builder-button-${builder.id}`}
                    disabled={!open}
                    onClick={() => navigate(`/mainBuilder/${builder.id}`)}
                    sx={{ marginRight: "10px" }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={"Close Workflow"}>
                <IconButton
                  data-testid="automation-drawer-close-button"
                  disabled={!open}
                  onClick={() => setOpen(false)}
                >
                  <KeyboardTab />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ padding: 2 }}
          rowSpacing={2}
        >
          <Grid item xs={6}>
            <Typography>
              Status
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {builder.status}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>
              Created
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
            {new Date(builder.createdAt).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Description
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {builder.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid 
          item 
          xs={12}
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
          
        >
          <Typography variant="h6">
              Recent
          </Typography>
          <Button
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                onClick={(e: any) => createNewTaker()}
                sx={{ marginLeft: "10px" }}
              >
                Create New
              </Button>
        </Grid>
        <Grid 
          item 
          xs={12}
          sx={{
            padding: 1
          }}
        >
          <MyRecentTakers builderId={builder.id} />
        </Grid>
      </Grid>
    </Drawer>
  );
}

const Home = () => {
  const [targetBuilder, setTargetBuilder] = useState<Builder | null>(null);
  const [takerModalOpen, setTakerModalOpen] = useState<boolean>(false);
  const { selectedOrgId, flagProvider } = useUserScopedAppData();
  const navigate = useNavigate();
  const [addBuilder, addBuilderResult] = useAddBuilderMutation();
  const {
    authenticated,
    user
  } = useSelector((state: RootReducerType) => state.auth);

  const hasBuilderRole = user?.roles.includes('BUILDER');

  useEffect(() => {
    if (addBuilderResult.isSuccess) {
      navigate(`/mainBuilder/${addBuilderResult.data.id}`);
    }
  }, [addBuilderResult]);

  const createNewBuilder = () => {
    addBuilder({
      name: "new builder",
      description: "",
      organizationId: selectedOrgId
    });
  };

  return (
    <SideNav>
      <Box
        id="homepage"
        sx={{ 
          width: '100%',
          height: '100%',
          overflow: 'auto'
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                width: "100%"
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Workflows
                  </Typography>
                </Box>
                {hasBuilderRole && (
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ fontWeight: "bold" }}
                    onClick={(e: any) => createNewBuilder()}
                  >
                    Create Workflow
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MyRecentBuilders
                hasBuilderRole={hasBuilderRole}
                onOpenBuilder={(bm: Builder) => {
                  setTargetBuilder(bm);
                  setTakerModalOpen(true);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                width: "100%"
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Shared With You
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: 2,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {authenticated ? (
                <SharedTakers />
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Grid>
        </Grid>
        <ViewBuilderModal
          user={user}
          hasBuilderRole={hasBuilderRole}
          open={takerModalOpen}
          setOpen={setTakerModalOpen}
          builder={targetBuilder}
        />
      </Box>
    </SideNav >
  );
}

export default Home;