import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

interface ForceUpdateEditorStatePluginProps {
    newEditorState: string;
}

const ForceUpdateEditorStatePlugin = ({
    newEditorState
}: ForceUpdateEditorStatePluginProps) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (editor && !!newEditorState) {
            try {
                const editorState = editor.parseEditorState(newEditorState);
                editor.setEditorState(editorState);
            } catch (e) {
                //console.log(e);
            }
        }
    }, [newEditorState]);
    return null;
}

export default ForceUpdateEditorStatePlugin;