import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import { RJSFSchema } from '@rjsf/utils';

export const staticFileApi = createApi({
    reducerPath: 'staticFileApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['StaticFiles'],
    endpoints: (build) => ({
        getGaapFileIndex: build.query<Object, void>({
            query: () => ({
                url: `gaap_file_index`,
                method: "GET"
            }),
            providesTags: () => [{ type: 'StaticFiles', id: 'gaap_file_index' }],
        }),
        getGaapMetadataFile: build.query<any, void>({
            query: () => ({
                url: `gaap_file_metadata`,
                method: "GET"
            }),
            providesTags: () => [{ type: 'StaticFiles', id: 'gaap_file_metadata' }],
        }),
        getGaapContentFile: build.query<Object, string>({
            query: (code) => ({
                url: `gaap_content_files/${code}.json`,
                method: "GET"
            }),
            providesTags: (result, error, code) => [{ type: 'StaticFiles', id: `gaap_content_files/${code}.json` }],
        }),
        getFeatureFlagFile: build.query<RJSFSchema, void>({
            query: () => ({
                url: `schemas/feature_flags.schema.json`,
                method: "GET"
            }),
            providesTags: () => [{ type: 'StaticFiles', id: 'feature_flags' }],
        })
    }),
})

export const {
    useGetGaapFileIndexQuery,
    useGetGaapMetadataFileQuery,
    useLazyGetGaapContentFileQuery,
    useGetFeatureFlagFileQuery
} = staticFileApi;