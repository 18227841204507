import React, { CSSProperties, useEffect, useRef } from "react";
import { Controller, FieldErrors } from "react-hook-form";
import { Select, MenuItem, FormControl, Button, FormHelperText } from "@mui/material/";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { InputVariantType, DropDownItem } from "../models/muiTypes";
import { Delete } from "@mui/icons-material";

interface FileUploadWithNameRhfProps {
  label?: string;
  variant?: InputVariantType;
  fullWidth?: boolean;
  fileLabelFieldName: string;
  fileTypeFieldName: string;
  disabled?: boolean;
  sx?: CSSProperties;
  control: any;
  errors: FieldErrors<any>;
  setValue: any;
  onChangeFile: (file: any) => void;
  helperText?: string;
  defaultFile: File | null;
}

const FILE_TYPES: DropDownItem[] = [
  { id: "application/pdf", display: "PDF" },
  { id: "application/zip", display: "ZIP" }
];

export const FileUploadWithNameRhf = ({
  label,
  variant,
  fullWidth,
  fileLabelFieldName,
  fileTypeFieldName,
  disabled,
  control,
  errors,
  setValue,
  onChangeFile,
  helperText,
  defaultFile
}: FileUploadWithNameRhfProps) => {
  const inputRef = useRef<HTMLInputElement | null>();

  const onChangeFileLocal = useRef((event: any) => {
    if (!inputRef.current) {
      return;
    }

    const file = inputRef.current.files && inputRef.current.files.item(0);
    if (file) {
      setValue(fileLabelFieldName, file.name);
      setValue(fileTypeFieldName, file.type);
      onChangeFile(file);
    }
  });

  const clearFile = useRef(() => {
    setValue(fileLabelFieldName, null);
    setValue(fileTypeFieldName, null);
    onChangeFile(null);
  });

  useEffect(() => {
    if (defaultFile) {
      setValue(fileLabelFieldName, defaultFile.name);
      setValue(fileTypeFieldName, defaultFile.type);
    }
  }, [defaultFile]);

  return (
    <>
      <FormControl
        sx={{ display: "inline", paddingBottom: "10px" }}
        disabled={disabled}
      >
        {(!!defaultFile) ? (
          <span style={{ paddingRight: "5px" }}>
            <Button
              data-testid="file-upload-clear"
              color="error"
              startIcon={<Delete />}
              onClick={() => {
                clearFile.current();
              }}
            >
              {defaultFile.name}
            </Button>
          </span>
        ) : (
          <span>
            <input
              accept="*"
              hidden
              id="raised-button-file"
              type="file"
              ref={(r) => { inputRef.current = r }}
              onChange={onChangeFileLocal.current}
            />
            <label htmlFor="raised-button-file">
              <Button
                data-testid="upload-button"
                color="primary"
                startIcon={<FileUploadIcon />}
                component="span">
                Upload {label}
              </Button>
            </label>
          </span>
        )}
        {(!!defaultFile) && (
          <Controller
            name={fileTypeFieldName}
            control={control}
            render={({ field }) => (
              <Select
                data-testid="file-type-select"
                size="small"
                placeholder="File Type"
                disabled={!defaultFile}
                value={field.value}
                onChange={(e: any) => field.onChange(e.target.value)}>
                {FILE_TYPES.map((item: DropDownItem | string) =>
                  typeof item === "string" ? (
                    <MenuItem key={item as string} value={item as string}>
                      {item as string}
                    </MenuItem>
                  ) : (
                    <MenuItem key={(item as DropDownItem).id} value={(item as DropDownItem).id}>
                      {(item as DropDownItem).display}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

interface FileUploadWithNameRhfProps2 {
  label?: string;
  disabled?: boolean;
  onChangeFile: (file: File | null) => void;
  helperText?: string;
  defaultFile: File | null;
  accept?: string;
}

export const FileUploadWithNameRhf2 = ({
  label,
  disabled,
  onChangeFile,
  helperText,
  defaultFile,
  accept = "*"
}: FileUploadWithNameRhfProps2) => {
  const inputRef = useRef<HTMLInputElement | null>();

  const onChangeFileLocal = useRef((event: any) => {
    if (!inputRef.current) {
      return;
    }

    const file = inputRef.current.files && inputRef.current.files.item(0);
    if (file) {
      onChangeFile(file);
    }
  });

  const clearFile = useRef(() => {
    onChangeFile(null);
  });

  return (
    <>
      <FormControl
        sx={{ display: "inline", paddingBottom: "10px" }}
        disabled={disabled}
      >
        {(!!defaultFile) ? (
          <span style={{ paddingRight: "5px" }}>
            <Button
              data-testid="file-upload-clear"
              color="error"
              startIcon={<Delete />}
              onClick={() => {
                clearFile.current();
              }}
            >
              {defaultFile.name}
            </Button>
          </span>
        ) : (
          <span>
            <input
              accept={accept}
              hidden
              id="raised-button-file"
              type="file"
              ref={(r) => { inputRef.current = r }}
              onChange={onChangeFileLocal.current}
            />
            <label htmlFor="raised-button-file">
              <Button
                data-testid="file-upload-button-raised"
                color="primary"
                startIcon={<FileUploadIcon />}
                component="span">
                Upload {label}
              </Button>
            </label>
          </span>
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};
