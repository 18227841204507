import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import React, { useEffect, useMemo } from 'react';
import { ReportSectionNode } from '../../nodes/ReportSectionNode';
import { ReportEdit, ReportRevision } from '../../../../types/taker/reportstate.generated';

interface InjectReportEditsPluginProps {
    reportRevision?: ReportRevision;
}

export default function InjectReportEditsPlugin({
    reportRevision
}: InjectReportEditsPluginProps) {
    const [editor] = useLexicalComposerContext();

    const editByIdentifier: Record<string, ReportEdit> = {};
    if (reportRevision) {
        for (const reportEdit of reportRevision.reportEdits) {
            editByIdentifier[reportEdit.identifier] = reportEdit;
        }
    }
    
    useEffect(() => {
        return mergeRegister(
            editor.registerNodeTransform(ReportSectionNode, (reportSectionNode) => {
                // Synchronize this node's metadata to the outside state.
                const nodeIdentifier = reportSectionNode.buildIdentifier();
                reportSectionNode.__reportEdit = editByIdentifier[nodeIdentifier];
            })
        );
    }, [
        editor,
        editByIdentifier
    ]);

    return null;
}