import React from "react";
import { Tooltip } from "@mui/material/";
import { CircularProgress } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TakerDocumentAnalysis } from "../../../redux/models/dataModelTypes";
import { Check, WarningOutlined } from '@mui/icons-material';

interface GeneratedReportRowProps {
    takerDocumentAnalysis: TakerDocumentAnalysis;
}

const GeneratedReportRow = ({
    takerDocumentAnalysis,
}: GeneratedReportRowProps) => {
    const errorMessage = takerDocumentAnalysis.data['job_failure'];
    const hasFatalError = !!errorMessage;

    return (
        <React.Fragment>
            <TableRow key={takerDocumentAnalysis.id}>
                <TableCell>
                    {(new Date(takerDocumentAnalysis.createdAt)).toLocaleString()}
                </TableCell>
                <TableCell>
                    {(takerDocumentAnalysis.state === "PENDING_GENERATION") ? (
                        <CircularProgress size={20} />
                    ) : (
                        hasFatalError ? (
                            <Tooltip title={errorMessage}>
                                <WarningOutlined />
                            </Tooltip>
                        ) : (
                            <Check />
                        )
                    )}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default GeneratedReportRow;