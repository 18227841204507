import { Module } from "./builderv2.generated";
import { FulfillmentState, GraphFulfillmentState, MappingQuestionFulfillmentState } from "./taker/fulfillmentstate.generated";
import { AnalysesData, AnswersData, CommentaryOrGuidanceReference, KeyTermSource, ReferencesData, SourcesData, UIDataSchema, UiDataState } from "./taker/uidatastate.generated";

export type ReferenceIdType = "COMMENTARY" | "CUSTOM_GUIDANCE";

function getDataHelper(q: string, uds: UiDataState, iteration: number | null) {
  if (iteration !== null && Array.isArray(uds.data)) {
    if (iteration < uds.data.length) {
      for (let i = 0; i < uds.data[iteration].fields.length; i ++) {
        let f = uds.data[iteration].fields[i];
        if (f.key === q) {
          return f.value;
        }
      }
    }
  } else if (iteration === null && !Array.isArray(uds.data)) {
    for (let i = 0; i < uds.data.fields.length; i ++) {
      let f = uds.data.fields[i];
      if (f.key === q) {
        return f.value;
      }
    }
  }
}

function updateDataHelper(q: string, value: any, uds: UiDataState, iteration: number | null) {
  if (iteration !== null && Array.isArray(uds.data)) {  
    for (const f of uds.data[iteration].fields) {
      if (f.key === q) {
        f.value = value;
        return;
      }
    }
    uds.data[iteration].fields.push({
      key: q,
      value: value
    });
  } else if (iteration === null && !Array.isArray(uds.data)) {
    for (const f of uds.data.fields) {
      if (f.key === q) {
        f.value = value;
        return;
      }
    }
    uds.data.fields.push({
      key: q,
      value: value
    });
  }
}

function find(moduleIds: string[], topUds: UiDataState | undefined): UiDataState | undefined {
  let first = moduleIds.shift();
  if (!topUds || first !== topUds.moduleId) {
    return;
  } else if (moduleIds.length === 0) {
    return topUds;
  }

  for (const n of topUds.nested) {
    let uds = find([...moduleIds], n);
    if (uds) {
      return uds;
    }
  }
}

function empty(module: Module, dataClazzFactory: () => (AnswersData | AnalysesData | ReferencesData | SourcesData)): UiDataState {
  const hasMany = module.dataSpec?.dataSpecType === "basic-table";
  return {
    moduleId: module.id,
    data: (module.dataSpec && hasMany) ? [] : dataClazzFactory(),
    nested: module.nestedModules?.map((m) => empty(m, dataClazzFactory))
  } as UiDataState;
}

export function generateEmptyUIDataSchema(topModule: Module) {
  return {
    lockedStatus: {},
    questionAnswers: empty(
      topModule, 
      () => ({ type: "ANSWERS", fields: [] } as AnswersData)
    ),
    questionAnalyses: empty(
      topModule, 
      () => ({ type: "ANALYSES", fields: [] } as AnalysesData)
    ),
    questionReferences: empty(
      topModule, 
      () => ({ type: "REFERENCES", fields: [] } as ReferencesData)
    ),
    questionSources: empty(
      topModule, 
      () => ({ type: "SOURCES", fields: [] } as SourcesData)
    )
  } as UIDataSchema;
}

export class TakerStateHolder {
  uiDataSchema: UIDataSchema;

  constructor(takerState: Object) {
    this.uiDataSchema = JSON.parse(JSON.stringify(takerState)) as UIDataSchema
  }

  getAnswer(moduleIds: string[], q: string, iteration: number | null): any {
    let uds = find([...moduleIds], this.uiDataSchema.questionAnswers);
    if (!uds) {
      return;
    }
    return getDataHelper(q, uds, iteration);
  }

  updateAnswer(moduleIds: string[], q: string, value: any, iteration: number | null) {
    let uds = find([...moduleIds], this.uiDataSchema.questionAnswers);
    if (!uds) {
      return;
    }
    return updateDataHelper(q, value, uds, iteration);
  }

  getAnalysis(moduleIds: string[], q: string, iteration: number | null): string | undefined {
    let uds = find([...moduleIds], this.uiDataSchema.questionAnalyses);
    if (!uds) {
      return;
    }
    return getDataHelper(q, uds, iteration) as string;
  }

  updateAnalysis(moduleIds: string[], q: string, value: string, iteration: number | null) {
    let uds = find([...moduleIds], this.uiDataSchema.questionAnalyses);
    if (!uds) {
      return;
    }
    return updateDataHelper(q, value, uds, iteration);
  }

  getReference(moduleIds: string[], q: string, iteration: number | null): CommentaryOrGuidanceReference[] {
    let uds = find([...moduleIds], this.uiDataSchema.questionReferences);
    if (!uds) {
      return [];
    }
    return getDataHelper(q, uds, iteration) as CommentaryOrGuidanceReference[];
  }

  updateReference(moduleIds: string[], q: string, value: CommentaryOrGuidanceReference[], iteration: number | null) {
    let uds = find([...moduleIds], this.uiDataSchema.questionReferences);
    if (!uds) {
      return;
    }
    return updateDataHelper(q, value, uds, iteration);
  }

  getSource(moduleIds: string[], q: string, iteration: number | null): KeyTermSource[] {
    let uds = find([...moduleIds], this.uiDataSchema.questionSources);
    if (!uds) {
      return [];
    }
    return getDataHelper(q, uds, iteration) as KeyTermSource[];
  }

  updateSource(moduleIds: string[], q: string, value: KeyTermSource[], iteration: number | null) {
    let uds = find([...moduleIds], this.uiDataSchema.questionSources);
    if (!uds) {
      return;
    }
    return updateDataHelper(q, value, uds, iteration);
  }

  count(moduleIds: string[]) {
    let uds = find([...moduleIds], this.uiDataSchema.questionAnswers);
    if (uds && !Array.isArray(uds.data)) {
      throw Error(`data in module ${moduleIds.join('_')} is not list`);
    } else if (uds && Array.isArray(uds.data)) {
      return uds.data.length;
    }
  }

  addIteration(moduleIds: string[]) {
    let answersUds = find([...moduleIds], this.uiDataSchema.questionAnswers);
    let analysesUds = find([...moduleIds], this.uiDataSchema.questionAnalyses);
    let referencesUds = find([...moduleIds], this.uiDataSchema.questionReferences);
    let sourcesUds = find([...moduleIds], this.uiDataSchema.questionSources);
    
    if (answersUds && !Array.isArray(answersUds.data)) {
      throw Error(`answers data in module ${moduleIds.join('_')} is not list`);
    } else if (answersUds && Array.isArray(answersUds.data)) {
      (answersUds.data as AnswersData[]).push({
        type: "ANSWERS",
        fields: []
      });
    }

    if (analysesUds && !Array.isArray(analysesUds.data)) {
      throw Error(`analyses data in module ${moduleIds.join('_')} is not list`);
    } else if (analysesUds && Array.isArray(analysesUds.data)) {
      (analysesUds.data as AnalysesData[]).push({
        type: "ANALYSES",
        fields: []
      });
    }

    if (referencesUds && !Array.isArray(referencesUds.data)) {
      throw Error(`references data in module ${moduleIds.join('_')} is not list`);
    } else if (referencesUds && Array.isArray(referencesUds.data)) {
      (referencesUds.data as ReferencesData[]).push({
        type: "REFERENCES",
        fields: []
      });
    }

    if (sourcesUds && !Array.isArray(sourcesUds.data)) {
      throw Error(`sources data in module ${moduleIds.join('_')} is not list`);
    } else if (sourcesUds && Array.isArray(sourcesUds.data)) {
      (sourcesUds.data as SourcesData[]).push({
        type: "SOURCES",
        fields: []
      });
    }
  }

  removeIteration(moduleIds: string[], iteration: number) {
    let answersUds = find([...moduleIds], this.uiDataSchema.questionAnswers);
    let analysesUds = find([...moduleIds], this.uiDataSchema.questionAnalyses);
    let referencesUds = find([...moduleIds], this.uiDataSchema.questionReferences);
    let sourcesUds = find([...moduleIds], this.uiDataSchema.questionSources);
    
    if (answersUds && !Array.isArray(answersUds.data)) {
      throw Error(`answers data in module ${moduleIds.join('_')} is not list`);
    } else if (answersUds && Array.isArray(answersUds.data)) {
      answersUds.data.splice(iteration, 1);
    }

    if (analysesUds && !Array.isArray(analysesUds.data)) {
      throw Error(`analyses data in module ${moduleIds.join('_')} is not list`);
    } else if (analysesUds && Array.isArray(analysesUds.data)) {
      analysesUds.data.splice(iteration, 1);
    }

    if (referencesUds && !Array.isArray(referencesUds.data)) {
      throw Error(`references data in module ${moduleIds.join('_')} is not list`);
    } else if (referencesUds && Array.isArray(referencesUds.data)) {
      referencesUds.data.splice(iteration, 1);
    }

    if (sourcesUds && !Array.isArray(sourcesUds.data)) {
      throw Error(`sources data in module ${moduleIds.join('_')} is not list`);
    } else if (sourcesUds && Array.isArray(sourcesUds.data)) {
      sourcesUds.data.splice(iteration, 1);
    }
  }
}

type FulfillmentSubstate = GraphFulfillmentState | MappingQuestionFulfillmentState | {};

function findFulfillmentState(moduleIds: string[], fs: FulfillmentState | undefined): FulfillmentState | undefined {
  let first = moduleIds.shift();
  if (!fs || first !== fs.moduleId) {
    return;
  } else if (moduleIds.length === 0) {
    return fs;
  }

  for (const n of fs.nested) {
    let uds = findFulfillmentState([...moduleIds], n);
    if (uds) {
      return uds;
    }
  }
}

export class FulfillmentStateHolder {
  fulfillmentState: FulfillmentState;

  constructor(fulfillmentStateObj: Object) {
    this.fulfillmentState = JSON.parse(JSON.stringify(fulfillmentStateObj)) as FulfillmentState
  }

  getSubstate(moduleIds: string[], iteration: number | null): FulfillmentSubstate | undefined {
    let fs = findFulfillmentState([...moduleIds], this.fulfillmentState);
    if (!fs) {
      return;
    }

    if (iteration !== null && Array.isArray(fs.state)) {
      return fs.state[iteration];
    } else if (iteration === null && !Array.isArray(fs.state)) {
      return fs.state;
    }
  }

  isSubstateFulfilled(moduleIds: string[]): boolean | undefined {
    let fs = findFulfillmentState([...moduleIds], this.fulfillmentState);
    if (!fs) {
      return false;
    }

    if (Array.isArray(fs.isFulfilled)) {
      return fs.isFulfilled.every(Boolean);
    } else if (!Array.isArray(fs.isFulfilled)) {
      return fs.isFulfilled;
    }
  }

  getSubstateFulfilled(moduleIds: string[]): boolean | boolean[] | undefined {
    let fs = findFulfillmentState([...moduleIds], this.fulfillmentState);
    if (!fs) {
      return;
    }
    return fs.isFulfilled;
  }

  count(moduleIds: string[]) {
    let fs = findFulfillmentState([...moduleIds], this.fulfillmentState);
    if (!fs) {
      return;
    }
    if (fs && Array.isArray(fs.state)) {
      return fs.state.length;
    }
  }
}
