import React, { CSSProperties } from 'react';
import { 
    OPEN_KEY_TERM_POPUP_COMMAND,
    CLOSE_KEY_TERM_POPUP_COMMAND
 } from '../../plugins/KeyTermPopupPlugin';
import { LexicalEditor } from 'lexical';

type KeyTermComponentProps = {
    editor: LexicalEditor;
    identifier: string;
    name: string;
    shouldHighlight: boolean
};

const KeyTermComponent = ({
    editor,
    identifier,
    name,
    shouldHighlight
} : KeyTermComponentProps) => {
    const style: CSSProperties = {};
    if (shouldHighlight) {
        style.backgroundColor = '#F0F2F5';
    }

    return (
        <span
            style={style}
            onMouseOver={(e) => {                
                editor.dispatchCommand(OPEN_KEY_TERM_POPUP_COMMAND, {
                    identifier,
                    clickBoundingRect: e.currentTarget.getBoundingClientRect()
                });
            }}
            onMouseLeave={() => {
                editor.dispatchCommand(CLOSE_KEY_TERM_POPUP_COMMAND, undefined);
            }}
        >
            {name}
        </span>
    );
};

export default KeyTermComponent;
