import React, { useEffect, useMemo } from "react";
import { Button, Grid } from "@mui/material/";
import Box from '@mui/material/Box';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Form } from "../../../components/jsonschema/theme";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import { Edge } from "../../../types/builderv2.generated";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@mui/icons-material";
import { CrudButtonGroup } from "../../../components/buttons/crudButtonGroup";


interface EdgeRowProps {
    edge: Edge; 
    shouldDisableUp: boolean;
    shouldDisableDown: boolean;
    clickUp: () => void;
    clickDown: () => void;
    onDelete: () => void;
    setEditEdge: (n: Edge) => void;
}

const EdgeRow = ({ 
    edge, 
    shouldDisableUp,
    shouldDisableDown,
    clickUp,
    clickDown,
    onDelete,
    setEditEdge
}: EdgeRowProps) => {
    return (
        <React.Fragment>    
            <TableRow 
                sx={{ 
                    '& > *': { borderBottom: 'unset' },
                    "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.04)",
                    }, 
                }}
            >
                <TableCell component="th" scope="row">
                    {edge.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {edge.label}
                </TableCell>
                <TableCell scope="row">
                    {edge.source}
                </TableCell>
                <TableCell scope="row">
                    {edge.target}
                </TableCell>
                <TableCell scope="row">
                    <CrudButtonGroup 
                        buttons={[
                            {
                                icon: (<Edit fontSize="small"/>),
                                handleClick: () => {
                                    setEditEdge(edge);
                                }
                            },
                            {
                                disabled: shouldDisableUp,
                                icon: (<ArrowUpward fontSize="small"/>),
                                handleClick: () => {
                                    clickUp();
                                }
                            },
                            {
                                disabled: shouldDisableDown,
                                icon: (<ArrowDownward fontSize="small"/>),
                                handleClick: () => {
                                    clickDown();
                                }
                            },
                            {
                                icon: (<Delete fontSize="small"/>),
                                handleClick: () => {
                                    onDelete();
                                }
                            }
                        ]} 
                    />                    
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

interface EdgesEditorProps {
    fullSchema: RJSFSchema;
    edges: Edge[];
    onUpdate: (es: Edge[]) => void;
} 

const EdgesEditor = ({ 
    fullSchema,
    edges,
    onUpdate
}: EdgesEditorProps) => {
    const [adding, setAdding] = React.useState<boolean>(false);
    const [editEdge, setEditEdge] = React.useState<Edge | null>(null);

    const nextEdgeId = useMemo(() => {
        if (edges) { 
            let maxNum = -1  
            for (let e of edges) {
                let num = parseInt(e.id.substring(1)); 
                if (maxNum < num) {
                    maxNum = num;
                }              
            }
            return `e${(maxNum + 1)}`
        }
        return "e0"
    }, [edges]);

    const updateEdge = (edge: Edge) => {
        const newEdges = JSON.parse(JSON.stringify(edges));
        if (adding && newEdges) {
            newEdges.push(edge);
        } else if (newEdges) {
            for (let i = 0; i < newEdges.length; i ++) {
                if (edge.id === newEdges[i].id) {
                    newEdges[i] = edge;
                }
            }
        }
        onUpdate(newEdges);
    };

    const onClickUp = (i: number) => {
        const newEdges = JSON.parse(JSON.stringify(edges));
        if (newEdges) {
            let tempTop = newEdges[i - 1];
            let tempCur = newEdges[i];
                
            newEdges[i - 1] = tempCur;
            newEdges[i] = tempTop;
        }
        onUpdate(newEdges);
    };

    const onClickDown = (i: number) => {
        const newEdges = JSON.parse(JSON.stringify(edges));
        if (newEdges) {
            let tempBottom = newEdges[i + 1];
            let tempCur = newEdges[i];
                
            newEdges[i + 1] = tempCur;
            newEdges[i] = tempBottom;
        }
        onUpdate(newEdges);
    };

    const onDelete = (i: number) => {
        const newEdges = JSON.parse(JSON.stringify(edges));
        if (newEdges) {
            newEdges.splice(i, 1);
        }
        onUpdate(newEdges);
    };

    return (
        <>
            <Grid container p={1}>
                <Grid item xs={12}>
                    <Button
                        sx={{ m: 1 }}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setAdding(true);
                            setEditEdge({
                                id: nextEdgeId
                            } as Edge);
                        }}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                id
                            </TableCell>
                            <TableCell>
                                label
                            </TableCell>
                            <TableCell>
                                source
                            </TableCell>
                            <TableCell>
                                target
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {edges.map((e, i) => (
                            <EdgeRow 
                                edge={e}
                                shouldDisableUp={(i === 0)}
                                shouldDisableDown={(i === (edges.length - 1))}
                                clickUp={() => {
                                    onClickUp(i);
                                }}
                                clickDown={() => {
                                    onClickDown(i);
                                }}
                                onDelete={() => {
                                    onDelete(i);
                                }}
                                setEditEdge={setEditEdge}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SimpleModalWrapper
                headerText="Edit Edge"
                open={!!editEdge}
                handleClose={() => setEditEdge(null)}
                maxWidth='md'
            >
                <Box 
                    sx={{ 
                        width: '100%',
                        paddingTop: '3%',
                        alignContent: "center"
                    }}
                >
                    {editEdge && (
                       <Form
                            formData={editEdge}
                            onSubmit={(data, event) => {
                                updateEdge(data.formData);
                                setEditEdge(null);
                            }}
                            schema={({
                                $ref: "#/$defs/edge",
                                $defs: fullSchema.$defs
                            } as RJSFSchema)}
                            validator={validator} 
                        />  
                    )}
                </Box>
            </SimpleModalWrapper>
        </>
    );
  }

export default EdgesEditor;
