import React, { useEffect, useMemo, useState, useContext, useCallback, useRef, memo } from 'react';
import Box from '@mui/material/Box';
import { useTakerState } from '../../../containers/TakerDocumentState/TakerDocumentState';
import { FormControl, FormHelperText, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KeyboardTab } from '@mui/icons-material';
import { useSelector } from '../../../redux/reduxUtils/functions';
import { RootReducerType } from '../../../redux/models/reduxTypes';
import { DocumentHighlight } from '../../../types/taker/documentkeyterms.generated';
import { CommentType, TakerDocumentReviewComment, TakerDocumentReviewCommentPayload } from '../../../redux/models/dataModelTypes';
import { useLocalStorage } from '@uidotdev/usehooks';
import { CreateNewComment } from './CreateNewComment';
import CommentThread from './CommentThread';
import CommentFilters, { DOCUMENT_COMMENT_VALUE, Filter, KEY_TERM_COMMENT_VALUE } from './CommentFilters';
import { useSideNav } from '../../navigation/SideNav';

const drawerWidth = 325;
const Context = React.createContext({});

interface CommentsDrawerHookData {
    stageCommentFromHighlights: (
        dh: DocumentHighlight[],
        t: string,
        takerDocumentUploadId: string,
        lexicalDocumentIdentifier: string,
        pageIndex: number
    ) => void;
    stageCommentFromKeyTerm: (
        takerDocumentUploadId: string,
        keyTermIdentifier: string,
        keyTermName: string,
    ) => void;
    highlightsPerDocumentPage: Record<string, Record<number, DocumentHighlight[]>>;
    selectCommentThread: (threadId: string) => void;
}

type CommentsDrawerHook = () => CommentsDrawerHookData;

export const useCommentsDrawer: CommentsDrawerHook = () => {
    return useContext(Context) as CommentsDrawerHookData;
}

interface CommentsDrawerProps {
    commentTypes: CommentType[];
    disabled?: boolean;
    children: React.ReactNode;
}

/**
 * The `CommentsDrawer` is a component that renders a drawer for comments.
 * The drawer is initially closed and can be opened or closed by clicking on the
 * button in the bottom right corner of the drawer.
 * The drawer contains a list of all comments for the current document.
 * The comments are grouped by their thread ID. A thread is a collection of comments
 * that are related to the same highlight or document section.
 * The drawer also contains a search bar for searching comments.
 * The drawer also contains a button for posting a new comment.
 * If the user is not logged in, the drawer will not be rendered.
 * @param {{
 *   commentTypes: CommentType[];
 *   disabled?: boolean;
 *   children: React.ReactNode;
 * }} props
 * @returns {JSX.Element}
 */
const CommentsDrawer = ({
    commentTypes,
    disabled = false,
    children
}: CommentsDrawerProps) => {
    const {
        takerDocumentId,
        commentsOpen,
        setCommentsOpen,
        reviewComments,
        takerPermissionState,
        addReviewComment,
        updateReviewComment
    } = useTakerState();
    const { isOpen: isSideNavOpen } = useSideNav();
    const [searchText, setSearchText] = useState<string>("");
    const [stagedComment, setStagedComment] = useLocalStorage<TakerDocumentReviewCommentPayload | undefined>(`${takerDocumentId}-${commentTypes.join("-")}-stagedComment`);
    const theme = useTheme();
    const [enabledFilters, setEnabledFilters] = useState<Filter[]>([]);
    const scrollableDrawerRef = useRef<HTMLDivElement>(null);
    const [selectedThread, setSelectedThread] = useState<string>();

    const isReviewer = takerPermissionState.allGrants.includes("REVIEW");
    const isOwner = takerPermissionState.allGrants.includes("OWNER");
    const canPostComment = isReviewer || isOwner;

    useEffect(() => {
        // if comments are closed, close the menu
        if (!commentsOpen) {
            setSearchText("");
        }
    }, [commentsOpen]);

    const targetReviewComments = useMemo(
        () => 
            commentTypes.length === 0 ?
                reviewComments :
                reviewComments.filter((comment) => commentTypes.includes(comment.commentType)),
        [reviewComments, commentTypes]
    );

    const groupedTargetReviewComments = useMemo(() => {
        const mapping: Record<string, TakerDocumentReviewComment[]> = {};
        const orderedThreadIds: string[] = [];
        for (const comment of targetReviewComments) {
            const { threadId } = comment.commentMetadata;
            if (threadId) {
                // Add thread to list if new
                if (!orderedThreadIds.includes(threadId)) {
                    orderedThreadIds.push(threadId);
                }

                // Add comment to mapping
                if (!mapping[threadId]) {
                    mapping[threadId] = [];
                }
                mapping[threadId].push(comment);
            }
        }
        return orderedThreadIds.map(threadId => ({
            threadId,
            comments: mapping[threadId]
        }));
    }, [targetReviewComments]);

    const filteredGroupedTargetReviewComments = useMemo(() => {
        let commentTypeFilters = enabledFilters.filter((filter) =>
            filter.type === "COMMENT_TYPE" 
        );

        return groupedTargetReviewComments.filter((groupedComments) => {
            // If no top level filters exist nothing is excluded, allow by default.
            let filteredOut = false;
            
            // If top level filters exist, then include values.
            if (commentTypeFilters.length !== 0) {

                // Find applicable filters if they exist.
                const firstComment = groupedComments.comments[0];            
                let topLevelFilter = commentTypeFilters.find((filter) =>
                    firstComment.commentType === filter.value
                );
                let filteredOutTop = !!topLevelFilter;
            
                // Default to the top level filter if no subfilters are found
                if (topLevelFilter?.value === DOCUMENT_COMMENT_VALUE) {
                    let filteredOutKeyTerm = enabledFilters.some((filter) => {
                        if (filter.type === "DOCUMENT_KEY_TERM_GROUP") {
                            const { takerDocumentUploadId } = firstComment.commentMetadata;
                            return takerDocumentUploadId === filter.value;
                        }
                        return false;
                    });
                    let filteredOutDocument = enabledFilters.some((filter) => {
                        if (filter.type === "DOCUMENT") {
                            const { lexicalDocumentIdentifier } = firstComment.commentMetadata;
                            return lexicalDocumentIdentifier === filter.value;
                        }
                        return false;
                    });
                    filteredOut = filteredOutTop && filteredOutKeyTerm && filteredOutDocument;
                } else if (topLevelFilter?.value === KEY_TERM_COMMENT_VALUE) {
                    let filteredOutKeyTerm = enabledFilters.some((filter) => {
                        if (filter.type === "KEY_TERM_GROUP") {
                            const { takerDocumentUploadId } = firstComment.commentMetadata;
                            return takerDocumentUploadId === filter.value;
                        }
                        return false;
                    });
                    filteredOut = filteredOutTop && filteredOutKeyTerm;
                }
            }

            if (searchText === "") {
                return !filteredOut;
            }

            let lowerSearch = searchText.toLowerCase();
            return !filteredOut && groupedComments.comments.some(comment => {
                return comment.commentText.toLowerCase().includes(lowerSearch);
            });
        });
    }, [groupedTargetReviewComments, enabledFilters, searchText]);

    const highlightsPerDocumentPage = useMemo(() => {
        const mapping: Record<string, Record<number, DocumentHighlight[]>> = {};
        for (const comment of targetReviewComments) {
            if (comment.commentType === "DOCUMENT_HIGHLIGHT") {
                const { documentHighlights, lexicalDocumentIdentifier, pageIndex } = comment.commentMetadata;
                if (!mapping[lexicalDocumentIdentifier]) {
                    mapping[lexicalDocumentIdentifier] = {};
                }
                if (!mapping[lexicalDocumentIdentifier][pageIndex]) {
                    mapping[lexicalDocumentIdentifier][pageIndex] = [];
                }
                if (documentHighlights) {
                    mapping[lexicalDocumentIdentifier][pageIndex].push(...documentHighlights);
                }
            }
        }
        return mapping;
    }, [targetReviewComments])

    const { user: authedUser } = useSelector((state: RootReducerType) => state.auth);

    const stageCommentFromHighlights = useCallback(
        (
            documentHighlights: DocumentHighlight[],
            highlightTextContent: string,
            takerDocumentUploadId: string,
            lexicalDocumentIdentifier: string,
            pageIndex: number
        ) => {
            if (!canPostComment) {
                return;
            }

            setCommentsOpen(true);
            setStagedComment({
                commentType: "DOCUMENT_HIGHLIGHT",
                commentLexical: undefined,
                commentText: "",
                commentMetadata: {
                    documentHighlights,
                    highlightTextContent,
                    takerDocumentUploadId,
                    lexicalDocumentIdentifier,
                    pageIndex,
                    threadId: window.crypto.randomUUID(), // create a new ID when we create from a highlight
                },
            });

            // Scroll to bottom with slight delay to account for the hight
            // of the scrollableDrawerRef changing.
            setTimeout(() => {
                if (scrollableDrawerRef.current) {
                    scrollableDrawerRef.current.scrollTop = scrollableDrawerRef.current.scrollHeight;
                }
            }, 50);
        },
        [canPostComment]
    );

    const stageCommentFromKeyTerm = useCallback(
        (
            keyTermIdentifier: string,
            keyTermName: string,
            takerDocumentUploadId: string,
        ) => {
            if (!canPostComment) {
                return;
            }

            setCommentsOpen(true);
            setStagedComment({
                commentType: "KEY_TERM",
                commentLexical: undefined,
                commentText: "",
                commentMetadata: {
                    keyTermIdentifier,
                    keyTermName,
                    takerDocumentUploadId,
                    threadId: window.crypto.randomUUID(), // create a new ID when we create from a highlight
                },
            });

            // Scroll to bottom with slight delay to account for the hight
            // of the scrollableDrawerRef changing.
            setTimeout(() => {
                if (scrollableDrawerRef.current) {
                    scrollableDrawerRef.current.scrollTop = scrollableDrawerRef.current.scrollHeight;
                }
            }, 50);
        },
        [canPostComment]
    );

    const postStagedComment = useCallback(() => {
        if (!canPostComment || !stagedComment) {
            return;
        }

        addReviewComment(
            stagedComment.commentLexical,
            stagedComment.commentText,
            stagedComment.commentType,
            stagedComment.commentMetadata
        );
        setStagedComment(undefined);
    }, [canPostComment, stagedComment]);

    const drawerContainerStyle: React.CSSProperties = {
        display: 'flex',
        height: '100%',
        maxWidth: '100vw',
    }

    const commentsStyle: React.CSSProperties = {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        overflowX: 'hidden',
        height: '100%',
        backgroundColor: '#FBFCFE'
    };

    if (commentsOpen) {
        commentsStyle.width = drawerWidth;
        commentsStyle.paddingLeft = '0.5em';
        commentsStyle.paddingRight = '0.5em';
        commentsStyle.paddingBottom = '0.5em';
    } else {
        commentsStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
        commentsStyle.width = `0px`;
    }

    const otherChildrenStyle: React.CSSProperties = {};
    if (commentsOpen && isSideNavOpen) {
        const occupiedSpace = (200 + drawerWidth);
        otherChildrenStyle.width = `calc(100vw - ${occupiedSpace}px)`;
        otherChildrenStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
    } else if (commentsOpen) {
        const occupiedSpace = drawerWidth;
        otherChildrenStyle.width = `calc(100vw - ${occupiedSpace}px)`;
        otherChildrenStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        });
    } else if (isSideNavOpen) {
        const occupiedSpace = 200;
        otherChildrenStyle.width = `calc(100vw - ${occupiedSpace}px)`;
        otherChildrenStyle.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        });
    } else {
        otherChildrenStyle.flexGrow = 1;
    }

    return (
        <Box
            data-testid="comments-drawer"
            sx={drawerContainerStyle}
        >
            <Box style={otherChildrenStyle}>
                <Context.Provider
                    value={{
                        stageCommentFromHighlights,
                        stageCommentFromKeyTerm,
                        highlightsPerDocumentPage,
                        selectCommentThread: (threadId: string) => setSelectedThread(threadId) 
                    }}
                >
                    {children}
                </Context.Provider>
            </Box>
            <Box style={commentsStyle}>
                {disabled ? (
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            textAlign="center"
                        >
                            Comments coming soon!
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        data-testid="comments-drawer-box"
                        width="100%"
                        height="100%"
                        overflow="hidden"
                        display="flex"
                        flexDirection="column"
                    >
                        <Toolbar
                            variant="dense"
                            disableGutters
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="text.secondary"
                            >
                                Comments
                            </Typography>
                            <IconButton
                                data-testid="comments-close-button"
                                disabled={!commentsOpen}
                                size='small'
                                onClick={() => setCommentsOpen(false)}
                            >
                                <KeyboardTab />
                            </IconButton>
                        </Toolbar>
                        <Toolbar
                            variant="dense"
                            disableGutters
                            sx={{
                                justifyContent: 'space-between',
                                marginBottom: 1
                            }}
                        >
                            <CommentFilters
                                commentTypes={commentTypes}
                                onChangeEnabledFilters={setEnabledFilters}
                            />
                            <TextField
                                data-testid="comments-search"
                                size="small"
                                value={searchText}
                                placeholder="Search"
                                onChange={(e: any) => {
                                    setSearchText(e.target.value);
                                }}
                                variant="outlined"
                                fullWidth
                            />
                        </Toolbar>
                        {!!authedUser && (
                            <Box
                                ref={scrollableDrawerRef}
                                sx={{
                                    overflowY: "auto",
                                    flex: 1
                                }}
                            >
                                <Box>
                                    {(filteredGroupedTargetReviewComments.length > 0) ? filteredGroupedTargetReviewComments.map((commentGroup, groupIndex) => (
                                        <CommentThread
                                            index={groupIndex}
                                            commentsOpen={commentsOpen}
                                            takerDocumentId={takerDocumentId}
                                            authedUser={authedUser}
                                            threadId={commentGroup.threadId}
                                            comments={commentGroup.comments}
                                            enableReplies={canPostComment}
                                            onPostComment={(commentPayload) => {
                                                addReviewComment(
                                                    commentPayload.commentLexical,
                                                    commentPayload.commentText,
                                                    commentPayload.commentType,
                                                    commentPayload.commentMetadata
                                                );
                                            }}
                                            onUpdateComment={(commentId, commentPayload) => {
                                                updateReviewComment(
                                                    commentId,
                                                    commentPayload.commentLexical,
                                                    commentPayload.commentText
                                                );
                                            }}
                                            isSelected={commentGroup.threadId === selectedThread}
                                            setIsSelected={() => setSelectedThread(commentGroup.threadId)}
                                            onClickMetadata={() => setSelectedThread(undefined)}
                                        />
                                    )) : (
                                        <i>no comments</i>
                                    )}
                                </Box>
                                {(stagedComment && canPostComment) && (
                                    <CreateNewComment
                                        stagedComment={stagedComment}
                                        updateStagedComment={setStagedComment}
                                        onClearStagedComment={() => setStagedComment(undefined)}
                                        onPostComment={postStagedComment}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default memo(CommentsDrawer);