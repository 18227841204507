import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
    LexicalCommand,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
} from 'lexical';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import SummaryPopup from './SummaryPopup';
import { useKeyTermGroupState } from '../../../../containers/TakerDocumentState/KeyTermGroupState';

import './index.css';

export interface KeyTermPopupPayload {
    identifier: string;
    clickBoundingRect: DOMRect;
};

export const OPEN_KEY_TERM_POPUP_COMMAND: LexicalCommand<KeyTermPopupPayload> = createCommand('OPEN_KEY_TERM_POPUP_COMMAND');
export const CLOSE_KEY_TERM_POPUP_COMMAND: LexicalCommand<void> = createCommand('CLOSE_KEY_TERM_POPUP_COMMAND');

export default function KeyTermPopupPlugin(): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const [currentPayload, setCurrentPayload] = useState<KeyTermPopupPayload>();

    const { documentKeyTermsService } = useKeyTermGroupState();

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                OPEN_KEY_TERM_POPUP_COMMAND,
                (payload) => {
                    setCurrentPayload(payload);
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                CLOSE_KEY_TERM_POPUP_COMMAND,
                () => {
                    setCurrentPayload(undefined);
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            )
        );
    }, [
        editor
    ]);

    const targetKeyTerm = useMemo(() => {
        if (!currentPayload) {
            return;
        }
        return documentKeyTermsService.keyTerms?.find(kt => kt.identifier === currentPayload.identifier);
    }, [
        currentPayload?.identifier,
        documentKeyTermsService
    ]);

    return (
        <>
            {(currentPayload && targetKeyTerm) &&
                createPortal(
                    <SummaryPopup
                        editor={editor}
                        onClose={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setCurrentPayload(undefined);
                        }}
                        originalRangeRect={currentPayload.clickBoundingRect}
                        keyTerm={targetKeyTerm}
                    />,
                    document.body,
                )}
        </>
    );
}