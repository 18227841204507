import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    useNodesState,
    useEdgesState,
    Node,
    Edge,
    Position,
    ConnectionMode,
    Background,
    XYPosition
} from 'reactflow';
import { ReactFlowProvider, useReactFlow } from 'reactflow';
import { Module, Root } from '../../../types/builderv2.generated';
import { BuilderHolder } from '../../../types/builder';
import dagre from 'dagre';
import { Box, Tab, Tabs } from '@mui/material';
import { a11yProps } from '../../../components/navigation/TabPanel';

import { ControlPointData, EditableEdge } from '../../../components/reactflow/edges/EditableEdge';
import { ConnectionLine } from '../../../components/reactflow/edges/ConnectionLine';

import 'reactflow/dist/style.css';
import './fullGps.css';
import { getPoints } from '../../../components/reactflow/utils';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
    dagreGraph.setGraph({ rankdir: 'TB' });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: node.width, height: node.height });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        if (!node.targetPosition && !node.sourcePosition) {
            node.targetPosition = Position.Top;
            node.sourcePosition = Position.Bottom;
        }

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        let nodeWidth = node.width;
        let nodeHeight = node.height;
        if (!!nodeWidth && !!nodeHeight && !node.position) {
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        }
        return node;
    });

    return { nodes, edges };
};

interface FullGpsComponentProps {
    builderHolder?: BuilderHolder;
    existingNodes: Node[];
    existingEdges: Edge[];
    onUpdate: (pm: any) => void;
};

const ACTIVE_COLOR = '#FF0072';
const initBgColor = '#1976d217';

export const CustomEdgeType = EditableEdge;

export const edgeTypes = {
    'editable-edge': EditableEdge,
};

interface Point {
    x: number;
    y: number;
    id: string;
    active: boolean;
};

export interface NodeDetails {
    xPos: number;
    yPos: number;
    absolute?: XYPosition;
    height?: number | null;
    width?: number | null;
}

function FullGpsComponent({
    builderHolder,
    existingNodes,
    existingEdges,
    onUpdate
}: FullGpsComponentProps) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [udpateJob, setUpdateJob] = useState<any>();
    const [positioningMetadata, setPositioningMetadata] = useState<{
        exactPositionsAndSizesByNodeId?: Record<string, NodeDetails>;
        edgePointsByEdgeId?: Record<string, Point[]>;
    }>(builderHolder?.builder.positioningMetadata as any);
    const { fitView } = useReactFlow();

    const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), []);

    useEffect(() => {
        if (udpateJob) {
            clearTimeout(udpateJob);
        }

        setUpdateJob(setTimeout(() => {
            onUpdate(positioningMetadata);
        }, 2000));
    }, [positioningMetadata]);

    useEffect(() => {
        const nodeById: Record<string, Node> = {};
        for (const n of existingNodes) {
            nodeById[n.id] = n;
        }

        // assign existing positions
        let exactPositionsAndSizesByNodeId = positioningMetadata.exactPositionsAndSizesByNodeId || {};
        for (const n of existingNodes) {
            let pos = exactPositionsAndSizesByNodeId[n.id];
            if (pos) {
                n.position = {
                    x: pos.xPos,
                    y: pos.yPos
                };
                if (pos.absolute) {
                    n.positionAbsolute = pos.absolute;
                }
            }
        }

        let edgePointsByEdgeId = positioningMetadata.edgePointsByEdgeId || {};
        for (const e of existingEdges) {
            let points = edgePointsByEdgeId[e.id];
            if (points) {
                e.data.points = points;
            } else {
                let sourceNode = nodeById[e.source];
                let targetNode = nodeById[e.target];
                let sourceWidth = sourceNode.width;
                let sourceHeight = sourceNode.height;
                let targetWidth = targetNode.width;
                let targetHeight = targetNode.height;
                if (sourceNode 
                    && targetNode 
                    && sourceWidth 
                    && sourceHeight 
                    && targetWidth 
                    && targetHeight
                    && sourceNode.positionAbsolute
                    && targetNode.positionAbsolute) {
                    const pathPoints = getPoints({
                        source: {
                            x: sourceNode.positionAbsolute.x + (sourceWidth / 2),
                            y: sourceNode.positionAbsolute.y + (sourceHeight)
                        } as XYPosition,
                        target: {
                            x: targetNode.positionAbsolute.x + (targetWidth / 2),
                            y: targetNode.positionAbsolute.y
                        } as XYPosition,
                        offset: 0
                    });

                    e.data.points = pathPoints.map((point, i) =>
                        ({
                            ...point,
                            id: window.crypto.randomUUID(),
                            prev: i === 0 ? undefined : pathPoints[i - 1],
                            active: true,
                        } as ControlPointData)
                    );
                } else {
                    e.data.points = [];
                }
            }
        }

        // Layout the rest of the positions 
        const layouted = getLayoutedElements(existingNodes, existingEdges);

        setNodes([...layouted.nodes]);
        setEdges([...layouted.edges]);

        window.requestAnimationFrame(() => fitView());
    }, [existingNodes, existingEdges]);

    useEffect(() => {
        const newEdgePointsByEdgeId: Record<string, Point[]> = {
            ...(positioningMetadata.edgePointsByEdgeId || {})
        };
        for (const edge of edges) {
            newEdgePointsByEdgeId[edge.id] = edge.data.points;
        }

        // TODO: enable this
        // setPositioningMetadata({
        //     exactPositionsAndSizesByNodeId: positioningMetadata.exactPositionsAndSizesByNodeId,
        //     edgePointsByEdgeId: newEdgePointsByEdgeId
        // });
    }, [edges]);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDragStop={(event, node, updatedNodes) => {
                const exactPositionsAndSizesByNodeId: Record<string, NodeDetails> = {
                    ...(positioningMetadata.exactPositionsAndSizesByNodeId || {})
                };

                for (const n of updatedNodes) {
                    const nodeDetails: NodeDetails = {
                        xPos: n.position.x,
                        yPos: n.position.y,
                        height: n.height,
                        width: n.width
                    };
                    if (n.positionAbsolute) {
                        nodeDetails.absolute = {
                            x: n.positionAbsolute.x,
                            y: n.positionAbsolute.y
                        };
                    } 
                    exactPositionsAndSizesByNodeId[n.id] = nodeDetails;
                }
                setPositioningMetadata({
                    exactPositionsAndSizesByNodeId,
                    edgePointsByEdgeId: positioningMetadata.edgePointsByEdgeId
                });
            }}
            fitView
            fitViewOptions={{ padding: 0.4 }}
            style={{ background: initBgColor }}
            edgeTypes={edgeTypes}
            connectionMode={ConnectionMode.Loose}
            connectionLineComponent={ConnectionLine}
        >
            <MiniMap
                nodeColor={(n: Node) => ACTIVE_COLOR}
                zoomable
                pannable
            />
            <Controls showInteractive={false} />
            <Background />
        </ReactFlow>
    );
}

const FullGps = ({
    fullConfig,
    onUpdate
}: {
    fullConfig: Root;
    onUpdate: (pm: any) => void;
}) => {
    const [tabValue, setTabValue] = useState(0);

    const countNodesForModule: (m: Module) => number = (m: Module) => {
        let total = 0;
        if (m.uiFulfillment?.fulfillmentType === "mapping-question"
            || m.uiFulfillment?.fulfillmentType === "percentage-mapping-question"
            || m.uiFulfillment?.fulfillmentType === "table"
            || (m.uiFulfillment?.fulfillmentType === "graph" && m.dataSpec?.dataSpecType === "basic-table")) {
            total += 1;
        } else if (m.uiFulfillment?.fulfillmentType === "graph") {
            if (m.uiFulfillment.nodes) {
                total += m.uiFulfillment.nodes.length;
            }
        }

        if (m.nestedModules) {
            for (const nm of m.nestedModules) {
                total += countNodesForModule(nm);
            }
        }
        return total;
    }

    const builderHolder = useMemo(() => {
        if (fullConfig) {
            return new BuilderHolder(fullConfig);
        }
    }, [fullConfig]);

    const positionedModuleList = useMemo(() => {
        if (!builderHolder?.builder.topModule) {
            return [];
        }

        const moduleList: { path: string[]; module: Module; }[] = [{
            path: [],
            module: builderHolder?.builder.topModule
        }];

        const addModules = (m: Module, path: string[]) => {
            if (m.uiFulfillment?.fulfillmentType === "graph" && m.dataSpec?.dataSpecType === "basic") {
                if (m.uiFulfillment.nodes) {
                    for (const n of m.uiFulfillment.nodes) {
                        if (n.nodeType === "module") {
                            const praxiModule = builderHolder.getModule([...path, m.id, n.moduleId]);
                            if (!praxiModule) {
                                throw Error(`module with moduleId=${[...path, m.id, n.moduleId]} doesn't exist`)
                            } else if ((praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table")) {
                                moduleList.push({
                                    path: [...path, m.id],
                                    module: praxiModule
                                });
                            }
                        }
                    }
                }
            }

            if (m.nestedModules) {
                for (const nm of m.nestedModules) {
                    addModules(nm, [...path, m.id]);
                }
            }
        }

        addModules(builderHolder.builder.topModule, []);

        return moduleList;
    }, []);

    const [existingNodes, existingEdges] = useMemo(() => {
        if (!builderHolder) {
            return [[], []];
        }

        const newEdges: Edge[] = [];
        const newNodes: Node[] = [];

        let target = positionedModuleList[tabValue];

        const addForModule = (m: Module, path: string[]) => {
            let pathId = [...path, m.id].join('-')
            if (m.uiFulfillment?.fulfillmentType === "graph") {
                if (m.uiFulfillment.edges) {
                    for (const e of m.uiFulfillment.edges) {
                        newEdges.push({
                            id: `${pathId}-${e.id}`,
                            type: 'editable-edge',
                            source: `${pathId}-${e.source}`,
                            target: `${pathId}-${e.target}`,
                            label: e.label,
                            style: {
                                fontWeight: "20px"
                            },
                            data: {}
                        });
                    }
                }
                if (m.uiFulfillment.nodes) {
                    for (const n of m.uiFulfillment.nodes) {
                        if (n.nodeType === "question") {
                            newNodes.push({
                                id: `${pathId}-${n.id}`,
                                data: {
                                    label: `${n.id}) ${n.questionLabel}: ${n.questionText}`
                                },
                                style: {
                                    width: 275,
                                    height: 150,
                                },
                                width: 275,
                                height: 150,
                                extent: (target.module.id === m.id) ? undefined : 'parent',
                                parentId: (target.module.id === m.id) ? undefined : pathId
                            } as Node);
                        } else if (n.nodeType === "module") {
                            // need to either push a group or a plain node
                            // module == graph (but not group) -> push group
                            // module == other -> push node
                            const praxiModule = builderHolder.getModule([...path, m.id, n.moduleId]);
                            if (!praxiModule) {
                                throw Error(`module with moduleId=${[...path, m.id, n.moduleId]} doesn't exist`)
                            } else if (praxiModule.uiFulfillment?.fulfillmentType === "mapping-question"
                                || praxiModule.uiFulfillment?.fulfillmentType === "percentage-mapping-question"
                                || praxiModule.uiFulfillment?.fulfillmentType === "table"
                                || (praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table")) {
                                newNodes.push({
                                    id: `${pathId}-${n.id}`,
                                    data: {
                                        label: praxiModule?.displayName,
                                    },
                                    className: "bakground-node",
                                    style: {
                                        width: 275,
                                        height: 150
                                    },
                                    width: 275,
                                    height: 150,
                                    extent: (target.module.id === m.id) ? undefined : 'parent',
                                    parentId: (target.module.id === m.id) ? undefined : pathId
                                } as Node);
                            } else if (praxiModule.uiFulfillment?.fulfillmentType === "graph") {
                                let nNodes = countNodesForModule(praxiModule);
                                newNodes.push({
                                    id: `${pathId}-${n.id}`,
                                    data: { label: praxiModule?.displayName },
                                    className: 'bakground-node',
                                    style: {
                                        backgroundColor: 'rgba(255, 0, 0, 0.2)',
                                        width: 200 * nNodes,
                                        height: 200 * nNodes,
                                    },
                                    width: 200 * nNodes,
                                    height: 200 * nNodes,
                                    resizing: true,
                                    extent: (target.module.id === m.id) ? undefined : 'parent',
                                    parentId: (target.module.id === m.id) ? undefined : pathId
                                } as Node);
                            }
                        } else if (n.nodeType === "end") {
                            newNodes.push({
                                id: `${pathId}-${n.id}`,
                                data: {
                                    label: n.label
                                },
                                style: {
                                    width: 50,
                                    height: 50,
                                },
                                width: 50,
                                height: 50,
                                extent: (target.module.id === m.id) ? undefined : 'parent',
                                parentId: (target.module.id === m.id) ? undefined : pathId
                            } as Node);
                        }
                    }
                }
            }

            if (m.nestedModules) {
                for (const nm of m.nestedModules) {
                    if (nm.uiFulfillment?.fulfillmentType === "graph" && nm.dataSpec?.dataSpecType === "basic") {
                        addForModule(nm, [...path, m.id]);
                    }
                }
            }
        }

        addForModule(target.module, target.path);

        return [newNodes, newEdges];
    }, [tabValue, positionedModuleList]);

    return (
        <Box width="100%" height="100%">
            <Tabs
                value={tabValue}
                onChange={(event: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}
            >
                {positionedModuleList.map(m => (
                    <Tab label={m.module.displayName} {...a11yProps(0)} />
                ))}
            </Tabs>
            <ReactFlowProvider>
                <FullGpsComponent
                    builderHolder={builderHolder}
                    existingNodes={existingNodes}
                    existingEdges={existingEdges}
                    onUpdate={onUpdate}
                />
            </ReactFlowProvider>
        </Box>
    )
};

export default memo(FullGps);