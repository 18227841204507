import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { LanguageModel } from "../../redux/models/dataModelTypes";
import { Delete, Edit, OpenInFull } from "@mui/icons-material";
import { useDeleteLanguageModelMutation, useListLanguageModelsQuery } from "../../redux/services/languageModel";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import PagedTable from "../../components/tables/pagedTable";
import { CrudButtonGroup } from "../../components/buttons/crudButtonGroup";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 5

interface Props {
    onOpenDetails: (bm: LanguageModel) => void;
}

const MyLanguageModels = ({ 
    onOpenDetails
}: Props) => {
    const [page, setPage] = useState<number>(DEFAULT_PAGE);
    const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);
    const [deleteLanguageModelDialogTarget, setDeleteLanguageModelDialogTarget] = useState<string | null>(null);
    const navigate = useNavigate();
    const [deleteLanguageModel, deleteLanguageModelResult] = useDeleteLanguageModelMutation();
    const {
        data: languageModels,
        isFetching,
        isLoading,
    } = useListLanguageModelsQuery({ page, limit });

    return (
        <>
            <PagedTable
                paginatedResponse={languageModels}
                tableSpan={5}
                rowMapper={(lm: LanguageModel) => (
                    <TableRow
                        key={lm.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>
                            {lm.name ? lm.name : "no name"}
                        </TableCell>
                        <TableCell>{lm.description}</TableCell>
                        <TableCell>{new Date(lm.createdAt).toLocaleString()}</TableCell>
                        <TableCell>
                            <CrudButtonGroup 
                                buttons={[
                                    {
                                        icon: (<Edit fontSize="small"/>),
                                        handleClick: () => {
                                            navigate(`/languageModel/${lm.id}`);
                                        }
                                    },
                                    {
                                        icon: (<OpenInFull fontSize="small"/>),
                                        handleClick: () => {
                                            onOpenDetails(lm)
                                        }
                                    },
                                    {
                                        icon: (
                                            <Delete fontSize="small"/>
                                        ),
                                        handleClick: () => {
                                            setDeleteLanguageModelDialogTarget(lm.id);
                                        }
                                    }
                                ]} 
                            />
                        </TableCell>
                    </TableRow>
                )}
                headers={[
                    "Label",
                    "Description",
                    "Created",
                    ""
                ]}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
            />
            <Dialog
                open={!!deleteLanguageModelDialogTarget}
                onClose={() => setDeleteLanguageModelDialogTarget(null)}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            if (deleteLanguageModelDialogTarget) {
                                deleteLanguageModel(deleteLanguageModelDialogTarget);
                                setDeleteLanguageModelDialogTarget(null);
                            }
                        }} 
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button onClick={() => setDeleteLanguageModelDialogTarget(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>  
        </>
    );
}

export default MyLanguageModels;
