import React, { useMemo } from "react";
import { $createTextNode, $getRoot, $setSelection } from "lexical";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import "./UpdateReportSectionModal.css"
import { $isInnerReportSectionNode } from "../../nodes/InnerReportSectionNode";
import { BoltButton } from "../../../buttons/boltButton";
import { reportData } from "../../../../containers/TakerDocumentState/dummyReportData";
import { OptionsWithExtraProps, useSnackbar } from "notistack";
import { useUserScopedAppData } from "../../../../containers/UserScopedAppData/UserScopedAppData";

const TOP_CENTER_OPTION = {
    variant: 'info',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
} as OptionsWithExtraProps<'info'>;

interface AiGeneratedResponseProps {
    editIdentifier: string;
    onSetLoading: (l: boolean) => void;
}

const AiGeneratedResponse = ({
    editIdentifier,
    onSetLoading
}: AiGeneratedResponseProps) => {
    const [editor] = useLexicalComposerContext();
    const { enqueueSnackbar } = useSnackbar();
    const { flagProvider } = useUserScopedAppData();
    let flags = flagProvider.populateFlagValues([
        "DEMO_MODE__enable_demo_mode",
        "AI_FEATURES_REPORTS__enable_ai_for_reports"
    ]);
    let tooltipText = "Generate AI Report Section";
    let disableButton = false;
    if(flags.DEMO_MODE__enable_demo_mode === "TRUE" && flags.AI_FEATURES_REPORTS__enable_ai_for_reports === "FALSE") {
        tooltipText = "Generate AI Report Section";
    } else if(flags.DEMO_MODE__enable_demo_mode === "FALSE" && flags.AI_FEATURES_REPORTS__enable_ai_for_reports === "FALSE") {
        tooltipText = "AI Report Section Generation coming soon!";
        disableButton = true;
    }

    const res = useMemo(() => {
        for (const rr of reportData.reportRevisions) {
            for (const re of rr.reportEdits) {
                if (re.identifier === editIdentifier) {
                    return re.lexical;
                }
            }
        }
    }, [
        editIdentifier,
        reportData.reportRevisions
    ]);

    const handleClick = () => {
        if (!res) {
            enqueueSnackbar("AI Generated Report Section is not available", TOP_CENTER_OPTION);
            return;
        }

        onSetLoading(true);

        const textContent = editor.parseEditorState(res).read(() => $getRoot().getTextContent());
        const words = textContent.split(' ');

        const setInnerReportSectionContent = (wordI: number) => {
            editor.update(() => {
                const root = $getRoot();
                const innerReportSectionNode = root.getChildAtIndex(0);
                if ($isInnerReportSectionNode(innerReportSectionNode)) {

                    // Replace first child, remove the addition children
                    const firstChild = innerReportSectionNode.getFirstChild();
                    const contatedTokens = words.slice(0, wordI + 1).join(' ');
                    if (firstChild) {
                        firstChild.replace($createTextNode(contatedTokens))
                    }
                }
            }, { skipTransforms: true });
        }

        // Clear selections so we can work with the editor
        editor.update(() => {
            $setSelection(null);
        }, { skipTransforms: true });

        // create token animations
        for (let i = 0; i < words.length; i ++) {
            setTimeout(() => {
                setInnerReportSectionContent(i);
            }, (300 * (i + 1)));
        }

        // set final result
        setTimeout(() => {
            editor.update(() => {
                editor.setEditorState(editor.parseEditorState(res));
            }, { skipTransforms: true });
        }, (300 * words.length));

        // set loading back to false
        setTimeout(() => {
            onSetLoading(false);
        }, (300 * (words.length + 1)));
    };

    return (
        <BoltButton
            boltId="generate-report-section"
            isReportGen
            disableAll={disableButton}
            tooltipText={tooltipText}            
            onClick={() => handleClick()}
            onClickDelay={(Math.random() * 3000) + 1000}
            size="small"
        />
    );
}

export default AiGeneratedResponse;
