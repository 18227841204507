import { Box, CircularProgress } from "@mui/material";

import "./defaultLoadingScreen.css";

type Props = {};

export default function DefaultLoadingScreen({}: Props) {
  return (
    <Box 
      id="default-loading-screen"
      data-testid="default-loading-screen"
      sx={{ 
        height: "100vh",
        display: "grid",
        placeItems: "center",
        background: "transparent",
      }}>
      <CircularProgress size={60}/>
    </Box>
  );
}
