import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { PaginatedResponse } from "../../redux/models/commonTypes";
import { Box, Paper, Skeleton, styled } from "@mui/material";

const LIMIT_OPTIONS = [2, 5, 10, 15, 20];

const TableRowsLoader = ({ rowsNum }: { rowsNum: number }) => {
    return (
        <>
            {[...Array(rowsNum)].map((row, index) => (
                <TableRow key={index}>
                    <TableCell component="th" scope="row" sx={{ padding: 1.25 }}>
                        <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell sx={{ padding: 1.25 }}>
                        <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell sx={{ padding: 1.25 }}>
                        <Skeleton animation="wave" variant="text" />
                    </TableCell>
                    <TableCell sx={{ padding: 1.25 }}>
                        <Skeleton animation="wave" variant="text" />
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
  };

interface Props<T> {
    paginatedResponse: PaginatedResponse<T> | undefined;
    tableSpan: number;
    rowMapper: (t: T) => any;
    headers: Array<string>;
    page: number;
    limit: number;
    setPage: (p: number) => void;
    setLimit: (l: number) => void;
    isFetching?: boolean;
    isLoading?: boolean;
    usePaperComponent?: boolean;
}

const StyledBox = styled(Box)`
    padding: 1;
    border: 1px solid rgba(221, 221, 221, 0.5);
    background-color: white;
    border-radius: 4px;
`;

const PagedTable = <T extends unknown>({
    paginatedResponse,
    tableSpan,
    rowMapper,
    headers,
    page,
    limit,
    setPage,
    setLimit,
    isFetching = false,
    isLoading = false,
    usePaperComponent = false
}: Props<T>) => {
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage((newPage + 1));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(parseInt(event.target.value));
        setPage(1);
    };

    return (
        <TableContainer component={usePaperComponent ? StyledBox : "div"}>
            <Table size="small">
                <TableHead>
                    {headers.map((h) => (
                        <TableCell
                            variant="head"
                            sx={{ fontWeight: "bolder" }}
                        >
                            {h}
                        </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                    {(isLoading || isFetching) ? (
                        <TableRowsLoader rowsNum={limit} />
                    ) : (
                        (paginatedResponse && paginatedResponse.data.length > 0) ? paginatedResponse.data.map(
                            rowMapper
                        ) : (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" colSpan={tableSpan}>
                                    <i>none</i>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={LIMIT_OPTIONS}
                            colSpan={tableSpan}
                            count={paginatedResponse?.metadata.total || -1}
                            rowsPerPage={limit}
                            page={(page - 1)}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default PagedTable;