import React, { useEffect, useState } from "react";
import { SimpleModalWrapper } from "../../components/dialog/wrappers/simpleModalWrapper";
import { Autocomplete, Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material/";
import { Assistant, AssistantMembership, Organization } from "../../redux/models/dataModelTypes";
import { useSearchAssistantsByNameQuery } from "../../redux/services/assistant";
import { useAddOrganizationAssistantMembershipMutation } from "../../redux/services/organization";


interface Props {
    organization: Organization;
    onAddAssistantMembership: (am: AssistantMembership) => void;
    open: boolean;
    onClose: () => void;
}

export const ManageAssistantMemberships = ({ 
    organization, 
    onAddAssistantMembership, 
    open, 
    onClose
}: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<Assistant | null>(null);
    const { data: assistantOptions } = useSearchAssistantsByNameQuery({
        page: 0,
        limit: 10,
        name: inputValue
    });
    const [addOrganizationAssistantMembership, addOrganizationAssistantMembershipRes] = useAddOrganizationAssistantMembershipMutation();

    useEffect(() => {
        if (addOrganizationAssistantMembershipRes.isSuccess) {
            onAddAssistantMembership(addOrganizationAssistantMembershipRes.data);
        }
    }, [addOrganizationAssistantMembershipRes]);

    return (
        <SimpleModalWrapper
            headerText="Manage Memberships"
            open={open}
            handleClose={onClose}
            maxWidth='md'
        >
            <Grid container>
                <Grid
                    xs={12}
                    item
                    paddingTop={1}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Type
                                </TableCell>
                                <TableCell
                                    variant="head"
                                    sx={{ fontWeight: "bolder" }}
                                >
                                    Created At
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {(organization.assistantMemberships.length > 0) ? organization.assistantMemberships.map((am: AssistantMembership) => (
                                    <TableRow
                                        key={am.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {am.assistant.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {am.type}
                                        </TableCell>
                                        <TableCell style={{ width: 160 }} align="right">
                                            {new Date(am.createdAt).toLocaleString()}
                                        </TableCell>
                                    </TableRow>

                                )) : (
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={3} align="left">
                                            <i>none</i>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid
                    item
                    xs={12}
                    paddingBottom={2}
                >
                    <Stack direction="row" spacing={2}>
                        <Autocomplete
                            size="small"
                            sx={{ minWidth: "250px" }}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.name
                            }
                            filterOptions={(x) => x}
                            options={assistantOptions?.data || []}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="No Assistants"
                            onChange={(event: any, newValue: Assistant | null) => {
                                setValue(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Add an assistant" fullWidth />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props}>
                                        <Grid container alignItems="center">
                                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                                <Box component="span">
                                                    {option.name}
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Added {new Date(option.createdAt).toLocaleString()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                );
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (value) {
                                    addOrganizationAssistantMembership({
                                        organizationId: organization.id,
                                        assistantId: value.id
                                    });
                                }
                            }}
                        >
                            Add
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </SimpleModalWrapper>
    );
};

