export const fileUploadAction = {
  FETCH_FILE_UPLOADS_SUCCESS: "FETCH_FILE_UPLOADS_SUCCESS",
  FETCH_FILE_UPLOADS_FAILURE: "FETCH_FILE_UPLOADS_FAILURE",
  FETCH_FILE_UPLOADS_REQUEST: "FETCH_FILE_UPLOADS_REQUEST",
  ADD_FILE_UPLOAD_SUCCESS: "ADD_FILE_UPLOAD_SUCCESS",
  ADD_FILE_UPLOAD_FAILURE: "ADD_FILE_UPLOAD_FAILURE",
  ADD_FILE_UPLOAD_REQUEST: "ADD_FILE_UPLOAD_REQUEST",
  UPDATE_FILE_UPLOAD_SUCCESS: "UPDATE_FILE_UPLOAD_SUCCESS",
  UPDATE_FILE_UPLOAD_FAILURE: "UPDATE_FILE_UPLOAD_FAILURE",
  UPDATE_FILE_UPLOAD_REQUEST: "UPDATE_FILE_UPLOAD_REQUEST",
  ADD_FILE_UPLOAD_ITEM_CONTENT_REQUEST: "ADD_FILE_UPLOAD_ITEM_CONTENT_REQUEST",
  ADD_FILE_UPLOAD_ITEM_CONTENT_SUCCESS: "ADD_FILE_UPLOAD_ITEM_CONTENT_SUCCESS",
  ADD_FILE_UPLOAD_ITEM_CONTENT_FAILURE: "ADD_FILE_UPLOAD_ITEM_CONTENT_FAILURE",
  ADD_FILE_UPLOAD_ITEM_REQUEST: "ADD_FILE_UPLOAD_ITEM_REQUEST",
  ADD_FILE_UPLOAD_ITEM_SUCCESS: "ADD_FILE_UPLOAD_ITEM_SUCCESS",
  ADD_FILE_UPLOAD_ITEM_FAILURE: "ADD_FILE_UPLOAD_ITEM_FAILURE",
  ARCHIVE_FILE_UPLOAD_ITEM_REQUEST: "ARCHIVE_FILE_UPLOAD_ITEM_REQUEST",
  ARCHIVE_FILE_UPLOAD_ITEM_SUCCESS: "ARCHIVE_FILE_UPLOAD_ITEM_SUCCESS",
  ARCHIVE_FILE_UPLOAD_ITEM_FAILURE: "ARCHIVE_FILE_UPLOAD_ITEM_FAILURE"
};
