import React, { useState } from "react";
import {
    Box, Button, Stack,
} from "@mui/material/";
import {
    useAddBuilderDocumentMemoTemplateMutation,
    useUpdateBuilderDocumentMemoTemplateMutation
} from "../../../../redux/services/builder";
import { CircularProgress } from "@mui/material";
import { BuilderDocument, BuilderDocumentMemoTemplate } from "../../../../redux/models/dataModelTypes";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import EditorThemeClasses from "../../../../pages/Builder/themes/playgroundTheme";
import ToolbarPlugin from "../../plugins/MemoTemplateEditorToolbarPlugin";
import { PraxiTemplateNode } from "../../nodes/PraxiTemplateNode";
import PraxiTemplatePlugin from "../../plugins/PraxiTemplatePlugin";
import { EditorState } from "lexical";
import { Download } from "@mui/icons-material";

import './index.css';

interface MemoTemplateLexicalEditorProps {
    builderDocument: BuilderDocument;
}

const MemoTemplateLexicalEditor = ({ builderDocument }: MemoTemplateLexicalEditorProps) => {
    const [localEditorState, setLocalEditorState] = useState<null | EditorState>(null);
    const [addBuilderDocumentMemoTemplate] = useAddBuilderDocumentMemoTemplateMutation();
    const [updateBuilderDocumentMemoTemplate] = useUpdateBuilderDocumentMemoTemplateMutation();

    if (!builderDocument) {
        return (
            <CircularProgress />
        );
    }

    let memoTemplates: Array<BuilderDocumentMemoTemplate> = builderDocument.memoTemplates;
    return (
        <Stack 
            sx={{
                height: "100%",
                overflowY: "scroll"
            }}
        >
            <Box>
                <Button 
                    startIcon={<Download/>}
                    onClick={() => {
                        const link = document.createElement("a");
                        link.href = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(memoTemplates[0].data))}`;
                        link.download = `report_template_${builderDocument.builderId}_${new Date().toString()}.json`;
                        link.click();
                    }}
                >
                    export
                </Button>
            </Box>
            <Box>
                <LexicalComposer
                    initialConfig={{
                        editorState: (memoTemplates.length > 0) && (JSON.stringify(memoTemplates[0].data) || ""),
                        namespace: "main",
                        theme: EditorThemeClasses,
                        onError(error: any) {
                            throw error;
                        },
                        nodes: [
                            HeadingNode,
                            ListNode,
                            ListItemNode,
                            QuoteNode,
                            TableNode,
                            TableCellNode,
                            TableRowNode,
                            PraxiTemplateNode
                        ]
                    } as InitialConfigType}
                >
                    <ToolbarPlugin />
                    <div className="editor-container">
                        <div
                            className="editor-inner"
                            style={{
                                padding: "1%"
                            }}
                        >
                            <RichTextPlugin
                                contentEditable={
                                    <ContentEditable className="editor-input" />
                                }
                                placeholder={null}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <OnChangePlugin
                                onChange={(editorState, editor) => setLocalEditorState(editorState)} />
                            <HistoryPlugin />
                            <AutoFocusPlugin />
                            <ListPlugin />
                            <TablePlugin />
                            <PraxiTemplatePlugin />
                        </div>
                    </div>
                </LexicalComposer>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (memoTemplates.length > 0) {
                            const memoTemplate = memoTemplates[0];
                            updateBuilderDocumentMemoTemplate({
                                id: memoTemplate.id,
                                builderDocumentId: memoTemplate.builderDocumentId,
                                data: localEditorState as Object
                            });
                        } else {
                            addBuilderDocumentMemoTemplate({
                                builderDocumentId: builderDocument.id,
                                data: localEditorState as Object
                            });
                        }
                    }}
                >
                    Save
                </Button>
            </Box>
        </Stack>
    );
}

export default MemoTemplateLexicalEditor;
