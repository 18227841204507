import React from "react";
import { Skeleton } from "@mui/material";

const RectangularSkeleton = () => {
    return (    
        <Skeleton 
            animation="wave" 
            variant="rectangular"
            sx={{ 
                height: "100%",
                width: "100%",
                borderRadius: 1,
                bgcolor: "#FBFCFE"
            }}
        />
    );
};

export default RectangularSkeleton;