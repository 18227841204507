import { createApi } from '@reduxjs/toolkit/query/react';
import { 
    TakerDocumentData,
    TakerDocumentUploadData
} from "../models/dataModelTypes";
import { axiosBaseQuery } from '../reduxUtils/baseQuery';
import socket from '../../utils/socket';

export const takerDataApi = createApi({
    reducerPath: 'takerDataApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [
        'LatestTakerDocumentData', 
        'LatestTakerDocumentUploadData',
        'LatestKeyTermsGroupData'
    ],
    endpoints: (build) => ({
        getLatestTakerDocumentData: build.query<TakerDocumentData, { takerDocumentId: string, contentType: string }>({
            query({ takerDocumentId, contentType }) {
                return {
                    url: `taker_document_data/latest?taker_document_id=${takerDocumentId}&content_type=${contentType}`,
                    method: "GET"
                };
            },
            providesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        getLatestTakerDocumentUploadData: build.query<TakerDocumentUploadData, { takerDocumentUploadId: string, contentType: string }>({
            query({ takerDocumentUploadId, contentType }) {
                return {
                    url: `taker_document_upload_data/latest?taker_document_upload_id=${takerDocumentUploadId}&content_type=${contentType}`,
                    method: "GET"
                };
            },
            providesTags: (result, error, { takerDocumentUploadId, contentType }) => [
                { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` }
            ],
        }),
        addTakerDocumentData: build.mutation<TakerDocumentData, Partial<TakerDocumentData> & { takerId: string }>({
            query(data) {
                const {
                    takerDocumentId,
                    content,
                    contentType
                } = data;
                return {
                    url: `taker_document_data`,
                    method: 'POST',
                    data: {
                        taker_document_id: takerDocumentId,
                        content: content,
                        content_type: contentType
                    }
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        updateTakerDocumentData: build.mutation<TakerDocumentData, Partial<TakerDocumentData> & { takerId: string }>({
            query(data) {
                const { id, content } = data;
                return {
                    url: `taker_document_data/${id}`,
                    method: 'PUT',
                    data: { content: content }
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        deleteTakerDocumentData: build.mutation<TakerDocumentData, Partial<TakerDocumentData> & { takerId: string }>({
            query(data) {
                const { id } = data;
                return {
                    url: `taker_document_data/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { takerDocumentId, contentType }) => [
                { type: 'LatestTakerDocumentData', id: `${takerDocumentId}-${contentType}` }
            ],
        }),
        addTakerDocumentUploadData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const {
                    takerDocumentUploadId,
                    content,
                    contentType
                } = data;
                return {
                    url: `taker_document_upload_data`,
                    method: 'POST',
                    data: {
                        taker_document_upload_id: takerDocumentUploadId,
                        content: content,
                        content_type: contentType
                    }
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId, contentType }) => {
                let tags: any = [
                    { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` },
                ]; 

                if (contentType === 'KEY_TERMS') {
                    tags.push({ type: 'LatestKeyTermsGroupData', id: `${takerDocumentUploadId}` });
                }
                return tags;
            },        
        }),
        updateTakerDocumentUploadData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const { id, content } = data;
                return {
                    url: `taker_document_upload_data/${id}`,
                    method: 'PUT',
                    data: { content: content }
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId, contentType }) => [
                { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` }
            ],
        }),
        deleteTakerDocumentUploadData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const { id } = data;
                return {
                    url: `taker_document_upload_data/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId, contentType }) => [
                { type: 'LatestTakerDocumentUploadData', id: `${takerDocumentUploadId}-${contentType}` }
            ],
        }),
        getLatestKeyTermGroupData: build.query<TakerDocumentUploadData, string>({
            query(takerDocumentUploadId) {
                return {
                    url: `taker_document_upload_data/latest?taker_document_upload_id=${takerDocumentUploadId}&content_type=KEY_TERMS`,
                    method: "GET"
                };
            },
            async onCacheEntryAdded(
                arg,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                function onUpdate(newModel: TakerDocumentUploadData) {
                    updateCachedData((draft) => {
                        Object.assign(draft, newModel);
                    });
                }

                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded;

                    socket.on('taker-document-upload-data-update', onUpdate);

                    // cacheEntryRemoved will resolve when the cache subscription is no longer active
                    await cacheEntryRemoved;
                } catch { }

                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                socket.off('taker-document-upload-data-update', onUpdate);
            },
            providesTags: (result, error, takerDocumentUploadId) => [
                { type: 'LatestKeyTermsGroupData', id: takerDocumentUploadId }
            ]
        }),
        updateKeyTermGroupData: build.mutation<TakerDocumentUploadData, Partial<TakerDocumentUploadData> & { takerId: string, takerDocumentId: string; }>({
            query(data) {
                const { id, content } = data;
                return {
                    url: `taker_document_upload_data/${id}`,
                    method: 'PUT',
                    data: { content: content }
                }
            },
            invalidatesTags: (result, error, { takerDocumentUploadId }) => [
                { type: 'LatestKeyTermsGroupData', id: takerDocumentUploadId }
            ],
        }),
    })
})

export const {
    useGetLatestTakerDocumentDataQuery,
    useGetLatestTakerDocumentUploadDataQuery,
    useLazyGetLatestTakerDocumentUploadDataQuery,
    useAddTakerDocumentDataMutation,
    useUpdateTakerDocumentDataMutation,
    useDeleteTakerDocumentDataMutation,
    useAddTakerDocumentUploadDataMutation,
    useUpdateTakerDocumentUploadDataMutation,
    useDeleteTakerDocumentUploadDataMutation,
    useGetLatestKeyTermGroupDataQuery,
    useUpdateKeyTermGroupDataMutation
} = takerDataApi;