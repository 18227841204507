import React, { useMemo } from "react";
import { Box, Button, SxProps, Theme } from "@mui/material/";
import { TakerDocumentReviewCommentPayload } from "../../../redux/models/dataModelTypes";
import CommentLexical from "../../comments/CommentLexical";
import { blue } from "@mui/material/colors";
import CommentMetadata from "./CommentMetadata";

interface CreateNewCommentProps {
    stagedComment: TakerDocumentReviewCommentPayload;
    updateStagedComment: (comment: TakerDocumentReviewCommentPayload) => void;
    onClearStagedComment: () => void;
    onPostComment: () => void;
    sx?: SxProps<Theme>;
}

/**
 * CreateNewComment is a component that renders a box with a comment editor,
 * a "Post" button and a "Cancel" button. The comment editor is a lexical editor
 * that allows the user to enter a comment. The "Post" button is disabled if the
 * comment is empty. When the user clicks on the "Post" button, the `onPostComment`
 * callback is called. When the user clicks on the "Cancel" button, the `onClearStagedComment`
 * callback is called.
 *
 * @param {{ stagedComment: TakerDocumentReviewCommentPayload; updateStagedComment: (comment: TakerDocumentReviewCommentPayload) => void; onClearStagedComment: () => void; onPostComment: () => void; }} props
 * @returns {JSX.Element} The CreateNewComment component.
 */
function CreateNewComment({
    stagedComment,
    updateStagedComment,
    onClearStagedComment,
    onPostComment,
}: CreateNewCommentProps) {
    const disablePost = useMemo(() =>
        !stagedComment.commentText || stagedComment.commentText.trim() === "",
        [stagedComment]
    );

    const border = `2px solid ${blue[400]}`;
    return (
        <Box
            data-testid="create-new-comment-box"
            sx={{
                marginTop: "10px",
                padding: 1,
                border,
                backgroundColor: "rgba(221, 221, 221, 0.05)",
                borderRadius: 1,
            }}
        >
            <CommentMetadata
                commentType={stagedComment.commentType}  
                commentMetadata={stagedComment.commentMetadata}
                onClick={() => {}}
                highlightOnInit
            />
            <Box
                maxHeight="150px"
                overflow="auto"
                marginTop={1}
                padding={1}
                border={"1px solid #ddd"}
                borderRadius={"4px"}
            >
                <CommentLexical
                    namespace={crypto.randomUUID()}
                    lexical={stagedComment.commentLexical}
                    onUpdate={(lexicalContent, textContent) => {
                        updateStagedComment({
                            ...stagedComment,
                            commentLexical: lexicalContent,
                            commentText: textContent
                        });
                    }}
                />
            </Box>
            <Box
                sx={{
                    paddingTop: "10px",
                }}
            >
                    <Button
                        variant="outlined"
                        color="info"
                        size="small"
                        disabled={disablePost}
                        onClick={onPostComment}
                    >
                        Post
                    </Button>
                    <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        sx={{ marginLeft: "10px" }}
                        onClick={onClearStagedComment}
                    >
                        Cancel
                    </Button>
            </Box>
        </Box>
    );
}

/**
 * A component that renders a box with a lexical editor and post/cancel buttons.
 * The component is meant to be used in a drawer.
 *
 * @param {CreateNewCommentProps} props
 * @prop {TakerDocumentReviewCommentPayload} stagedComment The staged comment to render.
 * @prop {(comment: TakerDocumentReviewCommentPayload) => void} updateStagedComment A callback that will be called with the updated staged comment.
 * @prop {() => void} onClearStagedComment A callback that will be called when the user clicks the cancel button.
 * @prop {() => void} onPostComment A callback that will be called when the user clicks the post button.
 * @prop {CSSProperties} sx The CSSProperties to apply to the component.
 *
 * @returns {JSX.Element} The rendered component.
 */
function CreateNewCommentPlain({
    stagedComment,
    updateStagedComment,
    onClearStagedComment,
    onPostComment,
    sx
}: CreateNewCommentProps) {
    const disablePost = useMemo(() =>
        !stagedComment.commentText || stagedComment.commentText.trim() === "",
        [stagedComment]
    );

    return (
        <Box 
            data-testid="create-new-comment-box"
            sx={sx}
        >
            <Box
                maxHeight="150px"
                overflow="auto"
            >
                <CommentLexical
                    namespace={crypto.randomUUID()}
                    lexical={stagedComment.commentLexical}
                    onUpdate={(lexicalContent, textContent) => {
                        updateStagedComment({
                            ...stagedComment,
                            commentLexical: lexicalContent,
                            commentText: textContent
                        });
                    }}
                />
            </Box>
            <Box
                sx={{
                    paddingTop: "10px",
                }}
            >
                <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    disabled={disablePost}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        onPostComment();
                    }}
                >
                    Post
                </Button>
                <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    sx={{ marginLeft: "10px" }}
                    onClick={(e: React.MouseEvent ) => {
                        e.stopPropagation();
                        onClearStagedComment();
                    }}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    );
}

export {
    CreateNewComment,
    CreateNewCommentPlain
};
