import React, { useState } from "react";
import { SimpleModalWrapper } from "../../../components/dialog/wrappers/simpleModalWrapper";
import {
    Alert,
    Box,
    IconButton,
    Tab,
    Tabs
} from "@mui/material/";
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { TakerDocumentAnalysis } from "../../../redux/models/dataModelTypes";
import { Update } from '@mui/icons-material';
import { useReportState } from "../../../containers/TakerDocumentState/ReportState";
import TabPanel from "../../../components/navigation/TabPanel";
import { useTakerState } from "../../../containers/TakerDocumentState/TakerDocumentState";
import GeneratedReportRow from "./GeneratedReportRow";
import ReportRevisionRow from "./ReportRevisionRow";

interface Props {
    open: boolean;
    setOpen: (o: boolean) => void;
    readOnly: boolean;
}

export default ({
    open,
    setOpen,
    readOnly
}: Props) => {
    const {
        allReportRevisions,
        latestMemoGenAnalyses,
        createMemoGenAnalysis
    } = useReportState();
    const [tabValue, setTabValue] = useState(0);
    const [didClickRegenerate, setDidClickRegenerate] = useState(false);
    const { fulfillmentStateHolder } = useTakerState();

    const isAutomationFulfilled = fulfillmentStateHolder?.fulfillmentState?.isFulfilled;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

    return (
        <SimpleModalWrapper
            headerText="Reports"
            open={open}
            handleClose={() => setOpen(false)}
            maxWidth='md'
        >
            <Box>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                >
                    <Tab label="Revisions" />
                    <Tab label="Autogenerated Reports" />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        sx={{ fontWeight: "bolder" }}
                                    >
                                        Last Updated
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        sx={{ fontWeight: "bolder" }}
                                    >
                                        Number of Edits
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allReportRevisions && allReportRevisions.map((rr) => (
                                    <ReportRevisionRow reportRevision={rr} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={tabValue} index={1} >
                    <Box sx={{ padding: 1 }}>
                        {!isAutomationFulfilled ? (
                            <Alert severity="error">
                                There are required answers missing on the questionnaire.
                            </Alert>
                        ) : (
                            <Alert
                                severity="info"
                                action={
                                    <IconButton
                                        disabled={(!isAutomationFulfilled || readOnly || didClickRegenerate)}
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setDidClickRegenerate(true);
                                            createMemoGenAnalysis();
                                        }}
                                    >
                                        <Update />
                                    </IconButton>
                                }>
                                Force a regeneration of the report
                            </Alert>
                        )}
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        sx={{ fontWeight: "bolder" }}
                                    >
                                        Created
                                    </TableCell>
                                    <TableCell
                                        variant="head"
                                        sx={{ fontWeight: "bolder" }}
                                    >
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {latestMemoGenAnalyses && latestMemoGenAnalyses.map((tda: TakerDocumentAnalysis, i: number) => (
                                    <GeneratedReportRow takerDocumentAnalysis={tda} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </Box>
        </SimpleModalWrapper>
    );
}
