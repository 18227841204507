import React, { CSSProperties } from "react";
import { Chip, Autocomplete, TextField, Checkbox } from "@mui/material/";
import { BlankCheckBoxIcon, FilledCheckBoxIcon } from "./../../../assets/icons";
import { Controller, Control, FieldErrors } from "react-hook-form";

interface Item {
  id: string;
  display: string;
}

interface Props {
  label: string;
  sx: CSSProperties;
  placeholder: string;
  options: Item[];
  fieldName: string;
  control: Control<any>;
  errors: FieldErrors<any>;
  disabled?: boolean;
}

export const MultiSelectChipsAutoCompRhf = ({
  options,
  label,
  placeholder,
  fieldName,
  control,
  errors,
  sx,
  disabled,
}: Props) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          multiple
          options={options}
          disabled={disabled ?? false}
          getOptionLabel={(option) => option.display}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<BlankCheckBoxIcon />}
                checkedIcon={<FilledCheckBoxIcon />}
                style={{ marginRight: "10px" }}
                checked={selected}
              />
              {option.display}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option.display} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              sx={sx ?? {}}
              variant="standard"
              label={label}
              placeholder={placeholder}
              error={!!errors[`${fieldName}`]}
              helperText={(errors[`${fieldName}`]?.message as string | undefined | null) ?? ""}
            />
          )}
          onChange={(event, data) => onChange(data)}
          value={value}
        />
      )}
    />
  );
};
