import { ReportStateData } from "./taker/reportstate.generated";


export class ReportStateHolder {
    reportStateData: ReportStateData;
  
    constructor(reportStateDataJson: Object) {
        this.reportStateData = JSON.parse(JSON.stringify(reportStateDataJson)) as ReportStateData;
    }

    updateSyncedWithGenerationId(revisionId: string, newSyncedWithGenerationId: string) {
        for (const reportRevision of this.reportStateData.reportRevisions) {
            if (reportRevision.revisionId === revisionId) {
                reportRevision.syncedWithGenerationId = newSyncedWithGenerationId;
                reportRevision.updatedAt = new Date().getTime();
            }
        }
    }

    upsertEditLexical(revisionId: string, editIdentifier: string, editLexical: string | null, sectionHash: string, updateHash=false) {
        const newUpdateAt = new Date().getTime();
        for (const reportRevision of this.reportStateData.reportRevisions) {
            if (reportRevision.revisionId === revisionId) {
                // we should delete this revision
                if (editLexical === null) {
                    reportRevision.reportEdits = reportRevision.reportEdits.filter(re => re.identifier !== editIdentifier);
                    reportRevision.updatedAt = newUpdateAt;
                    break;
                }
                
                let updated = false;
                for (const reportEdit of reportRevision.reportEdits) {
                    if (reportEdit.identifier === editIdentifier) {
                        reportEdit.lexical = editLexical;
                        reportEdit.updatedAt = newUpdateAt;
                        if (updateHash) {
                            reportEdit.sectionHash = sectionHash;
                        }
                        updated = true;
                        break
                    }
                }
                if (!updated) {
                    reportRevision.reportEdits.push({
                        identifier: editIdentifier,
                        lexical: editLexical,
                        sectionHash: sectionHash,
                        updatedAt: newUpdateAt
                    })
                }
                reportRevision.updatedAt = newUpdateAt;
            }
        }
    }
}