import React from "react";
import { RJSFSchema } from '@rjsf/utils';
import { Module } from "../../../types/builderv2.generated";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import VariablesEditor from "./variables";

interface VariablesProps {
    fullSchema: RJSFSchema;
    praxiModule: Module;
    level: number;
    updateModule: (b: Module) => void;
}

const Variables = ({
    fullSchema,
    praxiModule,
    level,
    updateModule
}: VariablesProps) => {
    return (
        <Accordion>
            <AccordionSummary
                aria-controls="variables-content"
                id="variables-header"
            >
                <strong>{`${praxiModule.displayName} (ID:${praxiModule.id},  FULFILLMENT:${praxiModule.uiFulfillment?.fulfillmentType}, DATA_SPEC:${praxiModule.dataSpec?.dataSpecType})`}</strong>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12} padding={1}>
                        <Typography>Exported</Typography>
                    </Grid>
                    <Grid item xs={12} padding={1}>
                        <Typography paragraph>
                            {(praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic") && (
                                <>
                                    {praxiModule.dataSpec.fields?.map((f) => (
                                        <>
                                            <div>
                                                {`${f.name}.answer [${f.primitive}]`}
                                            </div>
                                            <div>
                                                {`${f.name}.analysis [${f.primitive}]`}
                                            </div>
                                        </>
                                    ))}
                                </>
                            )}
                            {(praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table") && (
                                <>
                                    <div>
                                        {`TABLE.answer (${praxiModule.dataSpec.fields?.map((f) => `${f.name}[${f.primitive}]`).join(", ")})`}
                                    </div>
                                    <div>
                                        {`TABLE.count`}
                                    </div>
                                    <div>
                                        {`TABLE.analysis (${praxiModule.dataSpec.fields?.map((f) => `${f.name}[${f.primitive}]`).join(", ")})`}
                                    </div>
                                    {praxiModule.dataSpec.fields?.map((f) => (
                                        <>
                                            <div>
                                                {`${f.name}.answer List[${f.primitive}]`}
                                            </div>
                                            <div>
                                                {`${f.name}.analysis List[${f.primitive}]`}
                                            </div>
                                        </>
                                    ))}
                                </>
                            )}
                            {(((praxiModule.uiFulfillment?.fulfillmentType === "graph" && praxiModule.dataSpec?.dataSpecType === "basic-table"))
                                && praxiModule.uiFulfillment.completionContexts
                                && praxiModule.uiFulfillment.completionContexts.length > 0) && (
                                <>
                                    <div>
                                        {`TABLE_EXTENDED.answer`}
                                    </div>
                                    <div>
                                        {`TABLE_EXTENDED.analysis`}
                                    </div>
                                </>
                            )}
                            {(praxiModule.uiFulfillment?.fulfillmentType === "table" && praxiModule.dataSpec?.dataSpecType === "table-with-single-analysis") && (
                                <>
                                    <div>{`${praxiModule.dataSpec.otherDataSpecFieldName}.analysis`}</div>
                                    <div>
                                        {`TABLE.answer (${praxiModule.dataSpec.fields?.map((f) => `${f.name}[${f.primitive}]`).join(", ")})`}
                                    </div>
                                    <div>
                                        {`TABLE.count`}
                                    </div>
                                    {praxiModule.dataSpec.fields?.map((f) => (
                                        <div>
                                            {`${f.name}.answer List[${f.primitive}]`}
                                        </div>
                                    ))}
                                </>
                            )}
                            {((praxiModule.uiFulfillment?.fulfillmentType === "mapping-question" || praxiModule.uiFulfillment?.fulfillmentType === "percentage-mapping-question") && praxiModule.dataSpec?.dataSpecType === "table-with-single-analysis") && (
                                <>
                                    <div>{`${praxiModule.dataSpec.otherDataSpecFieldName}.analysis`}</div>
                                    <div>
                                        {`TABLE.answer (${praxiModule.dataSpec.fields?.map((f) => `${f.name}[${f.primitive}]`).join(", ")})`}
                                    </div>
                                    <div>
                                        {`TABLE.count`}
                                    </div>
                                    {praxiModule.dataSpec.fields?.map((f) => (
                                        <div>
                                            {`${f.name}.answer List[${f.primitive}]`}
                                        </div>
                                    ))}
                                </>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} padding={1} border={`1px solid #ddd`}>
                        <VariablesEditor
                            initialVariables={praxiModule.variableDefinitions}
                            fullSchema={fullSchema}
                            onUpdate={(vars) => {
                                let newMod: Module = JSON.parse(JSON.stringify(praxiModule));
                                newMod.variableDefinitions = vars;
                                updateModule(newMod);
                            }}
                        />
                    </Grid>
                    {praxiModule.nestedModules?.map(pm => (
                        <Grid item xs={12}>
                            <Variables
                                fullSchema={fullSchema}
                                level={level + 1}
                                praxiModule={pm}
                                updateModule={updateModule}
                            />
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

interface VariableTreeProps {
    fullSchema: RJSFSchema;
    initialTopModule: Module;
    onUpdate: (b: Module) => void;
}

const VariableTree = ({
    fullSchema,
    initialTopModule,
    onUpdate
}: VariableTreeProps) => {
    const searchModuleHelper = (moduleId: string, module: Module): Module | null => {
        if (moduleId === module.id) {
            return module;
        }

        if (module.nestedModules) {
            for (const m of module.nestedModules) {
                let res = searchModuleHelper(moduleId, m);
                if (!!res) {
                    return res;
                }
            }
        }
        return null;
    };

    const updateModuleHelper = (moduleToUpdate: Module, modules: Module[]) => {
        const newModules: Module[] = [];
        for (const m of modules) {
            const newModule: Module = JSON.parse(JSON.stringify(m));
            if (m.id === moduleToUpdate.id) {
                newModules.push(JSON.parse(JSON.stringify(moduleToUpdate)));
            } else if (newModule.nestedModules) {
                newModule.nestedModules = updateModuleHelper(moduleToUpdate, newModule.nestedModules);
                newModules.push(newModule);
            } else {
                newModules.push(newModule);
            }
        }
        return newModules;
    };

    const updateModule = (m: Module) => {
        const newTopModule = updateModuleHelper(m, [initialTopModule])[0];
        onUpdate(newTopModule);
    };

    return (
        <Grid container rowGap={1}>
            {initialTopModule.nestedModules?.map(pm => (
                <Grid item xs={12}>
                    <Variables
                        fullSchema={fullSchema}
                        level={0}
                        praxiModule={pm}
                        updateModule={updateModule}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default VariableTree;
