import { combineReducers } from "redux";
import authReducer from "../reducers/authReducer";
import fileUploadReducer from "../reducers/fileUploadReducer";
import { languageModelApi } from "../services/languageModel";
import { userApi } from "../services/user";
import { takerApi, takerImportApi } from "../services/taker";
import { buildersApi } from "../services/builder";
import { assistantApi } from "../services/assistant";
import { organizationApi } from "../services/organization";
import { staticFileApi } from "../services/staticFile";
import { indexedGuidanceApi } from "../services/indexedGuidance";
import { applicationApi } from "../services/application";
import { currentUserApi } from "../services/currentUser";
import { 
  fileUploadApi, 
  fileContentUploadApi 
} from '../services/fileUpload';
import { takerDataApi } from "../services/takerData";

const rootReducer = combineReducers({
  auth: authReducer,
  fileUpload: fileUploadReducer,
  [languageModelApi.reducerPath]: languageModelApi.reducer,
  [assistantApi.reducerPath]: assistantApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [takerApi.reducerPath]: takerApi.reducer,
  [takerDataApi.reducerPath]: takerDataApi.reducer,
  [takerImportApi.reducerPath]: takerImportApi.reducer,
  [buildersApi.reducerPath]: buildersApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [staticFileApi.reducerPath]: staticFileApi.reducer,
  [indexedGuidanceApi.reducerPath]: indexedGuidanceApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [fileUploadApi.reducerPath]: fileUploadApi.reducer,
  [fileContentUploadApi.reducerPath]: fileContentUploadApi.reducer,
  [currentUserApi.reducerPath]: currentUserApi.reducer
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
