import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootReducerType } from "../../redux/models/reduxTypes";
import { pageOption } from "./../../data/pages";
import DefaultLoadingScreen from "../loading/defaultLoadingScreen";

const PublicRoutes = () => {
  const {
    authenticated,
    user,
    loaded
  } = useSelector((state: RootReducerType) => state.auth);

  if (!loaded) {
    return <DefaultLoadingScreen />
  }
  if (!authenticated || !user) {
    return (<Outlet />);
  }

  if (user.roles.length === 1 && user.roles.includes("INDEXER")) {
    return (
      <Navigate to={pageOption.INDEXED_GUIDANCE_LIST.route} />
    );
  }
  return (
    <Navigate to={pageOption.HOME.route} />
  );
};

export default PublicRoutes;